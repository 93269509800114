export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const UPPER_CASE_LETTERS = /[A-Z]/g;
export const NUMBERS = /[0-9]/g;
export const SCREEN_WIDTH = 1150;

// Date formats

export const HUNGARIAN_DATE_FORMAT = 'YYYY-MM-DD';
export const CALENDAR_DATE_FORMAT = 'yyyy-MM-dd';

// Pages

export const WINERY_PAGE_SIZE = 9;
export const NEWS_PAGE_SIZE = 15;
export const USER_PAGE_SIZE = 15;
export const EVENT_PAGE_SIZE = 15;
export const OFFER_PAGE_SIZE = 15;
export const ACCOMMODATION_PAGE_SIZE = 15;
export const TRAVEL_PAGE_SIZE = 15;
