import React from 'react';
import { Box, Button, Card, CardContent, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { colors } from '../../constants/theme';

const Header = ({ onDeleteClick, deleteDisabled, onAddClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.root}>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Button
                        className={classes.button}
                        onClick={onAddClick}
                        color="primary"
                        variant="contained"
                    >
                        <AddIcon className={classes.icon} />
                        {t('pageHeader.new')}
                    </Button>
                    <Button
                        className={classes.deleteButton}
                        disabled={deleteDisabled}
                        onClick={onDeleteClick}
                        color="primary"
                        variant="outlined"
                    >
                        <DeleteIcon className={classes.icon} />
                        {t('pageHeader.delete')}
                    </Button>
                </CardContent>
            </Card>
        </Box>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    card: {
        width: '90%',
        boxShadow: 'none',
        backgroundColor: colors.LIGHTGREY,
    },
    content: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 0px',
    },
    icon: {
        paddingRight: 10,
    },
    button: {
        marginRight: 15,
    },
    deleteButton: {
        backgroundColor: colors.WHITE,
    },
});

Header.propTypes = {
    onAddClick: func,
    onDeleteClick: func,
    deleteDisabled: bool,
};

export default Header;
