import React from 'react';
import {
    Box,
    Card,
    TablePagination,
    CircularProgress,
    CardHeader,
    Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { bool, element, number, func, string } from 'prop-types';

const ListLayout = ({
    isRequested,
    totalItems,
    size,
    page,
    handlePageChange,
    children,
    title,
}) => {
    const classes = useStyles();

    if (isRequested) {
        return (
            <div className={classes.root}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <div className={classes.table}>
                    <CardHeader title={title} />
                    <Divider />
                    <Box>{children}</Box>
                </div>
                <TablePagination
                    component="div"
                    count={totalItems}
                    onChangePage={handlePageChange}
                    page={page}
                    rowsPerPage={size}
                    rowsPerPageOptions={[]}
                    className={classes.pagination}
                />
            </Card>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: '5px 0px 20px 0px',
        flexGrow: 1,
    },
    card: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 100%',
    },
    pagination: {
        minHeight: 60,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
});

ListLayout.propTypes = {
    title: string.isRequired,
    isRequested: bool,
    children: element,
    totalItems: number,
    handlePageChange: func,
    page: number,
    size: number,
};

export default ListLayout;
