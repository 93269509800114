import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
    GET_SESSION_REQUEST,
    LOGIN_REQUEST,
    LOG_OUT,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_ME_REQUEST,
} from '../actions/constants';
import {
    getSessionFailed,
    getSessionSuccess,
    loginFailed,
    loginSuccess,
    updatePasswordFailed,
    updatePasswordSuccess,
    updateMeFailed,
    updateMeSuccess,
} from '../actions/session';
import { setNotification } from '../actions/notification';
import API from '../constants/api';
import history from '../constants/history';
import { NotificationType } from '../constants/enums';
import { removeTokens, setToken } from '../constants/token';

function loginRequest(payload) {
    return API.post('/admin/login', payload);
}

function updateUserRequest(payload) {
    return API.put(`/users/me`, payload);
}

function updatePasswordRequest(payload) {
    return API.put(`/users/${payload?.id}/password`, {
        password: payload.password,
        confirm: payload.confirm,
        currentPassword: payload.currentPassword,
    });
}

function logoutRequest() {
    return API.post('/authentication/logout');
}

function sessionRequest() {
    return API.get('/authentication/session');
}

function* postLoginRequest(payload) {
    try {
        const { data } = yield call(loginRequest, payload.payload);
        setToken('token', `Bearer ${data.accessToken}`);
        setToken('refreshToken', data.refreshToken);
        yield put(loginSuccess(data));
        history.push('/');
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(loginFailed(error));
    }
}

function* putMeRequest(payload) {
    try {
        const { data } = yield call(updateUserRequest, payload.payload);
        yield put(updateMeSuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(updateMeFailed(error));
    }
}

function* putPasswordRequest(payload) {
    try {
        yield call(updatePasswordRequest, payload.payload);
        yield put(updatePasswordSuccess());
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(updatePasswordFailed(error));
    }
}

function* postLogoutRequest() {
    try {
        yield call(logoutRequest);
        history.push('/login');
        removeTokens();
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
    }
}

function* getSessionRequest() {
    try {
        const { data } = yield call(sessionRequest);
        yield put(getSessionSuccess(data));
    } catch (error) {
        console.warn(error);
        history.push('/login');
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getSessionFailed(error));
    }
}

export default function* watchSession() {
    yield takeLatest(LOGIN_REQUEST, postLoginRequest);
    yield takeLatest(UPDATE_ME_REQUEST, putMeRequest);
    yield takeLatest(UPDATE_PASSWORD_REQUEST, putPasswordRequest);
    yield takeLatest(LOG_OUT, postLogoutRequest);
    yield takeLatest(GET_SESSION_REQUEST, getSessionRequest);
}
