import React from 'react';
import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    makeStyles,
} from '@material-ui/core';
import { bool, func, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';

const InformationModal = ({
    open,
    handleClose,
    children,
    onOkClick,
    title,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="info-dialog-title"
            aria-describedby="info-dialog-description"
        >
            <DialogTitle id="info-dialog-title">{title}</DialogTitle>
            <DialogContent className={classes.content}>
                <InfoIcon className={classes.icon} />
                <div>{children}</div>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.button}
                    onClick={handleClose}
                    color="primary"
                >
                    {t('form.cancel')}
                </Button>
                <Button
                    className={classes.button}
                    onClick={onOkClick}
                    color="primary"
                    autoFocus
                >
                    {t('form.ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

InformationModal.propTypes = {
    open: bool.isRequired,
    handleClose: func.isRequired,
    children: object,
    onOkClick: func,
    title: string.isRequired,
};

const useStyles = makeStyles({
    content: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0',
    },
    contentText: {
        margin: 0,
    },
    icon: {
        paddingRight: 15,
    },
    button: {
        paddingRight: 15,
    },
});

export default InformationModal;
