import { Box, Card, makeStyles } from '@material-ui/core';
import { Pagination as MaterialPagination } from '@material-ui/lab';
import { func, number } from 'prop-types';
import React from 'react';

const Pagination = ({ totalPages, page, handlePageChange }) => {
    const classes = useStyles();

    return (
        <Box className={classes.footerBox}>
            <Card className={classes.paginationCard}>
                <MaterialPagination
                    className={classes.pagination}
                    color="primary"
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                />
            </Card>
        </Box>
    );
};

const useStyles = makeStyles({
    footerBox: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    paginationCard: {
        width: '100%',
        padding: 5,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
    },
});

Pagination.propTypes = {
    handlePageChange: func,
    page: number,
    totalPages: number,
};

export default Pagination;
