import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEventsRequest } from '../../actions/events';
import { getAllNewsRequest } from '../../actions/news';
import { getAllWineriesRequest } from '../../actions/wineries';
import Header from '../../components/Menu/Header';
import WarningModal from '../../components/Modal/WarningModal';
import { selectSelectedEvents } from '../../constants/selectors';
import { styles } from '../../constants/theme';
import EventList from './EventList';
import EventModal from './EventModal';

const Events = () => {
    const selectedEvents = useSelector(selectSelectedEvents);
    const dispatch = useDispatch();
    const [addOpen, setAddOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAllWineriesRequest());
        dispatch(getAllNewsRequest());
    }, []);

    const onDelete = () => {
        setDeleteOpen(false);
        dispatch(deleteEventsRequest(selectedEvents));
    };

    return (
        <div style={styles.defaultContainer}>
            <Header
                deleteDisabled={selectedEvents?.length === 0}
                onAddClick={() => setAddOpen(true)}
                onDeleteClick={() => setDeleteOpen(true)}
            />
            <EventList />
            <EventModal open={addOpen} onClose={() => setAddOpen(false)} />
            <WarningModal
                open={deleteOpen}
                handleClose={() => setDeleteOpen(false)}
                content={t('events.confirmDelete')}
                okButtonText={t('actions.delete')}
                onOkClick={onDelete}
                title={t('events.toDelete')}
            />
        </div>
    );
};

export default Events;
