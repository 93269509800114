import React, { useState } from 'react';
import Table from '../../components/Table/Table';
import { array, func, object } from 'prop-types';
import { setSelectedAccommodations } from '../../actions/accommodations';
import AccommodationActionMenu from './AccommodationActionMenu';
import { useTranslation } from 'react-i18next';
import UploadModal from '../../components/Modal/UploadModal';
import AccommodationImageGrid from './AccommodationImageGrid';
import { uploadAccommodationImageRequest } from '../../actions/images';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccommodations } from '../../constants/selectors';
import {
    enumFormatter,
    imageFormatter,
    newLineFormatter,
} from '../../constants/tableHelper';
import { AccommodationTypes } from '../../constants/enums';

const AccommodationTable = ({
    items,
    selectedAccommodationIds,
    setSelectedAccommodation,
    setAnchorEl,
    anchorEl,
    onEditMenuClick,
    selectedAccommodation,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [uploadOpen, setUploadOpen] = useState(false);
    const currentAccommodation = useSelector(selectAccommodations)?.items?.find(
        (accommodation) => accommodation.id === selectedAccommodation?.id
    );

    const columns = [
        {
            id: 'images',
            label: t('accommodations.coverImage'),
            format: (value) => imageFormatter(value),
        },
        { id: 'name', label: t('accommodations.name') },
        {
            id: 'winery',
            label: t('accommodations.winery'),
            format: (value) => (value ? value.name : ''),
        },
        { id: 'address', label: t('accommodations.address') },
        {
            id: 'accommodationType',
            label: t('accommodations.type'),
            format: (value) => enumFormatter(AccommodationTypes, value, t),
        },
        {
            id: 'openingHours',
            label: t('accommodations.openingHours'),
            format: (value) => newLineFormatter(value),
        },
    ];

    const onMenuClick = (e, accommodation) => {
        setAnchorEl(e.currentTarget);
        setSelectedAccommodation(accommodation);
    };

    const onImageUpload = (files) => {
        setUploadOpen(false);
        dispatch(
            uploadAccommodationImageRequest({
                id: selectedAccommodation?.id,
                photos: files,
            })
        );
    };

    const onUploadClick = () => {
        setUploadOpen(true);
        setAnchorEl(null);
    };

    return (
        <>
            <Table
                columns={columns}
                items={items}
                selectedItemIds={selectedAccommodationIds}
                setSelectedItems={setSelectedAccommodations}
                onMenuClick={onMenuClick}
            >
                <AccommodationActionMenu
                    anchorEl={anchorEl}
                    handleClose={() => setAnchorEl(null)}
                    onEditClick={onEditMenuClick}
                    onUploadClick={onUploadClick}
                />
            </Table>
            <UploadModal
                title={t('accommodations.accommodationUpload')}
                onClose={() => setUploadOpen(false)}
                open={uploadOpen}
                onImageUpload={onImageUpload}
                images={currentAccommodation?.images}
            >
                <AccommodationImageGrid
                    images={currentAccommodation?.images}
                    accommodationId={currentAccommodation?.id}
                />
            </UploadModal>
        </>
    );
};

AccommodationTable.propTypes = {
    items: array,
    selectedAccommodationIds: array,
    setSelectedAccommodation: func,
    selectedAccommodation: object,
    setAnchorEl: func,
    anchorEl: object,
    onEditMenuClick: func,
};

export default AccommodationTable;
