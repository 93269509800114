import React from 'react';
import ActionMenu from '../../components/Menu/ActionMenu';
import { bool, func, object } from 'prop-types';
import { EditMenuItem, PasswordMenuItem } from '../../components/MenuItem';

const UserActionMenu = ({
    anchorEl,
    handleClose,
    onGeneratePasswordClick,
    onEditClick,
    passwordGenerationDisabled = false,
}) => {
    return (
        <ActionMenu anchorEl={anchorEl} handleClose={handleClose}>
            {!passwordGenerationDisabled && (
                <PasswordMenuItem
                    onGeneratePasswordClick={onGeneratePasswordClick}
                />
            )}
            <EditMenuItem onEditClick={onEditClick} />
        </ActionMenu>
    );
};

UserActionMenu.propTypes = {
    anchorEl: object,
    handleClose: func,
    onGeneratePasswordClick: func,
    onEditClick: func,
    passwordGenerationDisabled: bool,
};

export default UserActionMenu;
