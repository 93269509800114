import { useLocation } from 'react-router';

const useUrlParams = () => {
    const { search } = useLocation();
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return params;
};

export default useUrlParams;
