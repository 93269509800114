import {
    GET_SESSION_FAILED,
    GET_SESSION_REQUEST,
    GET_SESSION_SUCCESS,
    LOGIN_FAILED,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOG_OUT,
    UPDATE_PASSWORD_FAILED,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_ME_FAILED,
    UPDATE_ME_REQUEST,
    UPDATE_ME_SUCCESS,
    REMOVE_SESSION,
} from '../actions/constants';
import { INITIAL_SESSION_STATE } from '../constants/states';

export default (state = INITIAL_SESSION_STATE, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loginRequested: true,
                isLoggedIn: false,
                sessionRemoved: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loginRequested: false,
                isLoggedIn: true,
                session: action.payload,
            };
        case LOGIN_FAILED:
            return { ...state, loginRequested: false, isLoggedIn: false };
        case GET_SESSION_REQUEST:
            return { ...state, sessionRequested: true };
        case GET_SESSION_SUCCESS:
            return {
                ...state,
                sessionRequested: false,
                isLoggedIn: true,
                session: action.payload,
            };
        case GET_SESSION_FAILED:
            return {
                ...state,
                sessionRequested: false,
                isLoggedIn: false,
                session: null,
            };
        case LOG_OUT:
            return { ...state, isLoggedIn: false, session: null };
        case REMOVE_SESSION:
            return {
                ...state,
                isLoggedIn: false,
                session: null,
                sessionRemoved: true,
            };
        case UPDATE_ME_REQUEST:
            return { ...state, userUpdateRequested: true };
        case UPDATE_ME_SUCCESS:
            return {
                ...state,
                userUpdateRequested: false,
                session: {
                    ...state.session,
                    ...action.payload,
                },
            };
        case UPDATE_ME_FAILED:
            return { ...state, userUpdateRequested: false };
        case UPDATE_PASSWORD_REQUEST:
            return { ...state, userUpdateRequested: true };
        case UPDATE_PASSWORD_SUCCESS:
            return { ...state, userUpdateRequested: false };
        case UPDATE_PASSWORD_FAILED:
            return { ...state, userUpdateRequested: false };
        default:
            return state;
    }
};
