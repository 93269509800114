import { makeStyles } from '@material-ui/core';
import { array } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewAllCardLayout from '../../components/Layout/ViewAllCardLayout';
import SimpleTable from '../../components/Table/SimpleTable';

const ExpiringOffersTable = ({ offerList }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const columns = [
        { id: 'title', label: t('offers.title') },
        { id: 'validFrom', label: t('offers.validFrom') },
        { id: 'validTo', label: t('offers.validTo') },
    ];

    return (
        <div className={classes.root}>
            <ViewAllCardLayout
                href={'/offers'}
                title={t('home.expiringOffers')}
            >
                <SimpleTable items={offerList} columns={columns} />
            </ViewAllCardLayout>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        marginTop: 30,
        width: '100%',
    },
});

ExpiringOffersTable.propTypes = {
    offerList: array,
};

export default ExpiringOffersTable;
