import axios from 'axios';
import { store } from '../store/store';
import { AuthenticationResponse } from './enums';
import { getToken, removeTokens, setToken } from './token';
import history from '../constants/history';
import { removeSession } from '../actions/session';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
});

const refreshInstance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = getToken('token');
        if (accessToken) {
            config.headers.Authorization = accessToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalRequest = error.config;
        if (
            error.response.status === AuthenticationResponse.TokenExpired &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
            return refreshInstance
                .post('/authentication/refresh', {
                    refreshToken: getToken('refreshToken'),
                })
                .then(({ data }) => {
                    setToken('token', `Bearer ${data.accessToken}`);
                    axiosInstance.defaults.headers[
                        'Authorization'
                    ] = `Bearer ${data.accessToken}`;
                    return axiosInstance.request(originalRequest);
                })
                .catch((error) => {
                    if (
                        error.response.status ===
                            AuthenticationResponse.TokenExpired &&
                        error.config.url.includes('/refresh')
                    ) {
                        removeTokens();
                        store.dispatch(removeSession());
                        history.push('/login');
                        return Promise.reject(error);
                    }
                });
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
