import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { array } from 'prop-types';
import React from 'react';
import EmptyFooter from './EmptyFooter';

const SimpleTable = ({ columns = [], items = [] }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                                minWidth: column.minWidth,
                                width: column.width,
                            }}
                        >
                            {column.label}
                        </TableCell>
                    ))}
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((item) => {
                    return (
                        <TableRow key={item.id} hover>
                            {columns.map((column) => {
                                const value = item[column.id];
                                return (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                    >
                                        {column.format
                                            ? column.format(value)
                                            : value}
                                    </TableCell>
                                );
                            })}
                            <TableCell />
                        </TableRow>
                    );
                })}
            </TableBody>
            <EmptyFooter
                itemLength={items.length}
                colSpan={columns.length + 1}
            />
        </Table>
    );
};

SimpleTable.propTypes = {
    items: array,
    columns: array,
};

export default SimpleTable;
