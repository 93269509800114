import { createMuiTheme } from '@material-ui/core';

export const colors = {
    BLACK: '#000000',
    WHITE: '#fff',
    LIGHTGREY: '#f4f6f8',
    LIGHTBLUE: '#5a68d6',
    MENUBLACK: '#524f4f',
    MAIN: '#B82542',
    SECONDARY: '#B82542',
    MIDGREY: '#87898c',
    DISABLEDGREY: '#c6c7ca',
    RED: '#f44336',
    DRAGACTIVE: '#2196f3',
    DRAGREJECT: '#ff1744',
    DRAGACCEPT: '#00e676',
    DRAGGREY: '#bdbdbd',
    WHITEGREY: '#eeeeee',
    TRANSPARENT: 'rgb(189 186 186 / 50%)',
};

export const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: colors.MAIN,
        },
        secondary: {
            main: colors.SECONDARY,
        },
    },
    breakpoints: {
        values: {
            xs: 700,
            sm: 900,
            md: 1100,
            ml: 1500,
            lg: 1700,
            xl: 1900,
        },
    },
});

export const styles = {
    title: {
        maxWidth: 180,
    },
    desc: {
        maxWidth: 250,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    url: {
        maxWidth: 100,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    twoRowList: {
        listStyleType: 'circle',
        margin: '0px 0px 0px 5px',
        padding: 0,
        columns: 2,
    },
    oneRowList: {
        listStyleType: 'circle',
        margin: '0px 0px 0px 5px',
        padding: 0,
        columns: 1,
    },
    icon: {
        paddingRight: 5,
    },
    defaultContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
    },
    newLine: {
        whiteSpace: 'pre-line',
    },
    divider: {
        margin: '15px 0',
    },
    formatter: {
        width: 80,
    },
    side: {
        width: '48%',
    },
    form: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    verticalDivider: {
        margin: 5,
    },
};
