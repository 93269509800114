import { array, number } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    deleteWineryImageRequest,
    selectWineryCoverPhotoRequest,
} from '../../actions/images';
import { ImageGridContainer } from '../../components/Grid';

const WineryImageGrid = ({ images, wineryId }) => {
    const dispatch = useDispatch();

    const onStarClick = (id, value) => {
        dispatch(
            selectWineryCoverPhotoRequest({
                wineryId,
                imageId: id,
                isCover: value,
            })
        );
    };

    return (
        <ImageGridContainer
            onStarClick={onStarClick}
            onDeleteAction={(id) =>
                dispatch(deleteWineryImageRequest({ id, wineryId }))
            }
            images={images}
        />
    );
};

WineryImageGrid.propTypes = {
    images: array.isRequired,
    wineryId: number,
};

export default WineryImageGrid;
