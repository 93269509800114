import {
    ADD_NEW_USER_FAILED,
    ADD_NEW_USER_REQUEST,
    ADD_NEW_USER_SUCCESS,
    CLEAR_SELECTED_USERS,
    DELETE_USERS_FAILED,
    DELETE_USERS_REQUEST,
    DELETE_USERS_SUCCESS,
    GENERATE_PASSWORD_FAILED,
    GENERATE_PASSWORD_REQUEST,
    GENERATE_PASSWORD_SUCCESS,
    GET_MOBILE_USERS_FAILED,
    GET_MOBILE_USERS_REQUEST,
    GET_MOBILE_USERS_SUCCESS,
    GET_USERS_FAILED,
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    SET_SELECTED_USERS,
    UPDATE_USER_FAILED,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
} from './constants';

export const getUsersRequest = (payload) => {
    return {
        type: GET_USERS_REQUEST,
        payload,
    };
};

export const getUsersSuccess = (payload) => {
    return {
        type: GET_USERS_SUCCESS,
        payload,
    };
};

export const getUsersFailed = (payload) => {
    return {
        type: GET_USERS_FAILED,
        payload,
    };
};

export const setSelectedUsers = (payload) => {
    return {
        type: SET_SELECTED_USERS,
        payload,
    };
};

export const clearSelectedUsers = () => {
    return {
        type: CLEAR_SELECTED_USERS,
    };
};

export const deleteUsersRequest = (payload) => {
    return {
        type: DELETE_USERS_REQUEST,
        payload,
    };
};

export const deleteUsersSuccess = (payload) => {
    return {
        type: DELETE_USERS_SUCCESS,
        payload,
    };
};

export const deleteUsersFailed = (payload) => {
    return {
        type: DELETE_USERS_FAILED,
        payload,
    };
};

export const addNewUserRequest = (payload) => {
    return {
        type: ADD_NEW_USER_REQUEST,
        payload,
    };
};

export const addNewUserSuccess = (payload) => {
    return {
        type: ADD_NEW_USER_SUCCESS,
        payload,
    };
};

export const addNewUserFailed = (payload) => {
    return {
        type: ADD_NEW_USER_FAILED,
        payload,
    };
};

export const generatePasswordRequest = (payload) => {
    return {
        type: GENERATE_PASSWORD_REQUEST,
        payload,
    };
};

export const generatePasswordSuccess = () => {
    return {
        type: GENERATE_PASSWORD_SUCCESS,
    };
};

export const generatePasswordFailed = (payload) => {
    return {
        type: GENERATE_PASSWORD_FAILED,
        payload,
    };
};

export const updateUserRequest = (payload) => {
    return {
        type: UPDATE_USER_REQUEST,
        payload,
    };
};

export const updateUserSuccess = (payload) => {
    return {
        type: UPDATE_USER_SUCCESS,
        payload,
    };
};

export const updateUserFailed = (payload) => {
    return {
        type: UPDATE_USER_FAILED,
        payload,
    };
};

export const getMobileUsersRequest = (payload) => {
    return {
        type: GET_MOBILE_USERS_REQUEST,
        payload,
    };
};

export const getMobileUsersSuccess = (payload) => {
    return {
        type: GET_MOBILE_USERS_SUCCESS,
        payload,
    };
};

export const getMobileUsersFailed = (payload) => {
    return {
        type: GET_MOBILE_USERS_FAILED,
        payload,
    };
};
