import { call, put, takeLatest } from '@redux-saga/core/effects';
import { GET_STATISTICS_DATA_REQUEST } from '../actions/constants';
import { setNotification } from '../actions/notification';
import {
    getStatisticsDataFailed,
    getStatisticsDataSuccess,
} from '../actions/statistics';
import API from '../constants/api';
import { NotificationType } from '../constants/enums';

function getStatistics() {
    return API.get(`/statistics`);
}

function* getStatisticsDataRequest() {
    try {
        const { data } = yield call(getStatistics);
        yield put(getStatisticsDataSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getStatisticsDataFailed(error));
    }
}

export default function* watchStatistics() {
    yield takeLatest(GET_STATISTICS_DATA_REQUEST, getStatisticsDataRequest);
}
