import React from 'react';
import { CssBaseline, Avatar, Typography, Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';

const Login = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img
                    src="/assets/images/szekszard-logo-center.png"
                    className={classes.image}
                />
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('login.signIn')}
                </Typography>
                <LoginForm />
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    image: {
        width: '40%',
    },
}));

export default Login;
