import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsSuperAdmin, selectUserWinery } from '../constants/selectors';

export const useEventValidation = ({
    name,
    location,
    fromDate,
    toDate,
    description,
    wineryIds = [],
}) => {
    const [helperText, setHelperText] = useState('');
    const [isValid, setIsValid] = useState(false);
    const { t } = useTranslation();
    const adminWinery = useSelector(selectUserWinery);
    const isSuperAdmin = useSelector(selectIsSuperAdmin);

    useEffect(() => {
        if (!isSuperAdmin && !wineryIds.some((id) => id === adminWinery?.id)) {
            setHelperText(`${t('notification.validation.invalid.winery')}`);
            setIsValid(false);
            return;
        }
        if (
            isEmpty(name.trim()) ||
            isEmpty(location.trim()) ||
            isEmpty(description.trim()) ||
            !fromDate ||
            !toDate
        ) {
            setHelperText(`${t('notification.validation.required-fields')}`);
            setIsValid(false);
            return;
        }
        if (moment(fromDate).isAfter(toDate)) {
            setHelperText(`${t('notification.validation.invalid.date')}`);
            setIsValid(false);
            return;
        }
        setIsValid(true);
        setHelperText('');
        return;
    }, [name, location, fromDate, toDate, description, wineryIds]);

    return [helperText, isValid];
};
