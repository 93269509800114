import {
    CLEAR_SELECTED_OFFERS,
    EDIT_OFFER_FAILED,
    EDIT_OFFER_REQUEST,
    EDIT_OFFER_SUCCESS,
    GET_OFFERS_FAILED,
    GET_OFFERS_REQUEST,
    GET_OFFERS_SUCCESS,
    SET_SELECTED_OFFERS,
    ADD_NEW_OFFER_FAILED,
    ADD_NEW_OFFER_REQUEST,
    ADD_NEW_OFFER_SUCCESS,
    DELETE_OFFERS_FAILED,
    DELETE_OFFERS_REQUEST,
    DELETE_OFFERS_SUCCESS,
} from './constants';

export const getOffersRequest = (payload) => {
    return {
        type: GET_OFFERS_REQUEST,
        payload,
    };
};

export const getOffersSuccess = (payload) => {
    return {
        type: GET_OFFERS_SUCCESS,
        payload,
    };
};

export const getOffersFailed = (payload) => {
    return {
        type: GET_OFFERS_FAILED,
        payload,
    };
};

export const editOfferRequest = (payload) => {
    return {
        type: EDIT_OFFER_REQUEST,
        payload,
    };
};

export const editOfferSuccess = (payload) => {
    return {
        type: EDIT_OFFER_SUCCESS,
        payload,
    };
};

export const editOfferFailed = (payload) => {
    return {
        type: EDIT_OFFER_FAILED,
        payload,
    };
};

export const setSelectedOffers = (payload) => {
    return {
        type: SET_SELECTED_OFFERS,
        payload,
    };
};

export const clearSelectedOffers = () => {
    return {
        type: CLEAR_SELECTED_OFFERS,
    };
};

export const addNewOfferRequest = (payload) => {
    return {
        type: ADD_NEW_OFFER_REQUEST,
        payload,
    };
};

export const addNewOfferSuccess = (payload) => {
    return {
        type: ADD_NEW_OFFER_SUCCESS,
        payload,
    };
};

export const addNewOfferFailed = (payload) => {
    return {
        type: ADD_NEW_OFFER_FAILED,
        payload,
    };
};

export const deleteOffersRequest = (payload) => {
    return {
        type: DELETE_OFFERS_REQUEST,
        payload,
    };
};

export const deleteOffersSuccess = (payload) => {
    return {
        type: DELETE_OFFERS_SUCCESS,
        payload,
    };
};

export const deleteOffersFailed = (payload) => {
    return {
        type: DELETE_OFFERS_FAILED,
        payload,
    };
};
