export const NotificationType = {
    Error: 'error',
    Success: 'success',
    Info: 'info',
    Warning: 'warning',
};

export const Roles = {
    Admin: 'ADMIN',
    SuperAdmin: 'SUPER_ADMIN',
    User: 'USER',
};

export const AccommodationTypes = [
    { id: 'RESTAURANT', name: 'accommodations.types.restaurant' },
    { id: 'ACCOMMODATION', name: 'accommodations.types.accommodation' },
    { id: 'BOTH', name: 'accommodations.types.both' },
];

export const PostStatus = {
    Published: 'PUBLISHED',
    Draft: 'DRAFT',
};

export const StatusTypes = [
    { id: 'DRAFT', name: 'news.types.draft' },
    { id: 'PUBLISHED', name: 'news.types.published' },
];

export const AuthenticationResponse = {
    TokenExpired: 490,
};

export const RoleNames = [
    { name: 'ADMIN', value: 'role.admin' },
    { name: 'USER', value: 'role.user' },
    { name: 'SUPER_ADMIN', value: 'role.superadmin' },
];
