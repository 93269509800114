import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import { number } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptyFooter = ({ itemLength, colSpan }) => {
    const { t } = useTranslation();

    if (itemLength === 0) {
        return (
            <TableFooter>
                <TableRow align="center">
                    <TableCell colSpan={colSpan} align="center">
                        {t('table.noData')}
                    </TableCell>
                </TableRow>
            </TableFooter>
        );
    }
    return null;
};

EmptyFooter.propTypes = {
    itemLength: number,
    colSpan: number,
};

export default EmptyFooter;
