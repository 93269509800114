import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { func } from 'prop-types';
import { styles } from '../../constants/theme';

const PasswordMenuItem = ({ onGeneratePasswordClick }) => {
    const { t } = useTranslation();

    return (
        <MenuItem onClick={onGeneratePasswordClick}>
            <VpnKeyIcon style={styles.icon} /> {t('actions.generatePassword')}
        </MenuItem>
    );
};

PasswordMenuItem.propTypes = {
    onGeneratePasswordClick: func,
};

export default PasswordMenuItem;
