import {
    GET_ACCOMMODATIONS_REQUEST,
    GET_ACCOMMODATIONS_SUCCESS,
    GET_ACCOMMODATIONS_FAILED,
    CLEAR_SELECTED_ACCOMMODATIONS,
    SET_SELECTED_ACCOMMODATIONS,
    EDIT_ACCOMMODATION_REQUEST,
    EDIT_ACCOMMODATION_SUCCESS,
    EDIT_ACCOMMODATION_FAILED,
    ADD_NEW_ACCOMMODATION_REQUEST,
    ADD_NEW_ACCOMMODATION_SUCCESS,
    ADD_NEW_ACCOMMODATION_FAILED,
    DELETE_ACCOMMODATIONS_REQUEST,
    DELETE_ACCOMMODATIONS_SUCCESS,
    DELETE_ACCOMMODATIONS_FAILED,
} from './constants';

export const getAccommodationsRequest = (payload) => {
    return {
        type: GET_ACCOMMODATIONS_REQUEST,
        payload,
    };
};

export const getAccommodationsSuccess = (payload) => {
    return {
        type: GET_ACCOMMODATIONS_SUCCESS,
        payload,
    };
};

export const getAccommodationsFailed = (payload) => {
    return {
        type: GET_ACCOMMODATIONS_FAILED,
        payload,
    };
};

export const setSelectedAccommodations = (payload) => {
    return {
        type: SET_SELECTED_ACCOMMODATIONS,
        payload,
    };
};

export const clearSelectedAccommodations = () => {
    return {
        type: CLEAR_SELECTED_ACCOMMODATIONS,
    };
};

export const editAccommodationRequest = (payload) => {
    return {
        type: EDIT_ACCOMMODATION_REQUEST,
        payload,
    };
};

export const editAccommodationSuccess = (payload) => {
    return {
        type: EDIT_ACCOMMODATION_SUCCESS,
        payload,
    };
};

export const editAccommodationFailed = (payload) => {
    return {
        type: EDIT_ACCOMMODATION_FAILED,
        payload,
    };
};

export const addNewAccommodationRequest = (payload) => {
    return {
        type: ADD_NEW_ACCOMMODATION_REQUEST,
        payload,
    };
};

export const addNewAccommodationSuccess = (payload) => {
    return {
        type: ADD_NEW_ACCOMMODATION_SUCCESS,
        payload,
    };
};

export const addNewAccommodationFailed = (payload) => {
    return {
        type: ADD_NEW_ACCOMMODATION_FAILED,
        payload,
    };
};

export const deleteAccommodationsRequest = (payload) => {
    return {
        type: DELETE_ACCOMMODATIONS_REQUEST,
        payload,
    };
};

export const deleteAccommodationsSuccess = (payload) => {
    return {
        type: DELETE_ACCOMMODATIONS_SUCCESS,
        payload,
    };
};

export const deleteAccommodationsFailed = (payload) => {
    return {
        type: DELETE_ACCOMMODATIONS_FAILED,
        payload,
    };
};
