import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsRequest } from '../../actions/events';
import {
    selectEventLoading,
    selectEvents,
    selectSelectedEvents,
} from '../../constants/selectors';
import history from '../../constants/history';
import EventTable from './EventTable';
import EventModal from './EventModal';
import { EVENT_PAGE_SIZE } from '../../constants/constants';
import ListLayout from '../../components/Layout/ListLayout';
import useUrlParams from '../../hooks/useUrlParams';
import { useTranslation } from 'react-i18next';

const EventList = () => {
    const selectedEventIds = useSelector(selectSelectedEvents);
    const isRequested = useSelector(selectEventLoading);
    const {
        items = [],
        totalItems = 0,
        size = EVENT_PAGE_SIZE,
    } = useSelector(selectEvents);
    const params = useUrlParams();
    const [page, setPage] = useState(params.page || 0);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(
            getEventsRequest({
                page,
                size: EVENT_PAGE_SIZE,
                sort: '',
            })
        );
    }, [page]);

    const handlePageChange = (_, newPage) => {
        setPage(newPage);
        history.push(`/events?page=${newPage}`);
    };

    const onEditMenuClick = () => {
        setEditOpen(true);
        setAnchorEl(null);
    };

    return (
        <>
            <ListLayout
                title={t('drawer.events')}
                isRequested={isRequested}
                size={size}
                page={page}
                handlePageChange={handlePageChange}
                totalItems={totalItems}
            >
                <EventTable
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    selectedEventIds={selectedEventIds}
                    items={items}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    onEditMenuClick={onEditMenuClick}
                />
            </ListLayout>
            <EventModal
                isEdit={true}
                event={selectedEvent}
                open={editOpen}
                onClose={() => setEditOpen(false)}
            />
        </>
    );
};

export default EventList;
