import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from './constants';

export const setNotification = (payload) => {
    return {
        type: SET_NOTIFICATION,
        payload,
    };
};

export const removeNotification = (payload) => {
    return {
        type: REMOVE_NOTIFICATION,
        payload,
    };
};
