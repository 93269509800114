import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EMAIL_REGEX } from '../constants/constants';

export const useUserValidation = ({
    email,
    firstName,
    lastName,
    winery,
    isSuperAdmin,
}) => {
    const [helperText, setHelperText] = useState('');
    const [isValid, setIsValid] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (
            isEmpty(email) ||
            isEmpty(firstName.trim()) ||
            isEmpty(lastName.trim()) ||
            (!winery && !isSuperAdmin)
        ) {
            setHelperText(`${t('notification.validation.required-fields')}`);
            setIsValid(false);
            return;
        } else if (!EMAIL_REGEX.test(email)) {
            setHelperText(`${t('notification.validation.invalid.email')}`);
            setIsValid(false);
            return;
        }
        setIsValid(true);
        setHelperText('');
        return;
    }, [email, firstName, lastName, winery, isSuperAdmin]);

    return [helperText, isValid, setHelperText];
};
