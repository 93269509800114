import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { array, bool, func, string } from 'prop-types';
import { colors } from '../../constants/theme';
import { Label } from '../Form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, items, theme) {
    return {
        fontWeight:
            items.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MultipleSelect = ({
    value,
    items,
    label,
    onChange,
    name,
    required = false,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <FormControl className={classes.formControl}>
            <Label
                isSelectLabel
                content={label}
                id="multiple-select-label"
                required={required}
            />
            <Select
                className={classes.select}
                labelId="multiple-chip-label"
                id="multiple-chip"
                multiple
                value={value}
                name={name}
                onChange={onChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => {
                    return (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip
                                    key={value}
                                    label={
                                        items.find((item) => item.id === value)
                                            ?.name
                                    }
                                    className={classes.chip}
                                />
                            ))}
                        </div>
                    );
                }}
                MenuProps={MenuProps}
            >
                {items.map(({ id, name }) => (
                    <MenuItem
                        key={id}
                        value={id}
                        style={getStyles(name, items, theme)}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
        width: '100%',
        position: 'relative',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        margin: `${theme.spacing(3)}px 5px 0px 5px`,
    },
    select: {
        '&.MuiInput-underline:after': {
            borderColor: colors.BLACK,
        },
    },
}));

MultipleSelect.propTypes = {
    value: array,
    items: array.isRequired,
    label: string,
    onChange: func.isRequired,
    name: string,
    required: bool,
};

export default MultipleSelect;
