// Session

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOG_OUT = 'LOG_OUT';
export const REMOVE_SESSION = 'REMOVE_SESSION';
export const UPDATE_ME_REQUEST = 'UPDATE_ME_REQUEST';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_FAILED = 'UPDATE_ME_FAILED';
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';
export const GET_SESSION_REQUEST = 'GET_SESSION_REQUEST';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAILED = 'GET_SESSION_FAILED';

// Notification

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// Users

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const GET_MOBILE_USERS_REQUEST = 'GET_MOBILE_USERS_REQUEST';
export const GET_MOBILE_USERS_SUCCESS = 'GET_MOBILE_USERS_SUCCESS';
export const GET_MOBILE_USERS_FAILED = 'GET_MOBILE_USERS_FAILED';
export const SET_SELECTED_USERS = 'SET_SELECTED_USERS';
export const CLEAR_SELECTED_USERS = 'CLEAR_SELECTED_USERS';
export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_FAILED = 'DELETE_USERS_FAILED';
export const ADD_NEW_USER_REQUEST = 'ADD_NEW_USER_REQUEST';
export const ADD_NEW_USER_SUCCESS = 'ADD_NEW_USER_SUCCESS';
export const ADD_NEW_USER_FAILED = 'ADD_NEW_USER_FAILED';
export const GENERATE_PASSWORD_REQUEST = 'GENERATE_PASSWORD_REQUEST';
export const GENERATE_PASSWORD_SUCCESS = 'GENERATE_PASSWORD_SUCCESS';
export const GENERATE_PASSWORD_FAILED = 'GENERATE_PASSWORD_FAILED';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

// Wineries

export const GET_WINERIES_REQUEST = 'GET_WINERIES_REQUEST';
export const GET_WINERIES_SUCCESS = 'GET_WINERIES_SUCCESS';
export const GET_WINERIES_FAILED = 'GET_WINERIES_FAILED';
export const GET_ALL_WINERIES_REQUEST = 'GET_ALL_WINERIES_REQUEST';
export const GET_ALL_WINERIES_SUCCESS = 'GET_ALL_WINERIES_SUCCESS';
export const GET_ALL_WINERIES_FAILED = 'GET_ALL_WINERIES_FAILED';
export const EDIT_WINERY_REQUEST = 'EDIT_WINERY_REQUEST';
export const EDIT_WINERY_SUCCESS = 'EDIT_WINERY_SUCCESS';
export const EDIT_WINERY_FAILED = 'EDIT_WINERY_FAILED';
export const DELETE_WINERY_REQUEST = 'DELETE_WINERY_REQUEST';
export const DELETE_WINERY_SUCCESS = 'DELETE_WINERY_SUCCESS';
export const DELETE_WINERY_FAILED = 'DELETE_WINERY_FAILED';
export const ADD_NEW_WINERY_REQUEST = 'ADD_NEW_WINERY_REQUEST';
export const ADD_NEW_WINERY_SUCCESS = 'ADD_NEW_WINERY_SUCCESS';
export const ADD_NEW_WINERY_FAILED = 'ADD_NEW_WINERY_FAILED';

// Events

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = 'GET_EVENTS_FAILED';
export const SET_SELECTED_EVENTS = 'SET_SELECTED_EVENTS';
export const CLEAR_SELECTED_EVENTS = 'CLEAR_SELECTED_EVENTS';
export const ADD_NEW_EVENT_REQUEST = 'ADD_NEW_EVENT_REQUEST';
export const ADD_NEW_EVENT_SUCCESS = 'ADD_NEW_EVENT_SUCCESS';
export const ADD_NEW_EVENT_FAILED = 'ADD_NEW_EVENT_FAILED';
export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILED = 'EDIT_EVENT_FAILED';
export const DELETE_EVENTS_REQUEST = 'DELETE_EVENTS_REQUEST';
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export const DELETE_EVENTS_FAILED = 'DELETE_EVENTS_FAILED';
export const GET_ALL_EVENTS_REQUEST = 'GET_ALL_EVENTS_REQUEST';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAILED = 'GET_ALL_EVENTS_FAILED';

// Images

export const UPLOAD_WINERY_IMAGE_REQUEST = 'UPLOAD_WINERY_IMAGE_REQUEST';
export const UPLOAD_WINERY_IMAGE_SUCCESS = 'UPLOAD_WINERY_IMAGE_SUCCESS';
export const UPLOAD_WINERY_IMAGE_FAILED = 'UPLOAD_WINERY_IMAGE_FAILED';
export const DELETE_WINERY_IMAGE_REQUEST = 'DELETE_WINERY_IMAGE_REQUEST';
export const DELETE_WINERY_IMAGE_SUCCESS = 'DELETE_WINERY_IMAGE_SUCCESS';
export const DELETE_WINERY_IMAGE_FAILED = 'DELETE_WINERY_IMAGE_FAILED';
export const UPLOAD_ACCOMMODATION_IMAGE_REQUEST =
    'UPLOAD_ACCOMMODATION_IMAGE_REQUEST';
export const UPLOAD_ACCOMMODATION_IMAGE_SUCCESS =
    'UPLOAD_ACCOMMODATION_IMAGE_SUCCESS';
export const UPLOAD_ACCOMMODATION_IMAGE_FAILED =
    'UPLOAD_ACCOMMODATION_IMAGE_FAILED';
export const DELETE_ACCOMMODATION_IMAGE_REQUEST =
    'DELETE_ACCOMMODATION_IMAGE_REQUEST';
export const DELETE_ACCOMMODATION_IMAGE_SUCCESS =
    'DELETE_ACCOMMODATION_IMAGE_SUCCESS';
export const DELETE_ACCOMMODATION_IMAGE_FAILED =
    'DELETE_ACCOMMODATION_IMAGE_FAILED';
export const SELECT_WINERY_COVER_PHOTO_REQUEST =
    'SELECT_WINERY_COVER_PHOTO_REQUEST';
export const SELECT_WINERY_COVER_PHOTO_SUCCESS =
    'SELECT_WINERY_COVER_PHOTO_SUCCESS';
export const SELECT_WINERY_COVER_PHOTO_FAILED =
    'SELECT_WINERY_COVER_PHOTO_FAILED';
export const SELECT_ACCOMMODATION_COVER_PHOTO_REQUEST =
    'SELECT_ACCOMMODATION_COVER_PHOTO_REQUEST';
export const SELECT_ACCOMMODATION_COVER_PHOTO_SUCCESS =
    'SELECT_ACCOMMODATION_COVER_PHOTO_SUCCESS';
export const SELECT_ACCOMMODATION_COVER_PHOTO_FAILED =
    'SELECT_ACCOMMODATION_COVER_PHOTO_FAILED';
export const SELECT_OFFER_COVER_PHOTO_REQUEST =
    'SELECT_OFFER_COVER_PHOTO_REQUEST';
export const SELECT_OFFER_COVER_PHOTO_SUCCESS =
    'SELECT_OFFER_COVER_PHOTO_SUCCESS';
export const SELECT_OFFER_COVER_PHOTO_FAILED =
    'SELECT_OFFER_COVER_PHOTO_FAILED';
export const UPLOAD_OFFER_IMAGE_REQUEST = 'UPLOAD_OFFER_IMAGE_REQUEST';
export const UPLOAD_OFFER_IMAGE_SUCCESS = 'UPLOAD_OFFER_IMAGE_SUCCESS';
export const UPLOAD_OFFER_IMAGE_FAILED = 'UPLOAD_OFFER_IMAGE_FAILED';
export const DELETE_OFFER_IMAGE_REQUEST = 'DELETE_OFFER_IMAGE_REQUEST';
export const DELETE_OFFER_IMAGE_SUCCESS = 'DELETE_OFFER_IMAGE_SUCCESS';
export const DELETE_OFFER_IMAGE_FAILED = 'DELETE_OFFER_IMAGE_FAILED';
export const SET_IMAGE = 'SET_IMAGE';

// Accommodations

export const GET_ACCOMMODATIONS_REQUEST = 'GET_ACCOMMODATIONS_REQUEST';
export const GET_ACCOMMODATIONS_SUCCESS = 'GET_ACCOMMODATIONS_SUCCESS';
export const GET_ACCOMMODATIONS_FAILED = 'GET_ACCOMMODATIONS_FAILED';
export const CLEAR_SELECTED_ACCOMMODATIONS = 'CLEAR_SELECTED_ACCOMMODATIONS';
export const SET_SELECTED_ACCOMMODATIONS = 'SET_SELECTED_ACCOMMODATIONS';
export const EDIT_ACCOMMODATION_REQUEST = 'EDIT_ACCOMMODATION_REQUEST';
export const EDIT_ACCOMMODATION_SUCCESS = 'EDIT_ACCOMMODATION_SUCCESS';
export const EDIT_ACCOMMODATION_FAILED = 'EDIT_ACCOMMODATION_FAILED';
export const ADD_NEW_ACCOMMODATION_REQUEST = 'ADD_NEW_ACCOMMODATION_REQUEST';
export const ADD_NEW_ACCOMMODATION_SUCCESS = 'ADD_NEW_ACCOMMODATION_SUCCESS';
export const ADD_NEW_ACCOMMODATION_FAILED = 'ADD_NEW_ACCOMMODATION_FAILED';
export const DELETE_ACCOMMODATIONS_REQUEST = 'DELETE_ACCOMMODATIONS_REQUEST';
export const DELETE_ACCOMMODATIONS_SUCCESS = 'DELETE_ACCOMMODATIONS_SUCCESS';
export const DELETE_ACCOMMODATIONS_FAILED = 'DELETE_ACCOMMODATIONS_FAILED';

// Statistics

export const GET_STATISTICS_DATA_REQUEST = 'GET_STATISTICS_DATA_REQUEST';
export const GET_STATISTICS_DATA_SUCCESS = 'GET_STATISTICS_DATA_SUCCESS';
export const GET_STATISTICS_DATA_FAILED = 'GET_STATISTICS_DATA_FAILED';

// Offers

export const GET_OFFERS_REQUEST = 'GET_OFFERS_REQUEST';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAILED = 'GET_OFFERS_FAILED';
export const EDIT_OFFER_REQUEST = 'EDIT_OFFER_REQUEST';
export const EDIT_OFFER_SUCCESS = 'EDIT_OFFER_SUCCESS';
export const EDIT_OFFER_FAILED = 'EDIT_OFFER_FAILED';
export const SET_SELECTED_OFFERS = 'SET_SELECTED_OFFERS';
export const CLEAR_SELECTED_OFFERS = 'CLEAR_SELECTED_OFFERS';
export const ADD_NEW_OFFER_REQUEST = 'ADD_NEW_OFFER_REQUEST';
export const ADD_NEW_OFFER_SUCCESS = 'ADD_NEW_OFFER_SUCCESS';
export const ADD_NEW_OFFER_FAILED = 'ADD_NEW_OFFER_FAILED';
export const DELETE_OFFERS_REQUEST = 'DELETE_OFFERS_REQUEST';
export const DELETE_OFFERS_SUCCESS = 'DELETE_OFFERS_SUCCESS';
export const DELETE_OFFERS_FAILED = 'DELETE_OFFERS_FAILED';

// News

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILED = 'GET_NEWS_FAILED';
export const GET_ALL_NEWS_REQUEST = 'GET_ALL_NEWS_REQUEST';
export const GET_ALL_NEWS_SUCCESS = 'GET_ALL_NEWS_SUCCESS';
export const GET_ALL_NEWS_FAILED = 'GET_ALL_NEWS_FAILED';
export const EDIT_NEWS_REQUEST = 'EDIT_NEWS_REQUEST';
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS';
export const EDIT_NEWS_FAILED = 'EDIT_NEWS_FAILED';
export const SET_SELECTED_NEWS = 'SET_SELECTED_NEWS';
export const CLEAR_SELECTED_NEWS = 'CLEAR_SELECTED_NEWS';
export const ADD_NEW_NEWS_REQUEST = 'ADD_NEW_NEWS_REQUEST';
export const ADD_NEW_NEWS_SUCCESS = 'ADD_NEW_NEWS_SUCCESS';
export const ADD_NEW_NEWS_FAILED = 'ADD_NEW_NEWS_FAILED';
export const DELETE_NEWS_REQUEST = 'DELETE_NEWS_REQUEST';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILED = 'DELETE_NEWS_FAILED';

// Travels

export const GET_TRAVELS_REQUEST = 'GET_TRAVELS_REQUEST';
export const GET_TRAVELS_SUCCESS = 'GET_TRAVELS_SUCCESS';
export const GET_TRAVELS_FAILED = 'GET_TRAVELS_FAILED';
export const EDIT_TRAVEL_REQUEST = 'EDIT_TRAVEL_REQUEST';
export const EDIT_TRAVEL_SUCCESS = 'EDIT_TRAVEL_SUCCESS';
export const EDIT_TRAVEL_FAILED = 'EDIT_TRAVEL_FAILED';
