import React, { useState } from 'react';
import { Avatar, Box, makeStyles, Typography } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import WineryModal from './WineryModal';
import { colors } from '../../constants/theme';
import WineryActionMenu from './WineryActionMenu';
import UploadModal from '../../components/Modal/UploadModal';
import { useDispatch } from 'react-redux';
import { uploadWineryImageRequest } from '../../actions/images';
import WarningModal from '../../components/Modal/WarningModal';
import { deleteWineryRequest } from '../../actions/wineries';
import WineryImageGrid from './WineryImageGrid';
import ItemCard from '../../components/Card/ItemCard';
import Link from '../../components/Routing/Link';
import useUrlParams from '../../hooks/useUrlParams';
import { WINERY_PAGE_SIZE } from '../../constants/constants';
import { DotButton } from '../../components/Button';

const WineryCard = ({ winery, isFullScreen = false }) => {
    const classes = useStyles({ isFullScreen });
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);
    const dispatch = useDispatch();
    const params = useUrlParams();

    const onMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onDeleteClick = () => {
        setDeleteOpen(true);
        setAnchorEl(null);
    };

    const onEditClick = () => {
        setEditOpen(true);
        setAnchorEl(null);
    };

    const onUploadClick = () => {
        setUploadOpen(true);
        setAnchorEl(null);
    };

    const onDelete = () => {
        setDeleteOpen(false);
        dispatch(
            deleteWineryRequest({
                id: winery.id,
                page: params.page - 1 || 0,
                size: WINERY_PAGE_SIZE,
            })
        );
    };

    const onImageUpload = (files) => {
        dispatch(
            uploadWineryImageRequest({
                id: winery.id,
                photos: files,
            })
        );
        setUploadOpen(false);
    };

    const getAvatarSrc = () => {
        const imageId = winery?.images?.find((image) => image.cover)?.id;
        if (imageId) {
            return `${process.env.REACT_APP_API_DOMAIN}/images/${imageId}`;
        }
        return '/assets/images/default-image.jpeg';
    };

    return (
        <>
            <ItemCard time={winery?.updatedAt}>
                <DotButton style={classes.dotIcon} onMenuClick={onMenuClick} />
                <WineryActionMenu
                    anchorEl={anchorEl}
                    handleClose={() => setAnchorEl(null)}
                    onEditClick={onEditClick}
                    onUploadClick={onUploadClick}
                    onDeleteClick={onDeleteClick}
                />
                <div className={classes.contentContainer}>
                    <div>
                        <Box className={classes.box}>
                            <Avatar
                                className={classes.avatar}
                                alt="Winery"
                                src={getAvatarSrc()}
                                variant="square"
                            />
                        </Box>
                    </div>
                    <div className={classes.details}>
                        <Typography
                            align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="h4"
                        >
                            {winery?.name}
                        </Typography>
                        <Typography
                            align="center"
                            gutterBottom
                            variant="subtitle2"
                        >
                            {winery?.address}
                        </Typography>
                        {winery?.webPage && (
                            <Typography
                                align="center"
                                color="textPrimary"
                                variant="subtitle2"
                                gutterBottom
                            >
                                {t('wineries.webPage')}:
                                <Link
                                    target="_blank"
                                    href={winery?.webPage}
                                    label={winery?.webPage}
                                ></Link>
                            </Typography>
                        )}
                        {winery?.webShop && (
                            <Typography
                                align="center"
                                color="textPrimary"
                                variant="subtitle2"
                                gutterBottom
                            >
                                {t('wineries.webShop')}:
                                <Link
                                    target="_blank"
                                    href={winery?.webShop}
                                    label={winery?.webShop}
                                ></Link>
                            </Typography>
                        )}
                        {winery?.phoneNumber && (
                            <Typography
                                gutterBottom
                                align="center"
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('wineries.phoneNumber')}:{' '}
                                {winery?.phoneNumber}
                            </Typography>
                        )}
                    </div>
                </div>
            </ItemCard>

            <WineryModal
                isEdit={true}
                winery={winery}
                open={editOpen}
                onClose={() => setEditOpen(false)}
            />
            <UploadModal
                title={t('wineries.wineryUpload')}
                onClose={() => setUploadOpen(false)}
                open={uploadOpen}
                onImageUpload={onImageUpload}
                images={winery.images}
            >
                <WineryImageGrid images={winery.images} wineryId={winery.id} />
            </UploadModal>
            <WarningModal
                open={deleteOpen}
                handleClose={() => setDeleteOpen(false)}
                content={t('wineries.confirmDelete')}
                okButtonText={t('actions.delete')}
                onOkClick={onDelete}
                title={t('wineries.toDelete')}
            />
        </>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: ({ isFullScreen }) => (isFullScreen ? '90%' : '100%'),
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 5,
    },
    detailBox: {
        justifyContent: 'space-between',
        margin: 0,
    },
    timeIcon: {
        paddingRight: 5,
    },
    grid: {
        alignItems: 'center',
        display: 'flex',
    },
    desc: {
        paddingTop: 15,
        display: '-webkit-box',
        overflow: 'hidden',
        '-webkit-line-clamp': ({ isFullScreen }) => (isFullScreen ? 6 : 4),
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
    },
    content: {
        position: 'relative',
        height: '85%',
    },
    avatar: {
        width: ({ isFullScreen }) => (isFullScreen ? 350 : 150),
        height: ({ isFullScreen }) => (isFullScreen ? 300 : 120),
        objectFit: 'contain',
    },
    dotIcon: {
        position: 'absolute',
        top: 5,
        right: 10,
        padding: 5,
    },
    link: {
        paddingLeft: 5,
        color: colors.BLACK,
        fontStyle: 'italic',
    },
    bottomContainer: {
        padding: 5,
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        height: ({ isFullScreen }) => isFullScreen && '100%',
        alignItems: 'center',
        flexDirection: ({ isFullScreen }) => (isFullScreen ? 'row' : 'column'),
    },
    details: {
        flexBasis: '40%',
    },
});

WineryCard.propTypes = {
    winery: object.isRequired,
    isFullScreen: bool,
};

export default WineryCard;
