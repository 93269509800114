import { array, number } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    deleteAccommodationImageRequest,
    selectAccommodationCoverPhotoRequest,
} from '../../actions/images';
import { ImageGridContainer } from '../../components/Grid';

const AccommodationImageGrid = ({ images = [], accommodationId }) => {
    const dispatch = useDispatch();

    const onStarClick = (id, value) => {
        dispatch(
            selectAccommodationCoverPhotoRequest({
                accommodationId,
                imageId: id,
                isCover: value,
            })
        );
    };

    return (
        <ImageGridContainer
            onStarClick={onStarClick}
            onDeleteAction={(id) =>
                dispatch(
                    deleteAccommodationImageRequest({ id, accommodationId })
                )
            }
            images={images}
        />
    );
};

AccommodationImageGrid.propTypes = {
    images: array,
    accommodationId: number,
};

export default AccommodationImageGrid;
