import { INITIAL_ACCOMMODATION_STATE } from '../constants/states';
import {
    GET_ACCOMMODATIONS_REQUEST,
    GET_ACCOMMODATIONS_FAILED,
    GET_ACCOMMODATIONS_SUCCESS,
    CLEAR_SELECTED_ACCOMMODATIONS,
    SET_SELECTED_ACCOMMODATIONS,
    EDIT_ACCOMMODATION_REQUEST,
    EDIT_ACCOMMODATION_FAILED,
    EDIT_ACCOMMODATION_SUCCESS,
    UPLOAD_ACCOMMODATION_IMAGE_REQUEST,
    UPLOAD_ACCOMMODATION_IMAGE_SUCCESS,
    UPLOAD_ACCOMMODATION_IMAGE_FAILED,
    DELETE_ACCOMMODATION_IMAGE_REQUEST,
    DELETE_ACCOMMODATION_IMAGE_SUCCESS,
    DELETE_ACCOMMODATION_IMAGE_FAILED,
    SELECT_ACCOMMODATION_COVER_PHOTO_REQUEST,
    SELECT_ACCOMMODATION_COVER_PHOTO_SUCCESS,
    SELECT_ACCOMMODATION_COVER_PHOTO_FAILED,
    ADD_NEW_ACCOMMODATION_REQUEST,
    ADD_NEW_ACCOMMODATION_FAILED,
    ADD_NEW_ACCOMMODATION_SUCCESS,
    DELETE_ACCOMMODATIONS_REQUEST,
    DELETE_ACCOMMODATIONS_FAILED,
    DELETE_ACCOMMODATIONS_SUCCESS,
} from '../actions/constants';

export default (state = INITIAL_ACCOMMODATION_STATE, action) => {
    switch (action.type) {
        case GET_ACCOMMODATIONS_REQUEST:
            return { ...state, isAccommodationsRequested: true };
        case GET_ACCOMMODATIONS_SUCCESS:
            return {
                ...state,
                isAccommodationsRequested: false,
                accommodations: action.payload,
            };
        case GET_ACCOMMODATIONS_FAILED:
            return { ...state, isAccommodationsRequested: true };
        case CLEAR_SELECTED_ACCOMMODATIONS:
            return { ...state, selectedAccommodations: [] };
        case SET_SELECTED_ACCOMMODATIONS:
            return { ...state, selectedAccommodations: action.payload };
        case EDIT_ACCOMMODATION_REQUEST:
            return { ...state, isEditRequested: true };
        case EDIT_ACCOMMODATION_SUCCESS:
            return {
                ...state,
                isEditRequested: false,
                accommodations: {
                    ...state.accommodations,
                    items: state.accommodations.items.map((accommodation) => {
                        if (accommodation.id === action.payload.id) {
                            return action.payload;
                        }
                        return accommodation;
                    }),
                },
            };
        case EDIT_ACCOMMODATION_FAILED:
            return { ...state, isEditRequested: false };
        case UPLOAD_ACCOMMODATION_IMAGE_REQUEST:
            return { ...state, isUploadRequested: true };
        case UPLOAD_ACCOMMODATION_IMAGE_SUCCESS:
            return {
                ...state,
                isUploadRequested: false,
                accommodations: {
                    ...state.accommodations,
                    items: state.accommodations.items.map((accommodation) => {
                        if (
                            accommodation.id === action.payload.accommodationId
                        ) {
                            return {
                                ...accommodation,
                                images: [
                                    ...accommodation.images,
                                    ...action.payload.urls,
                                ],
                            };
                        }
                        return accommodation;
                    }),
                },
            };
        case UPLOAD_ACCOMMODATION_IMAGE_FAILED:
            return { ...state, isUploadRequested: false };
        case DELETE_ACCOMMODATION_IMAGE_REQUEST:
            return { ...state, isDeleteRequested: true };
        case DELETE_ACCOMMODATION_IMAGE_SUCCESS:
            return {
                ...state,
                isDeleteRequested: false,
                accommodations: {
                    ...state.accommodations,
                    items: state.accommodations.items.map((accommodation) => {
                        if (
                            accommodation.id === action.payload.accommodationId
                        ) {
                            return {
                                ...accommodation,
                                images: accommodation.images.filter(
                                    (image) =>
                                        image.id !== action.payload.photoId
                                ),
                            };
                        }
                        return accommodation;
                    }),
                },
            };
        case DELETE_ACCOMMODATION_IMAGE_FAILED:
            return { ...state, isDeleteRequested: false };
        case SELECT_ACCOMMODATION_COVER_PHOTO_REQUEST:
            return { ...state, isCoverPhotoRequested: true };
        case SELECT_ACCOMMODATION_COVER_PHOTO_SUCCESS:
            return {
                ...state,
                isCoverPhotoRequested: false,
                accommodations: {
                    ...state.accommodations,
                    items: state.accommodations.items.map((accommodation) => {
                        if (
                            action.payload.accommodationId &&
                            accommodation.id === action.payload.accommodationId
                        ) {
                            return {
                                ...accommodation,
                                images: accommodation.images.map((image) => {
                                    if (image.id === action.payload.id) {
                                        return action.payload;
                                    }
                                    if (action.payload.cover) {
                                        return { ...image, cover: false };
                                    }
                                    return image;
                                }),
                            };
                        }
                        return accommodation;
                    }),
                },
            };
        case SELECT_ACCOMMODATION_COVER_PHOTO_FAILED:
            return { ...state, isCoverPhotoRequested: false };
        case ADD_NEW_ACCOMMODATION_REQUEST:
            return { ...state, isCreateRequested: true };
        case ADD_NEW_ACCOMMODATION_SUCCESS:
            return {
                ...state,
                isCreateRequested: false,
                accommodations: {
                    ...state.accommodations,
                    totalItems: state.accommodations.totalItems + 1,
                    items: [...state.accommodations.items, action.payload],
                },
            };
        case ADD_NEW_ACCOMMODATION_FAILED:
            return { ...state, isCreateRequested: false };
        case DELETE_ACCOMMODATIONS_REQUEST:
            return { ...state, isDeleteRequested: true };
        case DELETE_ACCOMMODATIONS_SUCCESS: {
            const deletedAccommodations = [...action.payload];
            const newAccommodations = state.accommodations.items.filter(
                (accommodation) =>
                    !deletedAccommodations.some(
                        (deletedAccomodation) =>
                            deletedAccomodation.id === accommodation.id
                    )
            );

            return {
                ...state,
                isDeleteRequested: false,

                accommodations: {
                    ...state.accommodations,
                    items: newAccommodations,
                    totalItems:
                        state.accommodations.totalItems -
                        deletedAccommodations.length,
                },
            };
        }
        case DELETE_ACCOMMODATIONS_FAILED:
            return { ...state, isDeleteRequested: false };
        default:
            return state;
    }
};
