import { Grid } from '@material-ui/core';
import { bool, func, number, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileDropzone, Label } from '../Form';

const ImageGridItem = ({ title, imageId, isEdit, setSelectedFile }) => {
    const { t } = useTranslation();

    return (
        <Grid item md={12} xs={12}>
            <Label content={title} />
            <FileDropzone
                onFileChange={(file) => setSelectedFile(file)}
                maxFiles={1}
                imageId={imageId}
                isEdit={isEdit}
                maxFileMessage={t('upload.maxOneUpload')}
            />
        </Grid>
    );
};

ImageGridItem.propTypes = {
    title: string.isRequired,
    isEdit: bool,
    imageId: number,
    setSelectedFile: func,
};

export default ImageGridItem;
