import { InputLabel, makeStyles } from '@material-ui/core';
import { string, bool, object } from 'prop-types';
import React from 'react';
import { colors } from '../../constants/theme';

const Label = ({ content, id, required = false, isSelectLabel = false }) => {
    const classes = useStyles();

    return (
        <InputLabel
            className={isSelectLabel ? classes.select : classes.label}
            id={id || ''}
        >
            {content} {required && '*'}
        </InputLabel>
    );
};

const useStyles = makeStyles({
    select: {
        '&.Mui-focused': {
            color: colors.BLACK,
        },
    },
    label: {
        margin: '10px 0px 5px 0px',
    },
});

Label.propTypes = {
    content: string.isRequired,
    required: bool,
    id: string,
    isSelectLabel: bool,
    extraStyle: object,
};

export default Label;
