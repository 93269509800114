import React from 'react';
import { bool, string } from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';

const SubmitButton = ({ label, isFullWidth = true }) => {
    const classes = useStyles();

    return (
        <Button
            type="submit"
            fullWidth={isFullWidth}
            variant="contained"
            color="primary"
            className={classes.submit}
        >
            {label}
        </Button>
    );
};

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

SubmitButton.propTypes = {
    label: string.isRequired,
    isFullWidth: bool,
};

export default SubmitButton;
