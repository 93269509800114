import React, { useState } from 'react';
import {
    makeStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Divider,
} from '@material-ui/core';
import { array, bool, element, func, string } from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { FileDropzone } from '../Form';
import { isEmpty } from 'lodash';
const UploadModal = ({
    open,
    onClose,
    title,
    onImageUpload,
    images,
    children,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [selectedFiles, setSelectedFiles] = useState([]);

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="dialog"
            maxWidth="xs"
            open={open}
        >
            <DialogTitle disableTypography className={classes.title}>
                <Typography className={classes.text} variant="h6">
                    {title}
                </Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content} dividers>
                <Typography className={classes.subtitle} variant="subtitle1">
                    {t('upload.uploadedPictures')}
                </Typography>
                {isEmpty(images) ? (
                    <Typography variant="caption">
                        {t('upload.nonUploaded')}
                    </Typography>
                ) : (
                    children
                )}
                <Divider />
                <Typography className={classes.subtitle} variant="subtitle1">
                    {t('upload.uploadNew')}
                </Typography>
                <FileDropzone
                    onFileChange={(files) => setSelectedFiles(files)}
                    maxFiles={3}
                    maxFileMessage={t('upload.maxThreeUpload')}
                />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button variant="outlined" onClick={onClose} color="primary">
                    {t('form.cancel')}
                </Button>
                <Button
                    variant="contained"
                    autoFocus
                    onClick={() => onImageUpload(selectedFiles)}
                    disabled={selectedFiles.length === 0}
                    color="primary"
                >
                    {t('form.upload')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
    },
    actions: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
    text: {
        alignSelf: 'center',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    subtitle: {
        margin: '10px 0',
    },
}));

UploadModal.propTypes = {
    open: bool.isRequired,
    onClose: func.isRequired,
    title: string.isRequired,
    onImageUpload: func.isRequired,
    images: array,
    children: element,
};

export default UploadModal;
