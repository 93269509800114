import { makeStyles } from '@material-ui/core';
import React from 'react';
import PasswordCard from './PasswordCard';
import ProfileCard from './ProfileCard';

const Profile = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ProfileCard />
            <PasswordCard />
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
});

export default Profile;
