import React from 'react';
import { useSelector } from 'react-redux';
import { selectSession } from '../../constants/selectors';
import { Route, Redirect } from 'react-router-dom';
import { array, element } from 'prop-types';
import { isEmpty } from 'lodash';

const PrivateRoute = ({ children, roleList, ...rest }) => {
    const { isLoggedIn, session } = useSelector(selectSession);

    if (isEmpty(session) && !isLoggedIn) {
        return <Redirect to={'/login'} />;
    }
    if (session && session.roles?.some((role) => roleList.includes(role))) {
        return <Route {...rest} render={() => children} />;
    }

    return <Redirect to={'/'} />;
};

PrivateRoute.propTypes = {
    children: element,
    roleList: array,
};

export default PrivateRoute;
