import React from 'react';
import {
    Table as MaterialTable,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Checkbox,
    makeStyles,
    TableContainer,
} from '@material-ui/core';
import { array, element, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    handleSelectAllItems,
    handleSelectOneItem,
} from '../../constants/tableHelper';
import EmptyFooter from './EmptyFooter';
import { DotButton } from '../Button';

const Table = ({
    columns,
    children,
    items,
    selectedItemIds,
    setSelectedItems,
    onMenuClick,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleSelectAll = (event) => {
        dispatch(setSelectedItems(handleSelectAllItems(event, items)));
    };

    const handleSelectOne = (e, id) => {
        dispatch(setSelectedItems(handleSelectOneItem(e, id, selectedItemIds)));
    };

    return (
        <TableContainer className={classes.table}>
            <MaterialTable stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={
                                    selectedItemIds?.length ===
                                        items?.filter((item) => !item.disabled)
                                            .length &&
                                    selectedItemIds.length > 0
                                }
                                color="primary"
                                indeterminate={
                                    selectedItemIds?.length > 0 &&
                                    selectedItemIds?.length <
                                        items?.filter((item) => !item.disabled)
                                            .length
                                }
                                onChange={handleSelectAll}
                            />
                        </TableCell>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => {
                        return (
                            <TableRow
                                selected={
                                    selectedItemIds.indexOf(item.id) !== -1
                                }
                                key={item.id}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={
                                            selectedItemIds.indexOf(item.id) !==
                                            -1
                                        }
                                        onChange={(e) =>
                                            handleSelectOne(e, item.id)
                                        }
                                        value="true"
                                        disabled={item.disabled}
                                    />
                                </TableCell>
                                {columns.map((column) => {
                                    const value = item[column.id];
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.format
                                                ? column.format(value)
                                                : value}
                                        </TableCell>
                                    );
                                })}
                                <TableCell>
                                    <DotButton
                                        style={classes.dotIcon}
                                        onMenuClick={(e) =>
                                            onMenuClick(e, item)
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                {children}
                <EmptyFooter
                    itemLength={items.length}
                    colSpan={columns.length + 2}
                />
            </MaterialTable>
        </TableContainer>
    );
};

const useStyles = makeStyles((theme) => ({
    dotIcon: {
        padding: 5,
    },
    table: {
        [theme.breakpoints.up('lg')]: {
            maxHeight: '78vh',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            maxHeight: '68vh',
        },
        [theme.breakpoints.between('ml', 'xl')]: {
            maxHeight: '72vh',
        },
        [theme.breakpoints.down('md')]: {
            maxHeight: '65vh',
        },
        overflow: 'auto',
    },
}));

Table.propTypes = {
    columns: array.isRequired,
    children: element,
    items: array,
    selectedItemIds: array,
    setSelectedItems: func,
    onMenuClick: func,
};

export default Table;
