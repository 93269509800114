import { call, put, takeLatest } from '@redux-saga/core/effects';
import { isEmpty } from 'lodash';
import {
    ADD_NEW_EVENT_REQUEST,
    DELETE_EVENTS_REQUEST,
    EDIT_EVENT_REQUEST,
    GET_ALL_EVENTS_REQUEST,
    GET_EVENTS_REQUEST,
} from '../actions/constants';
import {
    getEventsSuccess,
    getEventsFailed,
    addNewEventFailed,
    addNewEventSuccess,
    editEventSuccess,
    editEventFailed,
    deleteEventsSuccess,
    clearSelectedEvents,
    deleteEventsFailed,
    getAllEventsSuccess,
    getAllEventsFailed,
} from '../actions/events';
import { setImage } from '../actions/images';
import { setNotification } from '../actions/notification';
import API from '../constants/api';
import { NotificationType } from '../constants/enums';

function eventsRequest(payload) {
    return API.get('/events', {
        params: {
            ...payload,
        },
    });
}

function createRequest(payload) {
    return API.post('/events', payload);
}

function uploadEventImageRequest(payload) {
    return API.post(`/events/${payload.id}/images`, payload.file, {
        'Content-Type': `multipart/form-data`,
    });
}

function editEventRequest(payload) {
    return API.put(`/events/${payload.id}`, payload);
}

function deleteRequest(payload) {
    return API.patch('/events/delete', payload);
}

function allEventsRequest() {
    return API.get('/events/all');
}

function* getEventsRequest(payload) {
    try {
        const { data } = yield call(eventsRequest, payload.payload);
        yield put(getEventsSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getEventsFailed(error));
    }
}

function* addNewEventRequest(payload) {
    try {
        const { data } = yield call(createRequest, payload.payload);
        yield put(addNewEventSuccess(data));
        if (payload.payload.file && !isEmpty(payload.payload.file)) {
            let formData = new FormData();
            payload.payload.file.map((file) => formData.append('file', file));
            const imageUpload = yield call(uploadEventImageRequest, {
                file: formData,
                id: data.id,
            });
            yield put(
                setImage({
                    photoId: imageUpload.data.id,
                    eventId: data.id,
                })
            );
        }
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(addNewEventFailed(error));
    }
}

function* putEventEditRequest(payload) {
    try {
        const { data } = yield call(editEventRequest, payload.payload);
        yield put(editEventSuccess(data));
        if (payload.payload.file && !isEmpty(payload.payload.file)) {
            let formData = new FormData();
            payload.payload.file.map((file) => formData.append('file', file));
            const imageUpload = yield call(uploadEventImageRequest, {
                file: formData,
                id: data.id,
            });
            yield put(
                setImage({
                    photoId: imageUpload.data.id,
                    eventId: data.id,
                })
            );
        }
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(editEventFailed(error));
    }
}

function* deleteEventsRequest(payload) {
    try {
        const { data } = yield call(deleteRequest, payload.payload);
        yield put(deleteEventsSuccess(data));
        yield put(clearSelectedEvents());
        yield put(
            setNotification({
                message: 'notification.success.success-deletion',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(deleteEventsFailed(error));
    }
}

function* getAllEventsRequest() {
    try {
        const { data } = yield call(allEventsRequest);
        yield put(getAllEventsSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getAllEventsFailed(error));
    }
}

export default function* watchEvents() {
    yield takeLatest(GET_EVENTS_REQUEST, getEventsRequest);
    yield takeLatest(ADD_NEW_EVENT_REQUEST, addNewEventRequest);
    yield takeLatest(EDIT_EVENT_REQUEST, putEventEditRequest);
    yield takeLatest(DELETE_EVENTS_REQUEST, deleteEventsRequest);
    yield takeLatest(GET_ALL_EVENTS_REQUEST, getAllEventsRequest);
}
