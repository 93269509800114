import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectSession } from '../../constants/selectors';
import { updateMeRequest } from '../../actions/session';
import FormCardLayout from '../../components/Layout/FormCardLayout';
import { isEqual } from 'lodash';
import { UserDataGrid } from '../../components/Grid';
import { useUserDataValidation } from '../../hooks/useUserDataValidation';
import { HelperText } from '../../components/Form';

const ProfileCard = () => {
    const { t } = useTranslation();
    const { session } = useSelector(selectSession);
    const originalUser = {
        firstName: session?.firstName,
        lastName: session?.lastName,
        email: session?.email,
        phoneNumber: session?.phoneNumber,
    };
    const [user, setUser] = useState(originalUser);
    const [isDisabled, setIsDisabled] = useState(true);
    const [helperText, isValid, setHelperText] = useUserDataValidation({
        email: user.email,
        lastName: user.lastName,
        firstName: user.firstName,
    });
    const dispatch = useDispatch();

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value,
        });
        handleButtonDisable(event);
    };

    const handleButtonDisable = (event) => {
        if (isEqual(event.target.value, originalUser[event.target.name])) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    };

    const onSave = () => {
        if (isValid) {
            setHelperText('');
            dispatch(
                updateMeRequest({
                    id: session.id,
                    ...user,
                })
            );
            setIsDisabled(true);
        }
    };

    const onCancel = () => {
        setUser(originalUser);
        setIsDisabled(true);
    };

    return (
        <FormCardLayout
            onSave={onSave}
            buttonDisabled={isDisabled}
            subheader={t('profile.profileEdit')}
            title={t('profile.profile')}
            buttonTitle={t('form.save')}
            onCancel={onCancel}
        >
            <>
                <UserDataGrid values={user} handleChange={handleChange} />
                <HelperText helperText={helperText} />
            </>
        </FormCardLayout>
    );
};

export default ProfileCard;
