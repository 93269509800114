import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWineriesRequest } from '../../actions/wineries';
import Header from '../../components/Menu/Header';
import { selectSelectedOffers } from '../../constants/selectors';
import OfferList from './OfferList';
import OfferModal from './OfferModal';
import { useTranslation } from 'react-i18next';
import WarningModal from '../../components/Modal/WarningModal';
import { deleteOffersRequest } from '../../actions/offers';
import { styles } from '../../constants/theme';
import { getAllEventsRequest } from '../../actions/events';

const Offers = () => {
    const selectedOffers = useSelector(selectSelectedOffers);
    const dispatch = useDispatch();
    const [addOpen, setAddOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAllWineriesRequest());
        dispatch(getAllEventsRequest());
    }, []);

    const onDelete = () => {
        setDeleteOpen(false);
        dispatch(deleteOffersRequest(selectedOffers));
    };

    return (
        <div style={styles.defaultContainer}>
            <Header
                deleteDisabled={selectedOffers?.length === 0}
                onAddClick={() => setAddOpen(true)}
                onDeleteClick={() => setDeleteOpen(true)}
            />
            <OfferList />
            <OfferModal open={addOpen} onClose={() => setAddOpen(false)} />
            <WarningModal
                open={deleteOpen}
                handleClose={() => setDeleteOpen(false)}
                content={t('offers.confirmDelete')}
                okButtonText={t('actions.delete')}
                onOkClick={onDelete}
                title={t('offers.toDelete')}
            />
        </div>
    );
};

export default Offers;
