import React from 'react';
import ActionMenu from '../../components/Menu/ActionMenu';
import { func, object } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsSuperAdmin } from '../../constants/selectors';
import {
    DeleteMenuItem,
    ImageMenuItem,
    EditMenuItem,
} from '../../components/MenuItem';

const WineryActionMenu = ({
    onEditClick,
    anchorEl,
    handleClose,
    onUploadClick,
    onDeleteClick,
}) => {
    const isSuperAdmin = useSelector(selectIsSuperAdmin);

    return (
        <ActionMenu anchorEl={anchorEl} handleClose={handleClose}>
            <EditMenuItem onEditClick={onEditClick} />
            <ImageMenuItem onUploadClick={onUploadClick} />
            {isSuperAdmin && <DeleteMenuItem onDeleteClick={onDeleteClick} />}
        </ActionMenu>
    );
};

WineryActionMenu.propTypes = {
    anchorEl: object,
    handleClose: func,
    onEditClick: func,
    onUploadClick: func,
    onDeleteClick: func,
};

export default WineryActionMenu;
