import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { func } from 'prop-types';
import { styles } from '../../constants/theme';

const EditMenuItem = ({ onEditClick }) => {
    const { t } = useTranslation();

    return (
        <MenuItem onClick={onEditClick}>
            <EditIcon style={styles.icon} /> {t('actions.edit')}
        </MenuItem>
    );
};

EditMenuItem.propTypes = {
    onEditClick: func,
};

export default EditMenuItem;
