import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTravelsRequest } from '../../actions/travels';
import TravelCard from './TravelCard';
import { CircularProgress } from '@material-ui/core';
import { selectTravelLoading } from '../../constants/selectors';

const Travels = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isTravelLoading = useSelector(selectTravelLoading);

    useEffect(() => {
        dispatch(getTravelsRequest());
    }, []);

    if (isTravelLoading) {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <TravelCard />
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 25,
    },
});

export default Travels;
