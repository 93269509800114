import { bool, func, number, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { colors } from '../../constants/theme';
import { makeStyles } from '@material-ui/core';

const MAXIMUM_FILE_SIZE = 7;

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 25,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.MIDGREY,
    borderStyle: 'solid',
    backgroundColor: colors.LIGHTGREY,
    color: colors.DRAGGREY,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center',
};

const activeStyle = {
    borderColor: colors.DRAGACTIVE,
};

const acceptStyle = {
    borderColor: colors.DRAGACCEPT,
};

const rejectStyle = {
    borderColor: colors.DRAGREJECT,
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: `1px solid ${colors.MIDGREY}`,
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

const FileDropzone = ({
    onFileChange,
    maxFiles,
    imageId,
    isEdit = false,
    maxFileMessage,
}) => {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: 'image/jpeg, image/jpg, image/png',
        maxFiles,
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });
    const { t } = useTranslation();

    useEffect(() => {
        onFileChange(acceptedFiles);
    }, [acceptedFiles]);

    useEffect(
        () => () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    useEffect(() => {
        if (isEdit && imageId) {
            (async () => {
                const newFile = await createFile();
                setFiles([
                    { ...newFile, preview: URL.createObjectURL(newFile) },
                ]);
            })();
        }
    }, []);

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const createFile = () => {
        return fetch(`${process.env.REACT_APP_API_DOMAIN}/images/${imageId}`)
            .then((res) => res.blob())
            .then((blob) => {
                return new File([blob], 'tmp');
            });
    };

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.preview}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} />
            </div>
        </div>
    ));

    return (
        <div>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <span>{t('upload.dragAndDrop')}</span>
                <em>({maxFileMessage})</em>
                <span className={classes.error}>
                    {t('upload.maxSize', { mb: MAXIMUM_FILE_SIZE })}.
                </span>
            </div>
            <div>
                <aside style={thumbsContainer}>{thumbs}</aside>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    error: {
        color: colors.RED,
        marginTop: 10,
        fontSize: 12,
    },
});

FileDropzone.propTypes = {
    onFileChange: func,
    maxFiles: number.isRequired,
    maxFileMessage: string.isRequired,
    isEdit: bool,
    imageId: number,
};

export default FileDropzone;
