import {
    GET_TRAVELS_FAILED,
    GET_TRAVELS_REQUEST,
    GET_TRAVELS_SUCCESS,
    EDIT_TRAVEL_FAILED,
    EDIT_TRAVEL_REQUEST,
    EDIT_TRAVEL_SUCCESS,
} from './constants';

export const getTravelsRequest = () => {
    return {
        type: GET_TRAVELS_REQUEST,
    };
};

export const getTravelsSuccess = (payload) => {
    return {
        type: GET_TRAVELS_SUCCESS,
        payload,
    };
};

export const getTravelsFailed = (payload) => {
    return {
        type: GET_TRAVELS_FAILED,
        payload,
    };
};

export const editTravelRequest = (payload) => {
    return {
        type: EDIT_TRAVEL_REQUEST,
        payload,
    };
};

export const editTravelSuccess = (payload) => {
    return {
        type: EDIT_TRAVEL_SUCCESS,
        payload,
    };
};

export const editTravelFailed = (payload) => {
    return {
        type: EDIT_TRAVEL_FAILED,
        payload,
    };
};
