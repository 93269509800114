export const setToken = (type, token) => {
    localStorage.setItem(type, token);
};

export const getToken = (type) => {
    return localStorage.getItem(type);
};

export const removeTokens = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
};
