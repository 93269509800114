import { Grid } from '@material-ui/core';
import { func, object } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../Input';

const UserDataGrid = ({ handleChange, values }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <TextInput
                    onChange={handleChange}
                    label={t('profile.firstName')}
                    name="firstName"
                    id="firstName"
                    value={values?.firstName}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextInput
                    onChange={handleChange}
                    label={t('profile.lastName')}
                    name="lastName"
                    id="lastName"
                    value={values?.lastName}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextInput
                    onChange={handleChange}
                    label={t('profile.email')}
                    name="email"
                    id="email"
                    value={values?.email}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextInput
                    onChange={handleChange}
                    label={t('profile.phoneNumber')}
                    name="phoneNumber"
                    id="phoneNumber"
                    value={values?.phoneNumber}
                    isRequired={false}
                />
            </Grid>
        </Grid>
    );
};

UserDataGrid.propTypes = {
    handleChange: func,
    values: object,
};

export default UserDataGrid;
