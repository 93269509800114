import moment from 'moment';
import React from 'react';
import { HUNGARIAN_DATE_FORMAT } from './constants';
import { RoleNames } from './enums';
import { styles } from './theme';

export const handleSelectOneItem = (_, id, selectedItemIds) => {
    const selectedIndex = selectedItemIds.indexOf(id);
    let newSelectedItemIds = [];

    if (selectedIndex === -1) {
        newSelectedItemIds = newSelectedItemIds.concat(selectedItemIds, id);
    } else if (selectedIndex === 0) {
        newSelectedItemIds = newSelectedItemIds.concat(
            selectedItemIds.slice(1)
        );
    } else if (selectedIndex === newSelectedItemIds.length - 1) {
        newSelectedItemIds = newSelectedItemIds.concat(
            selectedItemIds.slice(0, -1)
        );
    } else if (selectedIndex > 0) {
        newSelectedItemIds = newSelectedItemIds.concat(
            selectedItemIds.slice(0, selectedIndex),
            selectedItemIds.slice(selectedIndex + 1)
        );
    }

    return newSelectedItemIds;
};

export const handleSelectAllItems = (event, items) => {
    let newSelectedItemIds;

    if (event.target.checked) {
        newSelectedItemIds = items
            .filter((item) => !item.disabled)
            .map((item) => item.id);
    } else {
        newSelectedItemIds = [];
    }

    return newSelectedItemIds;
};

export const imageFormatter = (images) => {
    const coverImage = images?.find((image) => image.cover);
    const src = coverImage
        ? `${process.env.REACT_APP_API_DOMAIN}/images/${coverImage.id}`
        : '';
    return <img src={src} style={styles.formatter} />;
};

export const coverImageFormatter = (imageId) => {
    const src = imageId
        ? `${process.env.REACT_APP_API_DOMAIN}/images/${imageId}`
        : '';
    return <img src={src} style={styles.formatter} />;
};

export const descriptionFormatter = (description) => {
    return <div style={styles.desc}>{description}</div>;
};

export const titleFormatter = (title) => {
    return <div style={styles.title}>{title}</div>;
};

export const urlFormatter = (url) => {
    return <div style={styles.url}>{url}</div>;
};

export const wineryListFormatter = (wineries) => {
    const listStyle =
        wineries && wineries.length > 2 ? styles.twoRowList : styles.oneRowList;
    return (
        <ul style={listStyle}>
            {wineries?.map(({ id, name }) => (
                <li key={id}>{name}</li>
            ))}
        </ul>
    );
};

export const dateFormatter = (date, text) => {
    return date ? moment(date).format(HUNGARIAN_DATE_FORMAT) : text;
};

export const newLineFormatter = (value) => {
    return <div style={styles.newLine}>{value}</div>;
};

export const enumFormatter = (types, value, t) => {
    const type = types.find((type) => type.id === value);
    return type ? <div>{t(type.name)}</div> : '';
};

export const roleFormatter = (roles, t) => {
    return roles
        .map((role) => {
            const type = RoleNames.find((type) => type.name === role);
            return t(type.value);
        })
        .join(', ');
};
