import React from 'react';
import DashboardCard from '../../components/Card/DashboardCard';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import EventIcon from '@material-ui/icons/Event';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import CommentIcon from '@material-ui/icons/Comment';
import { object } from 'prop-types';

const SummaryItems = ({ statistics }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const cardItems = [
        {
            id: 0,
            title: t('home.numberOfAppUsers'),
            data: statistics?.numberOfAppUsers,
            icon: <PhoneAndroidIcon />,
        },
        {
            id: 1,
            title: t('home.numberOfAdminUsers'),
            data: statistics?.numberOfAdminUsers,
            icon: <PeopleIcon />,
        },
        {
            id: 2,
            title: t('home.numberOfEvents'),
            data: statistics?.numberOfEvents,
            icon: <EventIcon />,
        },
        {
            id: 3,
            title: t('home.numberOfWineries'),
            data: statistics?.numberOfWineries,
            icon: <LocalBarIcon />,
        },
        {
            id: 4,
            title: t('home.numberOfOffers'),
            data: statistics?.numberOfOffers,
            icon: <AttachMoneyIcon />,
        },
        {
            id: 5,
            title: t('home.numberOfAccommodations'),
            data: statistics?.numberOfAccommodations,
            icon: <RestaurantMenuIcon />,
        },
        {
            id: 6,
            title: t('home.numberOfNews'),
            data: statistics?.numberOfNews,
            icon: <CommentIcon />,
        },
    ];

    return (
        <>
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                className={classes.title}
            >
                {t('home.summaryData')}
            </Typography>
            <Grid className={classes.grid} container spacing={4}>
                {cardItems.map((item) => (
                    <Grid key={item.id} item>
                        <DashboardCard
                            title={item.title}
                            data={item.data}
                            icon={item.icon}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

const useStyles = makeStyles({
    grid: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    title: {
        textAlign: 'center',
        paddingBottom: 10,
    },
});

SummaryItems.propTypes = {
    statistics: object,
};

export default SummaryItems;
