import {
    Box,
    Button,
    Card,
    CardHeader,
    Divider,
    makeStyles,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { element, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';

const ViewAllCardLayout = ({ children, title, href }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Card>
            <CardHeader title={title} />
            <Divider />
            <Box className={classes.contentBox}>{children}</Box>
            <Box className={classes.buttonBox}>
                <Button
                    color="primary"
                    endIcon={<ArrowRightIcon />}
                    size="small"
                    variant="text"
                    component={RouterLink}
                    to={href}
                >
                    {t('actions.viewAll')}
                </Button>
            </Box>
        </Card>
    );
};

const useStyles = makeStyles({
    contentBox: {
        minWidth: 800,
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 5,
    },
});

ViewAllCardLayout.propTypes = {
    title: string.isRequired,
    children: element.isRequired,
    href: string,
};

export default ViewAllCardLayout;
