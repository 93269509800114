import { makeStyles } from '@material-ui/core';
import { array, func, string } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageGridList } from '../../components/Grid';
import WarningModal from '../../components/Modal/WarningModal';

const ImageGridContainer = ({ images, onStarClick, alt, onDeleteAction }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState(null);
    const { t } = useTranslation();

    const onDelete = () => {
        setOpen(false);
        onDeleteAction(selectedImageId);
    };

    const onDeleteClick = (id) => {
        setOpen(true);
        setSelectedImageId(id);
    };

    return (
        <div className={classes.root}>
            <ImageGridList
                images={images}
                onDeleteClick={onDeleteClick}
                onStarClick={onStarClick}
                alt={alt}
            />
            <WarningModal
                open={open}
                handleClose={() => setOpen(false)}
                content={t('upload.confirmDelete')}
                okButtonText={t('actions.delete')}
                onOkClick={onDelete}
                title={t('upload.imageDeletion')}
            />
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        marginBottom: 25,
    },
}));

ImageGridContainer.propTypes = {
    images: array.isRequired,
    onDeleteAction: func,
    onStarClick: func,
    alt: string,
};

export default ImageGridContainer;
