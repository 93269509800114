import React, { useEffect, useRef, useState } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addNewNewsRequest, editNewsRequest } from '../../actions/news';
import { setNotification } from '../../actions/notification';
import { TextInput, MultipleSelect, HtmlEditor } from '../../components/Input';
import FormModal from '../../components/Modal/FormModal';
import { NotificationType, PostStatus } from '../../constants/enums';
import { HelperText, Switch, Label } from '../../components/Form';
import { useNewsValidation } from '../../hooks/useNewsValidation';
import { styles } from '../../constants/theme';
import {
    selectIsSuperAdmin,
    selectUserWinery,
    selectWineryDropdownItems,
} from '../../constants/selectors';
import { ImageGridItem } from '../../components/Grid';

const NewsModal = ({ open, onClose, news, isEdit = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const originalValues = {
        title: '',
        content: '',
        wineryIds: [],
        checked: false,
        summary: '',
    };
    const [values, setValues] = useState(originalValues);
    const [helperText, isValid] = useNewsValidation({
        title: values.title,
    });
    const editorRef = useRef(null);
    const wineryItems = useSelector(selectWineryDropdownItems);
    const isSuperAdmin = useSelector(selectIsSuperAdmin);
    const adminWinery = useSelector(selectUserWinery);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        if (isEdit && news) {
            setValues({
                title: news.title,
                content: news.content,
                summary: news.summary,
                wineryIds: news.wineries?.map((winery) => winery.id),
                checked: news.status === PostStatus.Published,
            });
        }
        if (!isSuperAdmin && !isEdit) {
            setValues({
                ...values,
                wineryId: adminWinery?.id,
            });
        }
    }, [open]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const onSave = () => {
        if (isValid) {
            const newsStatus = values.checked
                ? PostStatus.Published
                : PostStatus.Draft;
            if (isEdit) {
                dispatch(
                    editNewsRequest({
                        id: news?.id,
                        ...values,
                        content: editorRef?.current?.getContent(),
                        file: selectedFile,
                        status: newsStatus,
                    })
                );
            } else {
                dispatch(
                    addNewNewsRequest({
                        ...values,
                        content: editorRef?.current?.getContent(),
                        file: selectedFile,
                        status: newsStatus,
                    })
                );
            }
            onModalClose();
        } else {
            dispatch(
                setNotification({
                    message: t('notification.validation.required-fields'),
                    type: NotificationType.Error,
                })
            );
        }
    };

    const onModalClose = () => {
        onClose();
        setValues(originalValues);
    };

    const handleWineryChange = (event) => {
        setValues({
            ...values,
            wineryIds: event.target.value,
        });
    };

    const handleSwitchChange = (event) => {
        setValues({
            ...values,
            checked: event.target.checked,
        });
    };

    return (
        <FormModal
            onSave={onSave}
            saveDisabled={false}
            title={isEdit ? t('news.editNews') : t('news.addNews')}
            open={open}
            onClose={onModalClose}
        >
            <form noValidate>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('news.title')}
                            name="title"
                            id="title"
                            value={values?.title}
                        />
                    </Grid>
                    {isSuperAdmin && (
                        <Grid item md={12} xs={12}>
                            <MultipleSelect
                                label={t('news.wineries')}
                                name="wineryIds"
                                value={values.wineryIds}
                                items={wineryItems}
                                onChange={handleWineryChange}
                            />
                        </Grid>
                    )}
                    <Grid item md={12} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('news.summary')}
                            name="summary"
                            id="summary"
                            value={values?.summary}
                            multiline={3}
                            isRequired={false}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Label content={t('form.description')} />
                        <HtmlEditor
                            editorRef={editorRef}
                            content={values.content}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Switch
                            label={
                                values.checked
                                    ? t('news.types.published')
                                    : t('news.types.draft')
                            }
                            name="status"
                            checked={values.checked}
                            onChange={handleSwitchChange}
                            inputLabel={t('news.status')}
                        />
                    </Grid>
                </Grid>
                <Divider style={styles.divider} />
                <Grid container spacing={4}>
                    <ImageGridItem
                        isEdit={isEdit}
                        imageId={news?.coverImageId}
                        setSelectedFile={setSelectedFile}
                        title={t('upload.coverPhoto')}
                    />
                </Grid>
                <HelperText helperText={helperText} />
            </form>
        </FormModal>
    );
};

NewsModal.propTypes = {
    open: bool.isRequired,
    onClose: func,
    news: object,
    isEdit: bool,
};

export default NewsModal;
