import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { materialTheme } from './constants/theme';
import './index.css';
import { persistor, store } from './store/store';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ThemeProvider theme={materialTheme}>
                <App />
            </ThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
