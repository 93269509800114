import { Menu } from '@material-ui/core';
import { array, element, func, object, oneOfType } from 'prop-types';
import React from 'react';

const ActionMenu = ({ anchorEl, handleClose, children }) => {
    return (
        <Menu
            keepMounted
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            id="action-menu"
            onClose={handleClose}
        >
            {children}
        </Menu>
    );
};

ActionMenu.propTypes = {
    anchorEl: object,
    handleClose: func,
    children: oneOfType([element, array]),
};

export default ActionMenu;
