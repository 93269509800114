import React from 'react';
import { useSelector } from 'react-redux';
import { selectSession } from '../../constants/selectors';
import { Redirect, Route } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { element } from 'prop-types';
import { getToken } from '../../constants/token';

const LoginRoute = ({ children, ...rest }) => {
    const { isLoggedIn, session } = useSelector(selectSession);

    if (
        !isEmpty(session) &&
        getToken('token') &&
        isLoggedIn &&
        window.location.pathname.includes('login')
    ) {
        return <Redirect to={'/'} />;
    }
    return <Route {...rest} render={() => children} />;
};

LoginRoute.propTypes = {
    children: element,
};

export default LoginRoute;
