import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from '../actions/constants';
import { v4 as uuidv4 } from 'uuid';
import { INITIAL_NOTIFICATION_STATE } from '../constants/states';

export default (state = INITIAL_NOTIFICATION_STATE, action) => {
    switch (action.type) {
        case SET_NOTIFICATION: {
            const notification = {
                uuid: uuidv4(),
                type: action.payload.type,
                message: action.payload.message,
                status: action.payload.status || '',
            };
            return {
                ...state,
                notifications: [...state.notifications, notification],
            };
        }
        case REMOVE_NOTIFICATION: {
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.uuid !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};
