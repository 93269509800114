import { makeStyles } from '@material-ui/core';
import { element } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectSession } from '../../constants/selectors';
import DrawerBar from '../Menu/DrawerBar';
import MenuBar from '../Menu/MenuBar';
import { getToken } from '../../constants/token';

const DashboardLayout = ({ children }) => {
    const { isLoggedIn } = useSelector(selectSession);
    const classes = useStyles();

    return (
        <div className={classes.dashboard}>
            <MenuBar />
            <div className={classes.root}>
                {isLoggedIn && getToken('token') && <DrawerBar />}
                <div className={classes.content}>{children}</div>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        width: '100%',
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dashboard: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});

DashboardLayout.propTypes = {
    children: element,
};

export default DashboardLayout;
