import React, { useEffect } from 'react';
import './i18n';
import { Router, Switch, Route } from 'react-router-dom';
import Login from './views/Login/Login';
import Notification from './components/Toast/Notification';
import PrivateRoute from './components/Routing/PrivateRoute';
import LoginRoute from './components/Routing/LoginRoute';
import Home from './views/Home/Home';
import history from './constants/history';
import DashBoardLayout from './components/Layout/DashboardLayout';
import Profile from './views/Profile/Profile';
import NotFound from './components/Routing/NotFound';
import Users from './views/User/Users';
import { Roles } from './constants/enums';
import Wineries from './views/Winery/Wineries';
import Events from './views/Event/Events';
import Offers from './views/Offer/Offers';
import Accommodations from './views/Accomodation/Accommodations';
import News from './views/News/News';
import Travels from './views/Travel/Travels';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionRequest } from './actions/session';
import { selectIsLoggedIn } from './constants/selectors';
import MobileUsers from './views/MobileUser/MobileUsers';

function App() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(getSessionRequest());
        }
    }, []);

    return (
        <Router history={history}>
            <DashBoardLayout>
                <Switch>
                    <PrivateRoute
                        roleList={[Roles.Admin, Roles.SuperAdmin]}
                        exact
                        path="/"
                    >
                        <Home />
                    </PrivateRoute>
                    <PrivateRoute
                        roleList={[Roles.Admin, Roles.SuperAdmin]}
                        path="/profile"
                    >
                        <Profile />
                    </PrivateRoute>
                    <PrivateRoute roleList={[Roles.SuperAdmin]} path="/users">
                        <Users />
                    </PrivateRoute>
                    <PrivateRoute
                        roleList={[Roles.SuperAdmin]}
                        path="/mobile-users"
                    >
                        <MobileUsers />
                    </PrivateRoute>
                    <PrivateRoute
                        roleList={[Roles.Admin, Roles.SuperAdmin]}
                        path="/wineries"
                    >
                        <Wineries />
                    </PrivateRoute>
                    <PrivateRoute
                        roleList={[Roles.Admin, Roles.SuperAdmin]}
                        path="/events"
                    >
                        <Events />
                    </PrivateRoute>
                    <PrivateRoute
                        roleList={[Roles.Admin, Roles.SuperAdmin]}
                        path="/accommodations"
                    >
                        <Accommodations />
                    </PrivateRoute>
                    <PrivateRoute
                        roleList={[Roles.Admin, Roles.SuperAdmin]}
                        path="/offers"
                    >
                        <Offers />
                    </PrivateRoute>
                    <PrivateRoute
                        roleList={[Roles.Admin, Roles.SuperAdmin]}
                        path="/news"
                    >
                        <News />
                    </PrivateRoute>
                    <PrivateRoute
                        roleList={[Roles.Admin, Roles.SuperAdmin]}
                        path="/travels"
                    >
                        <Travels />
                    </PrivateRoute>
                    <LoginRoute path="/login">
                        <Login />
                    </LoginRoute>
                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
            </DashBoardLayout>
            <Notification />
        </Router>
    );
}

export default App;
