import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useAccommodationValidation = ({
    name,
    address,
    accommodationType,
}) => {
    const [helperText, setHelperText] = useState('');
    const [isValid, setIsValid] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (
            isEmpty(name.trim()) ||
            isEmpty(address.trim()) ||
            isEmpty(accommodationType)
        ) {
            setHelperText(`${t('notification.validation.required-fields')}`);
            setIsValid(false);
            return;
        }
        setIsValid(true);
        setHelperText('');
        return;
    }, [name, address, accommodationType]);

    return [helperText, isValid];
};
