import { Divider, Grid } from '@material-ui/core';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../actions/notification';
import { addNewWineryRequest, editWineryRequest } from '../../actions/wineries';
import { HelperText, Label } from '../../components/Form';
import { TextInput, HtmlEditor } from '../../components/Input';
import FormModal from '../../components/Modal/FormModal';
import { NotificationType } from '../../constants/enums';
import { useWineryValidation } from '../../hooks/useWineryValidation';
import { useWindowSize } from '../../hooks/useWindowSize';
import { styles } from '../../constants/theme';

const WineryModal = ({ open, onClose, winery, isEdit = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const editorRef = useRef(null);
    const isDesktop = useWindowSize();
    const originalValues = {
        name: '',
        address: '',
        email: '',
        phoneNumber: '',
        webPage: '',
        webShop: '',
        description: '',
        latitude: '',
        longitude: '',
    };
    const [values, setValues] = useState(originalValues);
    const [helperText, isValid] = useWineryValidation({
        name: values.name,
        address: values.address,
    });

    useEffect(() => {
        if (isEdit && winery) {
            setValues({
                name: winery?.name,
                address: winery?.address,
                email: winery?.email,
                phoneNumber: winery?.phoneNumber,
                webPage: winery?.webPage,
                webShop: winery?.webShop,
                description: winery?.description,
                latitude: winery?.latitude === 0 ? '' : winery.latitude,
                longitude: winery?.longitude === 0 ? '' : winery.longitude,
            });
        }
    }, [open]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const onSave = () => {
        if (isValid) {
            const wineryRequest = {
                ...values,
                longitude: Number(values.longitude),
                latitude: Number(values.latitude),
                description: editorRef?.current?.getContent(),
            };
            if (isEdit) {
                dispatch(
                    editWineryRequest({
                        id: winery?.id,
                        ...wineryRequest,
                    })
                );
            } else {
                dispatch(
                    addNewWineryRequest({
                        ...wineryRequest,
                    })
                );
            }

            onModalClose();
        } else {
            dispatch(
                setNotification({
                    message: t('notification.validation.required-fields'),
                    type: NotificationType.Error,
                })
            );
        }
    };

    const onModalClose = () => {
        onClose();
        setValues(originalValues);
    };

    return (
        <FormModal
            onSave={onSave}
            title={isEdit ? t('wineries.editWinery') : t('wineries.addWinery')}
            open={open}
            onClose={onModalClose}
            isWide={isDesktop}
        >
            <form noValidate style={isDesktop ? styles.form : null}>
                <Grid
                    container
                    spacing={2}
                    style={isDesktop ? styles.side : null}
                >
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('wineries.name')}
                            name="name"
                            id="name"
                            value={values?.name}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('wineries.address')}
                            name="address"
                            id="address"
                            value={values?.address}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('wineries.email')}
                            name="email"
                            id="email"
                            value={values?.email}
                            isRequired={false}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('wineries.phoneNumber')}
                            name="phoneNumber"
                            id="phoneNumber"
                            value={values?.phoneNumber}
                            isRequired={false}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('wineries.webPage')}
                            name="webPage"
                            id="webPage"
                            value={values?.webPage}
                            isRequired={false}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('wineries.webShop')}
                            name="webShop"
                            id="webShop"
                            value={values?.webShop}
                            isRequired={false}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('wineries.latitude')}
                            name="latitude"
                            id="latitude"
                            value={values?.latitude}
                            isRequired={false}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('wineries.longitude')}
                            name="longitude"
                            id="longitude"
                            value={values?.longitude}
                            isRequired={false}
                        />
                    </Grid>
                </Grid>
                {isDesktop && (
                    <Divider
                        orientation="vertical"
                        flexItem
                        style={styles.verticalDivider}
                    />
                )}
                <Grid container style={isDesktop ? styles.side : null}>
                    <Label content={t('form.description')} />
                    <Grid item md={12} xs={12}>
                        <HtmlEditor
                            editorRef={editorRef}
                            content={values?.description}
                            height={400}
                        />
                    </Grid>
                </Grid>
            </form>
            <HelperText helperText={helperText} />
        </FormModal>
    );
};

WineryModal.propTypes = {
    open: bool.isRequired,
    onClose: func,
    winery: object,
    isEdit: bool,
};

export default WineryModal;
