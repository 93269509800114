import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectTravels } from '../../constants/selectors';
import { isEqual } from 'lodash';
import { Grid } from '@material-ui/core';
import { TextInput } from '../../components/Input';
import { useTranslation } from 'react-i18next';
import FormCardLayout from '../../components/Layout/FormCardLayout';
import { editTravelRequest } from '../../actions/travels';

const TravelCard = () => {
    const { t } = useTranslation();
    const travels = useSelector(selectTravels);
    const originalTravel = { information: travels?.information || '' };
    const [travelData, setTravelData] = useState(originalTravel);
    const [isDisabled, setIsDisabled] = useState(true);
    const dispatch = useDispatch();

    const onCancel = () => {
        setTravelData(originalTravel);
        setIsDisabled(true);
    };

    const handleChange = (event) => {
        setTravelData({
            ...travelData,
            [event.target.name]: event.target.value,
        });
        handleButtonDisable(event);
    };

    const handleButtonDisable = (event) => {
        if (isEqual(event.target.value, originalTravel[event.target.name])) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    };

    const onSave = () => {
        dispatch(editTravelRequest(travelData));
        setIsDisabled(true);
    };

    return (
        <FormCardLayout
            onSave={onSave}
            buttonDisabled={isDisabled}
            title={t('travels.title')}
            buttonTitle={t('form.save')}
            onCancel={onCancel}
        >
            <Grid container>
                <Grid item md={12} xs={12}>
                    <TextInput
                        onChange={handleChange}
                        label={t('travels.information')}
                        name="information"
                        id="information"
                        value={travelData?.information}
                        isRequired={false}
                        multiline
                        rows={30}
                    />
                </Grid>
            </Grid>
        </FormCardLayout>
    );
};

export default TravelCard;
