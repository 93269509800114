import {
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
    makeStyles,
    Tooltip,
} from '@material-ui/core';
import { array, func, string } from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import React from 'react';
import { colors } from '../../constants/theme';
import { useTranslation } from 'react-i18next';

const ImageGridList = ({ images, alt, onDeleteClick, onStarClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const renderCoverStar = (tile) => {
        return (
            <Tooltip title={t('upload.coverPhoto')}>
                <IconButton
                    className={classes.starButton}
                    color="primary"
                    onClick={() => onStarClick(tile.id, !tile.cover)}
                >
                    {tile.cover ? <StarIcon /> : <StarBorderIcon />}
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <GridList className={classes.gridList} cols={2.5}>
            {images.map((tile) => (
                <GridListTile key={tile.id} cols={1}>
                    <img
                        src={`${process.env.REACT_APP_API_DOMAIN}/images/${tile.id}`}
                        alt={alt}
                    />
                    <GridListTileBar
                        className={classes.title}
                        actionIcon={
                            <div>
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={() => onDeleteClick(tile.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {renderCoverStar(tile)}
                            </div>
                        }
                    />
                </GridListTile>
            ))}
        </GridList>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        marginBottom: 25,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
        backgroundColor: colors.TRANSPARENT,
        height: 40,
    },
    iconButton: {
        color: colors.BLACK,
    },
    starButton: {
        padding: 5,
    },
}));

ImageGridList.propTypes = {
    images: array,
    alt: string,
    onDeleteClick: func,
    onStarClick: func,
};

export default ImageGridList;
