import { FormHelperText, makeStyles } from '@material-ui/core';
import { string } from 'prop-types';
import { colors } from '../../constants/theme';
import React from 'react';

const HelperText = ({ helperText }) => {
    const classes = useStyles();

    if (helperText) {
        return (
            <FormHelperText className={classes.helperText}>
                {helperText}
            </FormHelperText>
        );
    }
    return null;
};

const useStyles = makeStyles({
    helperText: {
        color: colors.RED,
    },
});

HelperText.propTypes = {
    helperText: string,
};

export default HelperText;
