import React from 'react';
import { Grid } from '@material-ui/core';
import WineryCard from '../WineryCard';
import { array, func, number } from 'prop-types';
import { WINERY_PAGE_SIZE } from '../../../constants/constants';
import CardContainer from '../../../components/Card/CardContainer';

const MultipleWineryList = ({
    page,
    handlePageChange,
    items,
    totalPages,
    totalItems,
}) => {
    return (
        <CardContainer
            pageSize={WINERY_PAGE_SIZE}
            handlePageChange={handlePageChange}
            page={Number(page)}
            items={items}
            totalPages={totalPages}
            totalItems={totalItems}
        >
            <React.Fragment>
                {items.map((winery) => {
                    return (
                        <Grid item key={winery.id} lg={4} md={6} xs={12}>
                            <WineryCard key={winery.id} winery={winery} />
                        </Grid>
                    );
                })}
            </React.Fragment>
        </CardContainer>
    );
};

MultipleWineryList.propTypes = {
    page: number,
    handlePageChange: func,
    items: array,
    totalPages: number,
    totalItems: number,
};

export default MultipleWineryList;
