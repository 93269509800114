import {
    GET_NEWS_REQUEST,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILED,
    GET_ALL_NEWS_FAILED,
    GET_ALL_NEWS_REQUEST,
    GET_ALL_NEWS_SUCCESS,
    EDIT_NEWS_REQUEST,
    EDIT_NEWS_SUCCESS,
    EDIT_NEWS_FAILED,
    CLEAR_SELECTED_NEWS,
    SET_SELECTED_NEWS,
    ADD_NEW_NEWS_REQUEST,
    ADD_NEW_NEWS_SUCCESS,
    ADD_NEW_NEWS_FAILED,
    DELETE_NEWS_REQUEST,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAILED,
    SET_IMAGE,
} from '../actions/constants';
import { INITIAL_NEWS_STATE } from '../constants/states';

export default (state = INITIAL_NEWS_STATE, action) => {
    switch (action.type) {
        case GET_NEWS_REQUEST:
            return { ...state, isNewsRequested: true };
        case GET_NEWS_SUCCESS:
            return {
                ...state,
                isNewsRequested: false,
                news: action.payload,
            };
        case GET_NEWS_FAILED:
            return { ...state, isNewsRequested: false };
        case GET_ALL_NEWS_REQUEST:
            return { ...state, isNewsRequested: true };
        case GET_ALL_NEWS_SUCCESS:
            return {
                ...state,
                isNewsRequested: false,
                dropdownItems: action.payload,
            };
        case GET_ALL_NEWS_FAILED:
            return { ...state, isNewsRequested: false };
        case EDIT_NEWS_REQUEST:
            return { ...state, isEditRequested: true };
        case EDIT_NEWS_SUCCESS: {
            return {
                ...state,
                isEditRequested: false,
                news: {
                    ...state.news,
                    items: state.news.items.map((news) => {
                        if (news.id === action.payload.id) {
                            return action.payload;
                        }
                        return news;
                    }),
                },
            };
        }
        case EDIT_NEWS_FAILED:
            return { ...state, isEditRequested: false };
        case CLEAR_SELECTED_NEWS:
            return { ...state, selectedNews: [] };
        case SET_SELECTED_NEWS:
            return { ...state, selectedNews: action.payload };
        case ADD_NEW_NEWS_REQUEST:
            return { ...state, isCreateRequested: true };
        case ADD_NEW_NEWS_SUCCESS:
            return {
                ...state,
                isCreateRequested: false,
                news: {
                    ...state.news,
                    totalItems: state.news.totalItems + 1,
                    items: [...state.news.items, action.payload],
                },
            };
        case ADD_NEW_NEWS_FAILED:
            return { ...state, isCreateRequested: false };
        case DELETE_NEWS_REQUEST:
            return { ...state, isDeleteRequested: true };
        case DELETE_NEWS_SUCCESS: {
            const deletedNews = [...action.payload];
            const newNews = state.news.items.filter(
                (news) =>
                    !deletedNews.some(
                        (deletedNews) => deletedNews.id === news.id
                    )
            );
            return {
                ...state,
                isDeleteRequested: false,

                news: {
                    ...state.news,
                    items: newNews,
                    totalItems: state.news.totalItems - deletedNews.length,
                },
            };
        }
        case DELETE_NEWS_FAILED:
            return { ...state, isDeleteRequested: false };
        case SET_IMAGE:
            if (action.payload.newsId) {
                return {
                    ...state,
                    news: {
                        ...state.news,
                        items: state.news.items.map((news) => {
                            if (news.id === action.payload.newsId) {
                                return {
                                    ...news,
                                    coverImageId: action.payload.photoId,
                                };
                            }
                            return news;
                        }),
                    },
                };
            }
            return state;
        default:
            return state;
    }
};
