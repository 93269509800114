import {
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,
    CLEAR_SELECTED_USERS,
    SET_SELECTED_USERS,
    DELETE_USERS_REQUEST,
    DELETE_USERS_SUCCESS,
    DELETE_USERS_FAILED,
    ADD_NEW_USER_SUCCESS,
    ADD_NEW_USER_REQUEST,
    ADD_NEW_USER_FAILED,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    GET_MOBILE_USERS_REQUEST,
    GET_MOBILE_USERS_SUCCESS,
    GET_MOBILE_USERS_FAILED,
} from '../actions/constants';
import { INITIAL_USER_STATE } from '../constants/states';

export default (state = INITIAL_USER_STATE, action) => {
    switch (action.type) {
        case GET_USERS_REQUEST:
            return { ...state, isUsersRequested: true };
        case GET_USERS_SUCCESS:
            return { ...state, isUsersRequested: false, users: action.payload };
        case GET_USERS_FAILED:
            return { ...state, isUsersRequested: false };
        case CLEAR_SELECTED_USERS:
            return { ...state, selectedUsers: [] };
        case SET_SELECTED_USERS:
            return { ...state, selectedUsers: action.payload };
        case DELETE_USERS_REQUEST:
            return { ...state, isDeleteRequested: true };
        case DELETE_USERS_SUCCESS: {
            const deletedUsers = action.payload;
            let newUsers = state.users?.items.map((user) => {
                if (
                    deletedUsers.some(
                        (deletedUser) => deletedUser.id === user.id
                    )
                ) {
                    return { ...user, deleted: true };
                }
                return user;
            });
            return {
                ...state,
                isDeleteRequested: false,
                users: { ...state.users, items: newUsers },
            };
        }
        case DELETE_USERS_FAILED:
            return { ...state, isDeleteRequested: false };
        case ADD_NEW_USER_REQUEST:
            return { ...state, isCreateRequested: true };
        case ADD_NEW_USER_SUCCESS:
            return {
                ...state,
                isCreateRequested: false,
                users: {
                    ...state.users,
                    totalItems: state.users.totalItems + 1,
                    items: [...state.users.items, action.payload],
                },
            };
        case ADD_NEW_USER_FAILED:
            return { ...state, isCreateRequested: false };
        case UPDATE_USER_REQUEST:
            return { ...state, isEditRequested: true };
        case UPDATE_USER_SUCCESS: {
            return {
                ...state,
                isEditRequested: false,
                users: {
                    ...state.users,
                    items: state.users.items.map((user) => {
                        if (user.id === action.payload.id) {
                            return action.payload;
                        }
                        return user;
                    }),
                },
            };
        }
        case UPDATE_USER_FAILED:
            return { ...state, isEditRequested: false };
        case GET_MOBILE_USERS_REQUEST:
            return { ...state, isMobileUsersRequested: true };
        case GET_MOBILE_USERS_SUCCESS:
            return {
                ...state,
                isMobileUsersRequested: false,
                mobileUsers: action.payload,
            };
        case GET_MOBILE_USERS_FAILED:
            return { ...state, isMobileUsersRequested: false };
        default:
            return state;
    }
};
