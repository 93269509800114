import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
    DELETE_ACCOMMODATION_IMAGE_REQUEST,
    DELETE_OFFER_IMAGE_REQUEST,
    DELETE_WINERY_IMAGE_REQUEST,
} from '../actions/constants';
import { setNotification } from '../actions/notification';
import {
    deleteAccommodationImageFailed,
    deleteAccommodationImageSuccess,
    deleteOfferImageFailed,
    deleteOfferImageSuccess,
    deleteWineryImageFailed,
    deleteWineryImageSuccess,
} from '../actions/images';
import API from '../constants/api';
import { NotificationType } from '../constants/enums';

function deleteImageRequest(payload) {
    return API.delete(`/images/${payload.id}`);
}

function* deleteWineryImageRequest(payload) {
    try {
        yield call(deleteImageRequest, payload.payload);
        yield put(
            deleteWineryImageSuccess({
                photoId: payload.payload.id,
                wineryId: payload.payload.wineryId,
            })
        );
        yield put(
            setNotification({
                message: 'notification.success.success-deletion',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(deleteWineryImageFailed(error));
    }
}

function* deleteAccommodationImageRequest(payload) {
    try {
        yield call(deleteImageRequest, payload.payload);
        yield put(
            deleteAccommodationImageSuccess({
                photoId: payload.payload.id,
                accommodationId: payload.payload.accommodationId,
            })
        );
        yield put(
            setNotification({
                message: 'notification.success.success-deletion',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(deleteAccommodationImageFailed(error));
    }
}

function* deleteOfferImageRequest(payload) {
    try {
        yield call(deleteImageRequest, payload.payload);
        yield put(
            deleteOfferImageSuccess({
                photoId: payload.payload.id,
                offerId: payload.payload.offerId,
            })
        );
        yield put(
            setNotification({
                message: 'notification.success.success-deletion',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(deleteOfferImageFailed(error));
    }
}

export default function* watchImages() {
    yield takeLatest(DELETE_WINERY_IMAGE_REQUEST, deleteWineryImageRequest);
    yield takeLatest(
        DELETE_ACCOMMODATION_IMAGE_REQUEST,
        deleteAccommodationImageRequest
    );
    yield takeLatest(DELETE_OFFER_IMAGE_REQUEST, deleteOfferImageRequest);
}
