import React from 'react';
import {
    NavLink as RouterLink,
    matchPath,
    useLocation,
} from 'react-router-dom';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import { string, object, bool } from 'prop-types';
import { colors } from '../../constants/theme';

const DrawerItem = ({ href, icon: Icon, title, visible, ...rest }) => {
    const location = useLocation();
    const active = matchPath(location.pathname, {
        path: href,
        exact: true,
    });
    const classes = useStyles({ active });

    if (visible) {
        return (
            <ListItem disableGutters {...rest}>
                <Button
                    component={RouterLink}
                    to={href}
                    className={classes.button}
                >
                    {Icon && <Icon className={classes.icon} />}
                    <span className={classes.text}> {title} </span>
                </Button>
            </ListItem>
        );
    }
    return null;
};

const useStyles = makeStyles({
    button: {
        color: (props) => (props.active ? colors.MENUBLACK : colors.MIDGREY),
        fontWeight: '500',
        justifyContent: 'flex-start',
        letterSpacing: 0,
        textTransform: 'none',
        width: '100%',
    },
    icon: {
        width: 30,
        height: 30,
        paddingRight: 10,
    },
    text: {
        fontSize: 18,
    },
});

DrawerItem.propTypes = {
    href: string,
    title: string,
    icon: object,
    visible: bool,
};

export default DrawerItem;
