export const INITIAL_SESSION_STATE = {
    session: {},
    isLoggedIn: false,
    loginRequested: false,
    userUpdateRequested: false,
    sessionRequested: false,
    sessionRemoved: false,
};

export const INITIAL_NOTIFICATION_STATE = {
    notifications: [],
};

export const INITIAL_USER_STATE = {
    users: [],
    mobileUsers: [],
    isUsersRequested: false,
    selectedUsers: [],
    isDeleteRequested: false,
    isCreateRequested: false,
    isEditRequested: false,
    isMobileUsersRequested: false,
};

export const INITIAL_WINERY_STATE = {
    wineries: [],
    isWineriesRequested: false,
    isLoading: true,
    dropdownItems: [],
    isEditRequested: false,
    isCoverPhotoRequested: false,
    isDeleteRequested: false,
    isUploadRequested: false,
    isCreateRequested: false,
};

export const INITIAL_EVENT_STATE = {
    events: [],
    isEventsRequested: false,
    isCreateRequested: false,
    isEditRequested: false,
    isDeleteRequested: false,
    selectedEvents: [],
    dropdownItems: [],
};

export const INITIAL_ACCOMMODATION_STATE = {
    accommodations: [],
    isAccommodationsRequested: false,
    selectedAccommodations: [],
    isEditRequested: false,
    isCreateRequested: false,
    isUploadRequested: false,
    isCoverPhotoRequested: false,
    isDeleteRequested: false,
};
export const INITIAL_STATISTICS_STATE = {
    statistics: {},
    isRequested: false,
};

export const INITIAL_OFFER_STATE = {
    offers: [],
    isOffersRequested: false,
    isCreateRequested: false,
    isEditRequested: false,
    isDeleteRequested: false,
    isCoverPhotoRequested: false,
    isUploadRequested: false,
    selectedOffers: [],
};

export const INITIAL_NEWS_STATE = {
    news: [],
    isNewsRequested: false,
    isEditRequested: false,
    isCreateRequested: false,
    isDeleteRequested: false,
    selectedNews: [],
    dropdownItems: [],
};

export const INITIAL_TRAVEL_STATE = {
    travels: [],
    isTravelsRequested: false,
    isEditRequested: false,
    selectedTravels: [],
};
