import React from 'react';
import {
    makeStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
} from '@material-ui/core';
import { bool, element, func, string } from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const FormModal = ({
    open,
    onClose,
    title,
    children,
    onSave,
    saveDisabled,
    isWide = false,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            <Dialog
                disableEnforceFocus
                onClose={onClose}
                aria-labelledby="dialog"
                open={open}
                maxWidth={isWide ? 'md' : 'xs'}
            >
                <DialogTitle disableTypography className={classes.title}>
                    <Typography className={classes.text} variant="h6">
                        {title}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content} dividers>
                    {children}
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        color="primary"
                    >
                        {t('form.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        autoFocus
                        onClick={onSave}
                        disabled={saveDisabled}
                        color="primary"
                    >
                        {t('form.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
    },
    actions: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
    text: {
        alignSelf: 'center',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

FormModal.propTypes = {
    open: bool.isRequired,
    onClose: func.isRequired,
    title: string.isRequired,
    children: element,
    onSave: func.isRequired,
    saveDisabled: bool,
    isWide: bool,
};

export default FormModal;
