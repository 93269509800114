import React from 'react';
import ActionMenu from '../../components/Menu/ActionMenu';
import { func, object } from 'prop-types';
import { ImageMenuItem, EditMenuItem } from '../../components/MenuItem';

const AccommodationActionMenu = ({
    onEditClick,
    anchorEl,
    handleClose,
    onUploadClick,
}) => {
    return (
        <ActionMenu anchorEl={anchorEl} handleClose={handleClose}>
            <EditMenuItem onEditClick={onEditClick} />
            <ImageMenuItem onUploadClick={onUploadClick} />
        </ActionMenu>
    );
};

AccommodationActionMenu.propTypes = {
    anchorEl: object,
    handleClose: func,
    onEditClick: func,
    onUploadClick: func,
};

export default AccommodationActionMenu;
