import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TextInput, Password } from '../../components/Input';
import { SubmitButton } from '../../components/Button';
import { useDispatch } from 'react-redux';
import { loginRequest } from '../../actions/session';
import { isEmpty } from 'lodash';
import { setNotification } from '../../actions/notification';
import { NotificationType } from '../../constants/enums';

const LoginForm = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();
        if (!isEmpty(email) && !isEmpty(password)) {
            dispatch(loginRequest({ email, password }));
        } else {
            dispatch(
                setNotification({
                    message: t('notification.validation.required-fields'),
                    type: NotificationType.Error,
                })
            );
        }
    };

    return (
        <form className={classes.form} onSubmit={onSubmit} noValidate>
            <TextInput
                id="email"
                label={t('login.emailAddress')}
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            <Password
                name="password"
                label={t('login.password')}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
            <SubmitButton label={t('login.signIn')} />
        </form>
    );
};

const useStyles = makeStyles((theme) => ({
    form: {
        width: '80%',
        marginTop: theme.spacing(1),
    },
}));

export default LoginForm;
