import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../actions/notification';
import { addNewUserRequest, updateUserRequest } from '../../actions/users';
import { HelperText } from '../../components/Form';
import { Select } from '../../components/Input';
import { PasswordGrid, UserDataGrid } from '../../components/Grid';
import FormModal from '../../components/Modal/FormModal';
import { NotificationType, Roles } from '../../constants/enums';
import { selectWineryDropdownItems } from '../../constants/selectors';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import { colors, styles } from '../../constants/theme';
import { useUserValidation } from '../../hooks/useUserValidation';
import WarningIcon from '@material-ui/icons/Warning';

const UserModal = ({ open, onClose, isEdit = false, user }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        wineryId: '',
        password: '',
        confirm: '',
    };
    const [values, setValues] = useState(initialValues);
    const wineryItems = useSelector(selectWineryDropdownItems);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [userHelperText, isUserValid] = useUserValidation({
        email: values.email,
        lastName: values.lastName,
        firstName: values.firstName,
        winery: values.wineryId,
        isSuperAdmin: isSuperAdmin,
    });
    const [passwordHelperText, isPasswordValid] = usePasswordValidation({
        password: values.password,
        confirm: values.confirm,
    });

    useEffect(() => {
        if (isEdit && user) {
            setValues({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                description: user.description,
                wineryId: user.winery?.id,
            });
            setIsSuperAdmin(user.roles.includes(Roles.SuperAdmin));
        }
    }, [open]);

    const handleChange = (event) => {
        setIsDisabled(false);
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const onSave = () => {
        if (!isEdit && isPasswordValid && isUserValid) {
            dispatch(addNewUserRequest(values));
            onModalClose();
        } else if (isEdit && isUserValid) {
            dispatch(
                updateUserRequest({
                    ...values,
                    id: user?.id,
                })
            );
            onModalClose();
        } else {
            dispatch(
                setNotification({
                    message: t('notification.validation.required-fields'),
                    type: NotificationType.Error,
                })
            );
        }
    };

    const onModalClose = () => {
        setValues(initialValues);
        onClose();
    };

    return (
        <FormModal
            title={isEdit ? t('users.editUser') : t('users.newUser')}
            open={open}
            onClose={onModalClose}
            onSave={onSave}
            saveDisabled={isDisabled || user?.deleted}
        >
            <form noValidate>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Typography variant="h6">
                            {t('users.userDetails')}
                        </Typography>
                        <UserDataGrid
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography className={classes.text} variant="h6">
                            {t('users.winery')}
                        </Typography>
                        <Select
                            placeholder
                            label={t('wineries.winery')}
                            name="wineryId"
                            value={values.wineryId}
                            options={wineryItems}
                            handleChange={handleChange}
                            required={!isSuperAdmin}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <HelperText helperText={userHelperText} />
                    </Grid>
                </Grid>

                {!isEdit && (
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Divider style={styles.divider} />
                            <Typography variant="h6">
                                {t('users.password')}
                            </Typography>
                        </Grid>
                        <PasswordGrid
                            values={values}
                            handleChange={handleChange}
                        />
                        <Grid item md={12} xs={12}>
                            <HelperText helperText={passwordHelperText} />
                        </Grid>
                    </Grid>
                )}
            </form>
            {user?.deleted && (
                <div className={classes.warning}>
                    <WarningIcon />
                    <div className={classes.warningText}>
                        {t('users.disabledEdit')}
                    </div>
                </div>
            )}
        </FormModal>
    );
};

const useStyles = makeStyles({
    text: {
        paddingBottom: 15,
    },
    warning: {
        color: colors.RED,
        fontStyle: 'italic',
        fontSize: 15,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        display: 'flex',
    },
    warningText: {
        paddingLeft: 5,
    },
});

UserModal.propTypes = {
    open: bool.isRequired,
    onClose: func.isRequired,
    isEdit: bool,
    user: object,
};

export default UserModal;
