import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import EventNoteIcon from '@material-ui/icons/EventNote';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import { Divider, Drawer, Box, Typography, List } from '@material-ui/core';
import DrawerItem from './DrawerItem';
import { useTranslation } from 'react-i18next';
import { Roles } from '../../constants/enums';
import { useSelector } from 'react-redux';
import { selectSession } from '../../constants/selectors';
import { roleFormatter } from '../../constants/tableHelper';

const DrawerBar = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        session: { roles = [], firstName, lastName },
    } = useSelector(selectSession);

    const items = [
        {
            href: '/profile',
            icon: PersonIcon,
            title: t('drawer.profile'),
            roles: [Roles.SuperAdmin, Roles.Admin],
        },
        {
            href: '/users',
            icon: PeopleAltIcon,
            title: t('drawer.adminUsers'),
            roles: [Roles.SuperAdmin],
        },
        {
            href: '/mobile-users',
            icon: PhoneIcon,
            title: t('drawer.mobileUsers'),
            roles: [Roles.SuperAdmin],
        },
        {
            href: '/wineries',
            icon: StorefrontIcon,
            title: t('drawer.wineries'),
            roles: [Roles.SuperAdmin, Roles.Admin],
        },
        {
            href: '/events',
            icon: EventNoteIcon,
            title: t('drawer.events'),
            roles: [Roles.SuperAdmin, Roles.Admin],
        },
        {
            href: '/accommodations',
            icon: RestaurantIcon,
            title: t('drawer.accommodations'),
            roles: [Roles.SuperAdmin, Roles.Admin],
        },
        {
            href: '/offers',
            icon: LocalOfferIcon,
            title: t('drawer.offers'),
            roles: [Roles.SuperAdmin, Roles.Admin],
        },
        {
            href: '/news',
            icon: NewReleasesIcon,
            title: t('drawer.news'),
            roles: [Roles.SuperAdmin, Roles.Admin],
        },
        {
            href: '/travels',
            icon: DriveEtaIcon,
            title: t('drawer.travels'),
            roles: [Roles.SuperAdmin, Roles.Admin],
        },
    ];

    const hasRole = (item) => {
        return roles.some((role) => item.roles.includes(role));
    };

    return (
        <Box className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <Box className={classes.userBox}>
                    <AccountCircleIcon />
                    <Typography color="textPrimary" variant="h5">
                        {firstName} {lastName}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        {roleFormatter(roles, t)}
                    </Typography>
                </Box>
                <Divider />
                <Box>
                    <List>
                        {items.map((item) => (
                            <DrawerItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                visible={hasRole(item)}
                            />
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    drawer: {
        width: 240,
        flexShrink: 0,
        height: '100%',
    },
    drawerPaper: {
        width: 240,
        top: 64,
        overflowY: 'visible',
        position: 'inherit',
        minHeight: '100%',
    },
    userBox: {
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 0px',
    },
});

export default DrawerBar;
