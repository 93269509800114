import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { func, number, object, string } from 'prop-types';

const HtmlEditor = ({ content = '', editorRef, onChange, height = 450 }) => {
    return (
        <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={content}
            onEditorChange={onChange}
            apiKey={process.env.REACT_APP_TINY_API_KEY || ''}
            init={{
                dialog_type: 'modal',
                height: height,
                resize: false,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste imagetools wordcount',
                ],
                toolbar:
                    'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
        />
    );
};

HtmlEditor.propTypes = {
    content: string,
    editorRef: object,
    onChange: func,
    height: number,
};

export default HtmlEditor;
