import { isEmpty, isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NUMBERS, UPPER_CASE_LETTERS } from '../constants/constants';

export const usePasswordValidation = ({
    password = '',
    confirm = '',
    currentPassword = null,
}) => {
    const [helperText, setHelperText] = useState('');
    const [isValid, setIsValid] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (
            !isEqual(password, confirm) &&
            !isEmpty(password) &&
            !isEmpty(confirm)
        ) {
            setHelperText(`${t('notification.error.user.password-not-match')}`);
            setIsValid(false);
            return;
        } else if (
            (!password.match(NUMBERS) ||
                !password.match(UPPER_CASE_LETTERS) ||
                password.length < 8) &&
            password.length > 1
        ) {
            setHelperText(`${t('notification.validation.invalid.password')}`);
            setIsValid(false);
            return;
        } else if (
            (currentPassword && isEmpty(currentPassword)) ||
            isEmpty(password.trim()) ||
            isEmpty(confirm.trim())
        ) {
            setHelperText(`${t('notification.validation.required-fields')}`);
            setIsValid(false);
            return;
        }
        setHelperText('');
        setIsValid(true);
        return;
    }, [password, confirm, currentPassword]);

    return [helperText, isValid, setHelperText];
};
