import React from 'react';
import { Container, Grid, Box, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import EmptyCard from '../../components/Card/EmptyCard';
import { array, element, func, number } from 'prop-types';
import Pagination from '../../components/Table/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link } from 'react-router-dom';

const CardContainer = ({
    page,
    handlePageChange,
    items,
    totalPages,
    children,
    pageSize,
    totalItems,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container>
                <Box>
                    <Grid container spacing={3}>
                        {isEmpty(items) ? <EmptyCard /> : children}
                    </Grid>
                </Box>
                {totalItems >= pageSize && (
                    <Pagination
                        totalPages={totalPages}
                        page={Number(page)}
                        handlePageChange={handlePageChange}
                        renderItem={(item) => (
                            <PaginationItem
                                component={Link}
                                to={`/wineries${
                                    item.page === 1 ? '' : `?page=${item.page}`
                                }`}
                                {...item}
                            />
                        )}
                    />
                )}
            </Container>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 25,
    },
});

CardContainer.propTypes = {
    page: number,
    handlePageChange: func,
    items: array,
    totalPages: number,
    children: element,
    pageSize: number,
    totalItems: number,
};

export default CardContainer;
