import { combineReducers } from 'redux';
import notificationReducer from './notificationReducer';
import sessionReducer from './sessionReducer';
import userReducer from './userReducer';
import wineryReducer from './wineryReducer';
import eventReducer from './eventReducer';
import accommodationReducer from './accommodationReducer';
import statisticsReducer from './statisticsReducer';
import offerReducer from './offerReducer';
import newsReducer from './newsReducer';
import travelReducer from './travelReducer';

export const rootReducer = combineReducers({
    session: sessionReducer,
    notification: notificationReducer,
    user: userReducer,
    winery: wineryReducer,
    event: eventReducer,
    accommodation: accommodationReducer,
    statistics: statisticsReducer,
    offer: offerReducer,
    news: newsReducer,
    travel: travelReducer,
});
