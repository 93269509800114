import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
    EDIT_OFFER_REQUEST,
    GET_OFFERS_REQUEST,
    ADD_NEW_OFFER_REQUEST,
    DELETE_OFFERS_REQUEST,
    SELECT_OFFER_COVER_PHOTO_REQUEST,
    UPLOAD_OFFER_IMAGE_REQUEST,
} from '../actions/constants';
import {
    selectOfferCoverPhotoFailed,
    selectOfferCoverPhotoSuccess,
    uploadOfferImageFailed,
    uploadOfferImageSuccess,
} from '../actions/images';
import { setNotification } from '../actions/notification';
import {
    editOfferFailed,
    editOfferSuccess,
    getOffersFailed,
    getOffersSuccess,
    addNewOfferFailed,
    addNewOfferSuccess,
    deleteOffersFailed,
    deleteOffersSuccess,
    clearSelectedOffers,
} from '../actions/offers';
import API from '../constants/api';
import { NotificationType } from '../constants/enums';

function offersRequest(payload) {
    return API.get('/offers', {
        params: {
            ...payload,
        },
    });
}

function editOffersRequest(payload) {
    return API.put(`/offers/${payload.id}`, payload);
}

function createRequest(payload) {
    return API.post('/offers', payload);
}

function deleteRequest(payload) {
    return API.patch('/offers', payload);
}

function uploadOfferImageRequest(payload) {
    return API.post(`/offers/${payload.id}/images`, payload.photos, {
        'Content-Type': `multipart/form-data`,
    });
}

function setOfferCoverImageRequest(payload) {
    return API.put(
        `offers/${payload.offerId}/images/${payload.imageId}/cover`,
        payload
    );
}

function* getOffersRequest(payload) {
    try {
        const { data } = yield call(offersRequest, payload.payload);
        yield put(getOffersSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getOffersFailed(error));
    }
}

function* putOfferEditRequest(payload) {
    try {
        const { data } = yield call(editOffersRequest, payload.payload);
        yield put(editOfferSuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(editOfferFailed(error));
    }
}

function* addNewOfferRequest(payload) {
    try {
        const { data } = yield call(createRequest, payload.payload);
        yield put(addNewOfferSuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(addNewOfferFailed(error));
    }
}

function* deleteOffersRequest(payload) {
    try {
        const { data } = yield call(deleteRequest, payload.payload);
        yield put(deleteOffersSuccess(data));
        yield put(clearSelectedOffers());
        yield put(
            setNotification({
                message: 'notification.success.success-deletion',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(deleteOffersFailed(error));
    }
}

function* postOfferImageRequest(payload) {
    try {
        let formData = new FormData();
        payload.payload.photos.map((file) => formData.append('file', file));
        const { data } = yield call(uploadOfferImageRequest, {
            id: payload.payload.id,
            photos: formData,
        });
        yield put(
            uploadOfferImageSuccess({
                urls: data,
                offerId: payload.payload.id,
            })
        );
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(uploadOfferImageFailed(error));
    }
}

function* putOfferCoverImageRequest(payload) {
    try {
        const { data } = yield call(setOfferCoverImageRequest, payload.payload);
        yield put(
            selectOfferCoverPhotoSuccess({
                ...data,
                offerId: payload.payload.offerId,
            })
        );
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(selectOfferCoverPhotoFailed(error));
    }
}

export default function* watchOffers() {
    yield takeLatest(GET_OFFERS_REQUEST, getOffersRequest);
    yield takeLatest(EDIT_OFFER_REQUEST, putOfferEditRequest);
    yield takeLatest(ADD_NEW_OFFER_REQUEST, addNewOfferRequest);
    yield takeLatest(DELETE_OFFERS_REQUEST, deleteOffersRequest);
    yield takeLatest(
        SELECT_OFFER_COVER_PHOTO_REQUEST,
        putOfferCoverImageRequest
    );
    yield takeLatest(UPLOAD_OFFER_IMAGE_REQUEST, postOfferImageRequest);
}
