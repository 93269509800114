import React, { useState } from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';
import {
    makeStyles,
    InputAdornment,
    IconButton,
    TextField,
} from '@material-ui/core';
import { colors } from '../../constants/theme';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Password = ({
    label,
    id,
    name,
    onChange,
    value,
    isRequired = true,
    autoComplete = 'off',
}) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            variant="outlined"
            margin="normal"
            required={isRequired}
            fullWidth
            name={name}
            label={label}
            type={showPassword ? 'text' : 'password'}
            id={id}
            className={classes.textfield}
            onChange={onChange}
            autoComplete={autoComplete}
            value={value}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

const useStyles = makeStyles({
    textfield: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: colors.BLACK,
            },
        },
        '& label.Mui-focused': {
            color: colors.BLACK,
        },
    },
});

Password.propTypes = {
    label: string.isRequired,
    id: string,
    name: string.isRequired,
    onChange: func,
    value: oneOfType([number, string]),
    isRequired: bool,
    autoComplete: bool,
};

export default Password;
