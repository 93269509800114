import {
    GET_STATISTICS_DATA_FAILED,
    GET_STATISTICS_DATA_REQUEST,
    GET_STATISTICS_DATA_SUCCESS,
} from '../actions/constants';
import { INITIAL_STATISTICS_STATE } from '../constants/states';

export default (state = INITIAL_STATISTICS_STATE, action) => {
    switch (action.type) {
        case GET_STATISTICS_DATA_REQUEST:
            return { ...state, isRequested: true };
        case GET_STATISTICS_DATA_SUCCESS:
            return {
                ...state,
                isRequested: false,
                statistics: { ...action.payload },
            };
        case GET_STATISTICS_DATA_FAILED:
            return { ...state, isRequested: false };
        default:
            return state;
    }
};
