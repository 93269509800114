import { call, put, takeLatest } from '@redux-saga/core/effects';
import API from '../constants/api';
import { setNotification } from '../actions/notification';
import { NotificationType } from '../constants/enums';
import {
    GET_ACCOMMODATIONS_REQUEST,
    EDIT_ACCOMMODATION_REQUEST,
    UPLOAD_ACCOMMODATION_IMAGE_REQUEST,
    SELECT_ACCOMMODATION_COVER_PHOTO_REQUEST,
    ADD_NEW_ACCOMMODATION_REQUEST,
    DELETE_ACCOMMODATIONS_REQUEST,
} from '../actions/constants';

import {
    getAccommodationsFailed,
    getAccommodationsSuccess,
    editAccommodationFailed,
    editAccommodationSuccess,
    addNewAccommodationSuccess,
    addNewAccommodationFailed,
    deleteAccommodationsFailed,
    deleteAccommodationsSuccess,
    clearSelectedAccommodations,
} from '../actions/accommodations';
import {
    selectAccommodationCoverPhotoFailed,
    selectAccommodationCoverPhotoSuccess,
    uploadAccommodationImageFailed,
    uploadAccommodationImageSuccess,
} from '../actions/images';

function accommodationsRequest(payload) {
    return API.get('/accommodations', {
        params: {
            ...payload,
        },
    });
}

function editAccommodationRequest(payload) {
    return API.put(`/accommodations/${payload.id}`, payload);
}

function uploadAccommodationImageRequest(payload) {
    return API.post(`/accommodations/${payload.id}/images`, payload.photos, {
        'Content-Type': `multipart/form-data`,
    });
}

function setAccommodationCoverImageRequest(payload) {
    return API.put(
        `accommodations/${payload.accommodationId}/images/${payload.imageId}/cover`,
        payload
    );
}

function createRequest(payload) {
    return API.post('/accommodations', payload);
}

function deleteRequest(payload) {
    return API.patch('/accommodations/delete', payload);
}

function* getAccommodationsRequest(payload) {
    try {
        const { data } = yield call(accommodationsRequest, payload.payload);
        yield put(getAccommodationsSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getAccommodationsFailed(error));
    }
}

function* putAccommodationEditRequest(payload) {
    try {
        const { data } = yield call(editAccommodationRequest, payload.payload);
        yield put(editAccommodationSuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(editAccommodationFailed(error));
    }
}

function* addNewAccommodationRequest(payload) {
    try {
        const { data } = yield call(createRequest, payload.payload);
        yield put(addNewAccommodationSuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(addNewAccommodationFailed(error));
    }
}

function* deleteAccommodationsRequest(payload) {
    try {
        const { data } = yield call(deleteRequest, payload.payload);
        yield put(deleteAccommodationsSuccess(data));
        yield put(clearSelectedAccommodations());
        yield put(
            setNotification({
                message: 'notification.success.success-deletion',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(deleteAccommodationsFailed(error));
    }
}

function* postAccommodationImageRequest(payload) {
    try {
        let formData = new FormData();
        payload.payload.photos.map((file) => formData.append('file', file));
        const { data } = yield call(uploadAccommodationImageRequest, {
            id: payload.payload.id,
            photos: formData,
        });
        yield put(
            uploadAccommodationImageSuccess({
                urls: data,
                accommodationId: payload.payload.id,
            })
        );
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(uploadAccommodationImageFailed(error));
    }
}

function* putAccommodationCoverImageRequest(payload) {
    try {
        const { data } = yield call(
            setAccommodationCoverImageRequest,
            payload.payload
        );
        yield put(
            selectAccommodationCoverPhotoSuccess({
                ...data,
                accommodationId: payload.payload.accommodationId,
            })
        );
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(selectAccommodationCoverPhotoFailed(error));
    }
}

export default function* watchAccommodations() {
    yield takeLatest(GET_ACCOMMODATIONS_REQUEST, getAccommodationsRequest);
    yield takeLatest(EDIT_ACCOMMODATION_REQUEST, putAccommodationEditRequest);
    yield takeLatest(ADD_NEW_ACCOMMODATION_REQUEST, addNewAccommodationRequest);
    yield takeLatest(
        DELETE_ACCOMMODATIONS_REQUEST,
        deleteAccommodationsRequest
    );
    yield takeLatest(
        UPLOAD_ACCOMMODATION_IMAGE_REQUEST,
        postAccommodationImageRequest
    );
    yield takeLatest(
        SELECT_ACCOMMODATION_COVER_PHOTO_REQUEST,
        putAccommodationCoverImageRequest
    );
}
