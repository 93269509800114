import React from 'react';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { element, number, string } from 'prop-types';

const DashboardCard = ({ title, data, icon }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            {title}
                        </Typography>
                        <Typography color="textPrimary" variant="h3">
                            {data}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatar}>{icon}</Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles({
    grid: {
        justifyContent: 'space-between',
    },
    avatar: {
        backgroundColor: grey[400],
        height: 56,
        width: 56,
    },
    card: {
        width: 370,
    },
});

DashboardCard.propTypes = {
    title: string.isRequired,
    data: number,
    icon: element,
};

export default DashboardCard;
