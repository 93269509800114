import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccommodationsRequest } from '../../actions/accommodations';
import {
    selectAccommodations,
    selectSelectedAccommodations,
    selectAccommodationLoading,
} from '../../constants/selectors';
import history from '../../constants/history';
import AccommodationTable from './AccommodationTable';
import AccommodationModal from './AccommodationModal';
import { ACCOMMODATION_PAGE_SIZE } from '../../constants/constants';
import useUrlParams from '../../hooks/useUrlParams';
import ListLayout from '../../components/Layout/ListLayout';
import { useTranslation } from 'react-i18next';

const AccommodationList = () => {
    const isRequested = useSelector(selectAccommodationLoading);
    const {
        items = [],
        totalItems = 0,
        size = ACCOMMODATION_PAGE_SIZE,
    } = useSelector(selectAccommodations);
    const params = useUrlParams();
    const [page, setPage] = useState(params.page || 0);
    const dispatch = useDispatch();
    const selectedAccommodationIds = useSelector(selectSelectedAccommodations);
    const [selectedAccommodation, setSelectedAccommodation] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(
            getAccommodationsRequest({
                page,
                size: ACCOMMODATION_PAGE_SIZE,
                sort: '',
            })
        );
    }, [page]);

    const handlePageChange = (_, newPage) => {
        setPage(newPage);
        history.push(`/accommodations?page=${newPage}`);
    };

    const onEditMenuClick = () => {
        setEditOpen(true);
        setAnchorEl(null);
    };

    return (
        <>
            <ListLayout
                title={t('drawer.accommodations')}
                isRequested={isRequested}
                size={size}
                page={page}
                handlePageChange={handlePageChange}
                totalItems={totalItems}
            >
                <AccommodationTable
                    items={items}
                    selectedAccommodation={selectedAccommodation}
                    setSelectedAccommodation={setSelectedAccommodation}
                    selectedAccommodationIds={selectedAccommodationIds}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    onEditMenuClick={onEditMenuClick}
                />
            </ListLayout>
            <AccommodationModal
                isEdit={true}
                accommodation={selectedAccommodation}
                open={editOpen}
                onClose={() => setEditOpen(false)}
            />
        </>
    );
};

export default AccommodationList;
