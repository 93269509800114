import { IconButton } from '@material-ui/core';
import { func, object, oneOfType, string } from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';

const DotButton = ({ onMenuClick, style }) => {
    return (
        <IconButton onClick={onMenuClick} className={style}>
            <MoreVertIcon />
        </IconButton>
    );
};

DotButton.propTypes = {
    onMenuClick: func.isRequired,
    style: oneOfType([object, string]),
};

export default DotButton;
