import { Card, CardContent, makeStyles } from '@material-ui/core';
import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import { useTranslation } from 'react-i18next';

const EmptyCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Card>
                <CardContent className={classes.content}>
                    <DescriptionIcon className={classes.icon} />
                    {t('notfound.result')}
                </CardContent>
            </Card>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        width: '50%',
        margin: '0 auto',
    },
    content: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        paddingBottom: 10,
    },
});

export default EmptyCard;
