import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeNotification } from '../../actions/notification';
import { selectNotification } from '../../constants/selectors';
import { AuthenticationResponse } from '../../constants/enums';
import { useSession } from '../../hooks/useSession';

const Notification = () => {
    const dispatch = useDispatch();
    const { notifications } = useSelector(selectNotification);
    const { t } = useTranslation();
    useSession();

    const openNotification = ({ uuid, message, type, status }) => {
        if (status !== AuthenticationResponse.TokenExpired) {
            toast(
                message
                    ? t(message)
                    : t('notification.error.basic.error-process'),
                {
                    position: toast.POSITION.BOTTOM_LEFT,
                    toastId: uuid,
                    type,
                }
            );
        }

        dispatch(removeNotification(uuid));
    };

    useEffect(() => {
        notifications.map((notification) => {
            openNotification(notification);
        });
    }, [notifications]);

    return (
        <ToastContainer
            autoClose={5000}
            pauseOnFocusLoss={false}
            limit={2}
            hideProgressBar
        />
    );
};

export default Notification;
