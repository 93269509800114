import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import FormModal from '../../components/Modal/FormModal';
import { HelperText } from '../../components/Form';
import { Select } from '../../components/Input';
import { Label } from '../../components/Form';
import { TextInput, HtmlEditor } from '../../components/Input';
import { bool, func, object } from 'prop-types';
import { AccommodationTypes } from '../../constants/enums';
import {
    selectIsSuperAdmin,
    selectUserWinery,
    selectWineryDropdownItems,
} from '../../constants/selectors';
import { useAccommodationValidation } from '../../hooks/useAccommodationValidation';
import {
    addNewAccommodationRequest,
    editAccommodationRequest,
} from '../../actions/accommodations';
import { setNotification } from '../../actions/notification';
import { NotificationType } from '../../constants/enums';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks/useWindowSize';
import { styles } from '../../constants/theme';

const AccommodationModal = ({
    open,
    onClose,
    isEdit = false,
    accommodation,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const wineryItems = useSelector(selectWineryDropdownItems);
    const isSuperAdmin = useSelector(selectIsSuperAdmin);
    const adminWinery = useSelector(selectUserWinery);
    const editorRef = useRef(null);
    const isDesktop = useWindowSize();
    const originalValues = {
        name: '',
        address: '',
        description: '',
        openingHours: '',
        wineryId: isSuperAdmin ? '' : adminWinery?.id,
        accommodationType: null,
    };
    const [values, setValues] = useState(originalValues);
    const [helperText, isValid] = useAccommodationValidation({
        name: values.name,
        address: values.address,
        accommodationType: values.accommodationType,
    });

    useEffect(() => {
        if (isEdit && accommodation) {
            setValues({
                name: accommodation.name,
                address: accommodation.address,
                openingHours: accommodation.openingHours,
                description: accommodation.description,
                wineryId: accommodation.winery?.id,
                accommodationType: accommodation.accommodationType,
            });
        }
    }, [open]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleWineryChange = (event) => {
        setValues({
            ...values,
            wineryId: event.target.value,
        });
    };

    const onModalClose = () => {
        setValues(originalValues);
        onClose();
    };

    const getTypeValues = (types) => {
        return types.map((type) => {
            return { ...type, name: t(type.name) };
        });
    };

    const onSave = () => {
        if (isValid) {
            if (isEdit) {
                dispatch(
                    editAccommodationRequest({
                        ...values,
                        id: accommodation.id,
                        description: editorRef?.current?.getContent(),
                    })
                );
            } else {
                dispatch(
                    addNewAccommodationRequest({
                        ...values,
                        description: editorRef?.current?.getContent(),
                    })
                );
            }
            onModalClose();
        } else {
            dispatch(
                setNotification({
                    message: t('notification.validation.required-fields'),
                    type: NotificationType.Error,
                })
            );
        }
    };

    return (
        <FormModal
            onSave={onSave}
            title={
                isEdit ? t('accommodations.edit') : t('accommodations.create')
            }
            open={open}
            onClose={onModalClose}
            isWide={isDesktop}
        >
            <form noValidate style={isDesktop ? styles.form : null}>
                <Grid
                    container
                    spacing={2}
                    style={isDesktop ? styles.side : null}
                >
                    <Grid item md={12} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('accommodations.name')}
                            name="name"
                            id="name"
                            value={values?.name}
                            isRequired={true}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('accommodations.address')}
                            name="address"
                            id="address"
                            value={values?.address}
                            isRequired={true}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Select
                            label={t('accommodations.type')}
                            name="accommodationType"
                            id="accommodationType"
                            value={values.accommodationType}
                            options={getTypeValues(AccommodationTypes)}
                            handleChange={handleChange}
                            required
                            placeholder
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Select
                            label={t('accommodations.winery')}
                            name="winery"
                            id="winery"
                            value={values.wineryId || null}
                            options={wineryItems}
                            handleChange={handleWineryChange}
                            placeholder
                            disabled={!isSuperAdmin}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('accommodations.openingHours')}
                            name="openingHours"
                            id="openingHours"
                            value={values?.openingHours}
                            isRequired={false}
                            multiline
                            rows={2}
                        />
                    </Grid>
                </Grid>
                {isDesktop && (
                    <Divider
                        orientation="vertical"
                        flexItem
                        style={styles.verticalDivider}
                    />
                )}
                <Grid container style={isDesktop ? styles.side : null}>
                    <Label content={t('form.description')} />
                    <Grid item md={12} xs={12}>
                        <HtmlEditor
                            editorRef={editorRef}
                            content={values?.description}
                            height={380}
                        />
                    </Grid>
                </Grid>
            </form>
            <div className={classes.helperText}>
                <HelperText helperText={helperText} />
            </div>
        </FormModal>
    );
};

const useStyles = makeStyles({
    helperText: {
        marginTop: 15,
    },
});

AccommodationModal.propTypes = {
    open: bool.isRequired,
    onClose: func,
    isEdit: bool,
    accommodation: object,
};

export default AccommodationModal;
