import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectNews,
    selectNewsLoading,
    selectSelectedNews,
} from '../../constants/selectors';
import history from '../../constants/history';
import { NEWS_PAGE_SIZE } from '../../constants/constants';
import ListLayout from '../../components/Layout/ListLayout';
import useUrlParams from '../../hooks/useUrlParams';
import { useTranslation } from 'react-i18next';
import { getNewsRequest } from '../../actions/news';
import NewsModal from './NewsModal';
import NewsTable from './NewsTable';

const NewsList = () => {
    const selectedNewsIds = useSelector(selectSelectedNews);
    const isRequested = useSelector(selectNewsLoading);
    const {
        items = [],
        totalItems = 0,
        size = NEWS_PAGE_SIZE,
    } = useSelector(selectNews);
    const params = useUrlParams();
    const [page, setPage] = useState(params.page || 0);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedNews, setSelectedNews] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(
            getNewsRequest({
                page,
                size: NEWS_PAGE_SIZE,
                sort: '',
            })
        );
    }, [page]);

    const handlePageChange = (_, newPage) => {
        setPage(newPage);
        history.push(`/news?page=${newPage}`);
    };

    const onEditMenuClick = () => {
        setEditOpen(true);
        setAnchorEl(null);
    };

    return (
        <>
            <ListLayout
                title={t('drawer.news')}
                isRequested={isRequested}
                totalItems={totalItems}
                size={size}
                page={page}
                handlePageChange={handlePageChange}
            >
                <NewsTable
                    selectedNews={selectedNews}
                    setSelectedNews={setSelectedNews}
                    selectedNewsIds={selectedNewsIds}
                    items={items}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    onEditMenuClick={onEditMenuClick}
                />
            </ListLayout>
            <NewsModal
                isEdit={true}
                news={selectedNews}
                open={editOpen}
                onClose={() => setEditOpen(false)}
            />
        </>
    );
};

export default NewsList;
