import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { generatePasswordRequest, getUsersRequest } from '../../actions/users';
import {
    selectSelectedUsers,
    selectUserLoading,
    selectUsers,
} from '../../constants/selectors';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import UserTable from './UserTable';
import InformationModal from '../../components/Modal/InformationModal';
import { NotificationType } from '../../constants/enums';
import { setNotification } from '../../actions/notification';
import history from '../../constants/history';
import { USER_PAGE_SIZE } from '../../constants/constants';
import useUrlParams from '../../hooks/useUrlParams';
import ListLayout from '../../components/Layout/ListLayout';
import UserModal from './UserModal';

const UserList = () => {
    const selectedUserIds = useSelector(selectSelectedUsers);
    const isRequested = useSelector(selectUserLoading);
    const {
        items = [],
        totalItems = 0,
        size = USER_PAGE_SIZE,
    } = useSelector(selectUsers);
    const params = useUrlParams();
    const [page, setPage] = useState(params.page || 0);
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [infoOpen, setInfoOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const currentUser = useSelector(selectUsers)?.items?.find(
        (user) => user?.id === selectedUser?.id
    );

    useEffect(() => {
        dispatch(
            getUsersRequest({
                page,
                size: USER_PAGE_SIZE,
                sort: '',
            })
        );
    }, [page]);

    const handlePageChange = (_, newPage) => {
        setPage(newPage);
        history.push(`/users?page=${newPage}`);
    };

    const renderPasswordRequestContent = () => {
        return (
            <div className={classes.container}>
                <Typography variant="textPrimary">
                    {t('users.generateForUser')}:{' '}
                    <b>{`${selectedUser?.name}`}</b>
                </Typography>
                <Typography color="textSecondary">
                    {t('users.inEmail')}
                </Typography>
            </div>
        );
    };

    const onGenerateMenuClick = () => {
        setInfoOpen(true);
        setAnchorEl(null);
    };

    const onGeneratePassword = () => {
        setInfoOpen(false);
        dispatch(generatePasswordRequest(selectedUser.id));
        dispatch(
            setNotification({
                message: 'notification.info.generate-in-progress',
                type: NotificationType.Info,
            })
        );
    };

    const onEditMenuClick = () => {
        setEditOpen(true);
        setAnchorEl(null);
    };

    return (
        <>
            <ListLayout
                title={t('drawer.adminUsers')}
                isRequested={isRequested}
                size={size}
                page={page}
                handlePageChange={handlePageChange}
                totalItems={totalItems}
            >
                <UserTable
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    selectedUserIds={selectedUserIds}
                    items={items}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    onGeneratePasswordClick={onGenerateMenuClick}
                    onEditMenuClick={onEditMenuClick}
                />
            </ListLayout>
            <InformationModal
                open={infoOpen}
                handleClose={() => setInfoOpen(false)}
                title={t('users.passwordRequest')}
                onOkClick={onGeneratePassword}
            >
                {renderPasswordRequestContent()}
            </InformationModal>
            <UserModal
                open={editOpen}
                isEdit
                user={currentUser}
                onClose={() => setEditOpen(false)}
            />
        </>
    );
};

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 25,
    },
    card: {
        width: '90%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: 40,
        justifyContent: 'space-between',
    },
});

export default UserList;
