import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
    ADD_NEW_USER_REQUEST,
    DELETE_USERS_REQUEST,
    GENERATE_PASSWORD_REQUEST,
    GET_MOBILE_USERS_REQUEST,
    GET_USERS_REQUEST,
    UPDATE_USER_REQUEST,
} from '../actions/constants';
import { setNotification } from '../actions/notification';
import {
    addNewUserFailed,
    addNewUserSuccess,
    clearSelectedUsers,
    deleteUsersFailed,
    deleteUsersSuccess,
    generatePasswordFailed,
    generatePasswordSuccess,
    getMobileUsersFailed,
    getMobileUsersSuccess,
    getUsersFailed,
    getUsersSuccess,
    updateUserFailed,
    updateUserSuccess,
} from '../actions/users';
import API from '../constants/api';
import { NotificationType } from '../constants/enums';

function usersRequest(payload) {
    return API.get('/users', {
        params: {
            ...payload,
        },
    });
}

function mobileUsersRequest(payload) {
    return API.get('/users/mobile-users', {
        params: {
            ...payload,
        },
    });
}

function deleteRequest(payload) {
    return API.patch('/users/delete', payload);
}

function createRequest(payload) {
    return API.post('/users', payload);
}

function generatePassword(payload) {
    return API.put(`users/${payload}/reset-password`);
}

function updateUserRequest(payload) {
    return API.put(`/users/${payload.id}`, payload);
}

function* getUsersRequest(payload) {
    try {
        const { data } = yield call(usersRequest, payload.payload);
        yield put(getUsersSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getUsersFailed(error));
    }
}

function* deleteUsersRequest(payload) {
    try {
        const { data } = yield call(deleteRequest, payload.payload);
        yield put(deleteUsersSuccess(data));
        yield put(clearSelectedUsers());
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(deleteUsersFailed(error));
    }
}

function* addNewUserRequest(payload) {
    try {
        const { data } = yield call(createRequest, payload.payload);
        yield put(addNewUserSuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(addNewUserFailed(error));
    }
}

function* generatePasswordRequest(payload) {
    try {
        yield call(generatePassword, payload.payload);
        yield put(generatePasswordSuccess());
        yield put(
            setNotification({
                message: 'notification.success.success-generation',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(generatePasswordFailed(error));
    }
}

function* putUserRequest(payload) {
    try {
        const { data } = yield call(updateUserRequest, payload.payload);
        yield put(updateUserSuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(updateUserFailed(error));
    }
}

function* getMobileUsersRequest(payload) {
    try {
        const { data } = yield call(mobileUsersRequest, payload.payload);
        yield put(getMobileUsersSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getMobileUsersFailed(error));
    }
}

export default function* watchUsers() {
    yield takeLatest(GET_USERS_REQUEST, getUsersRequest);
    yield takeLatest(DELETE_USERS_REQUEST, deleteUsersRequest);
    yield takeLatest(ADD_NEW_USER_REQUEST, addNewUserRequest);
    yield takeLatest(GENERATE_PASSWORD_REQUEST, generatePasswordRequest);
    yield takeLatest(UPDATE_USER_REQUEST, putUserRequest);
    yield takeLatest(GET_MOBILE_USERS_REQUEST, getMobileUsersRequest);
}
