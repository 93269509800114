import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useNewsValidation = ({ title }) => {
    const [helperText, setHelperText] = useState('');
    const [isValid, setIsValid] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (isEmpty(title.trim())) {
            setHelperText(`${t('notification.validation.required-fields')}`);
            setIsValid(false);
            return;
        }
        setIsValid(true);
        setHelperText('');
        return;
    }, [title]);

    return [helperText, isValid];
};
