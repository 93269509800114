import {
    ADD_NEW_WINERY_FAILED,
    ADD_NEW_WINERY_REQUEST,
    ADD_NEW_WINERY_SUCCESS,
    DELETE_WINERY_FAILED,
    DELETE_WINERY_REQUEST,
    DELETE_WINERY_SUCCESS,
    EDIT_WINERY_FAILED,
    EDIT_WINERY_REQUEST,
    EDIT_WINERY_SUCCESS,
    GET_ALL_WINERIES_FAILED,
    GET_ALL_WINERIES_REQUEST,
    GET_ALL_WINERIES_SUCCESS,
    GET_WINERIES_FAILED,
    GET_WINERIES_REQUEST,
    GET_WINERIES_SUCCESS,
} from './constants';

export const getWineriesRequest = (payload) => {
    return {
        type: GET_WINERIES_REQUEST,
        payload,
    };
};

export const getWineriesSuccess = (payload) => {
    return {
        type: GET_WINERIES_SUCCESS,
        payload,
    };
};

export const getWineriesFailed = (payload) => {
    return {
        type: GET_WINERIES_FAILED,
        payload,
    };
};

export const getAllWineriesRequest = () => {
    return {
        type: GET_ALL_WINERIES_REQUEST,
    };
};

export const getAllWineriesSuccess = (payload) => {
    return {
        type: GET_ALL_WINERIES_SUCCESS,
        payload,
    };
};

export const getAllWineriesFailed = (payload) => {
    return {
        type: GET_ALL_WINERIES_FAILED,
        payload,
    };
};

export const editWineryRequest = (payload) => {
    return {
        type: EDIT_WINERY_REQUEST,
        payload,
    };
};

export const editWinerySuccess = (payload) => {
    return {
        type: EDIT_WINERY_SUCCESS,
        payload,
    };
};

export const editWineryFailed = (payload) => {
    return {
        type: EDIT_WINERY_FAILED,
        payload,
    };
};

export const deleteWineryRequest = (payload) => {
    return {
        type: DELETE_WINERY_REQUEST,
        payload,
    };
};

export const deleteWinerySuccess = (payload) => {
    return {
        type: DELETE_WINERY_SUCCESS,
        payload,
    };
};

export const deleteWineryFailed = (payload) => {
    return {
        type: DELETE_WINERY_FAILED,
        payload,
    };
};

export const addNewWineryRequest = (payload) => {
    return {
        type: ADD_NEW_WINERY_REQUEST,
        payload,
    };
};

export const addNewWinerySuccess = (payload) => {
    return {
        type: ADD_NEW_WINERY_SUCCESS,
        payload,
    };
};

export const addNewWineryFailed = (payload) => {
    return {
        type: ADD_NEW_WINERY_FAILED,
        payload,
    };
};
