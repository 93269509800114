import {
    GET_SESSION_FAILED,
    GET_SESSION_REQUEST,
    GET_SESSION_SUCCESS,
    LOGIN_FAILED,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOG_OUT,
    UPDATE_PASSWORD_FAILED,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_ME_FAILED,
    UPDATE_ME_REQUEST,
    UPDATE_ME_SUCCESS,
    REMOVE_SESSION,
} from './constants';

export const loginRequest = (payload) => {
    return {
        type: LOGIN_REQUEST,
        payload,
    };
};

export const loginSuccess = (payload) => {
    return {
        type: LOGIN_SUCCESS,
        payload,
    };
};

export const loginFailed = (payload) => {
    return {
        type: LOGIN_FAILED,
        payload,
    };
};

export const logout = () => {
    return {
        type: LOG_OUT,
    };
};

export const removeSession = () => {
    return {
        type: REMOVE_SESSION,
    };
};

export const updateMeRequest = (payload) => {
    return {
        type: UPDATE_ME_REQUEST,
        payload,
    };
};

export const updateMeSuccess = (payload) => {
    return {
        type: UPDATE_ME_SUCCESS,
        payload,
    };
};

export const updateMeFailed = (payload) => {
    return {
        type: UPDATE_ME_FAILED,
        payload,
    };
};

export const updatePasswordRequest = (payload) => {
    return {
        type: UPDATE_PASSWORD_REQUEST,
        payload,
    };
};

export const updatePasswordSuccess = () => {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
    };
};

export const updatePasswordFailed = (payload) => {
    return {
        type: UPDATE_PASSWORD_FAILED,
        payload,
    };
};

export const getSessionRequest = () => {
    return {
        type: GET_SESSION_REQUEST,
    };
};

export const getSessionSuccess = (payload) => {
    return {
        type: GET_SESSION_SUCCESS,
        payload,
    };
};

export const getSessionFailed = (payload) => {
    return {
        type: GET_SESSION_FAILED,
        payload,
    };
};
