import { Grid, makeStyles } from '@material-ui/core';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../actions/notification';
import { DatePicker, HelperText, Label } from '../../components/Form';
import {
    MultipleSelect,
    HtmlEditor,
    TextInput,
    Select,
} from '../../components/Input';
import FormModal from '../../components/Modal/FormModal';
import { NotificationType } from '../../constants/enums';
import {
    selectEventDropdownItems,
    selectIsSuperAdmin,
    selectUserWinery,
    selectWineryDropdownItems,
} from '../../constants/selectors';
import { useOfferValidation } from '../../hooks/useOfferValidation';
import { editOfferRequest, addNewOfferRequest } from '../../actions/offers';

const OfferModal = ({ open, onClose, isEdit = false, offer }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const wineryItems = useSelector(selectWineryDropdownItems);
    const eventItems = useSelector(selectEventDropdownItems);
    const isSuperAdmin = useSelector(selectIsSuperAdmin);
    const adminWinery = useSelector(selectUserWinery);
    const classes = useStyles();
    const editorRef = useRef(null);
    const originalValues = {
        title: '',
        eventId: '',
        webShopLink: '',
        validFrom: new Date(),
        validTo: new Date(),
        description: '',
        wineryIds: isSuperAdmin ? [] : [adminWinery?.id],
    };
    const [values, setValues] = useState(originalValues);
    const [helperText, isValid] = useOfferValidation({
        title: values.title,
        validFrom: values.validFrom,
        validTo: values.validTo,
        wineryIds: values.wineryIds,
    });

    useEffect(() => {
        if (isEdit && offer) {
            setValues({
                title: offer.title,
                webShopLink: offer.webShopLink,
                validFrom: offer.validFrom,
                validTo: offer.validTo,
                description: offer.description,
                wineryIds: offer.wineries?.map((winery) => winery.id),
                eventId: offer.event?.id,
            });
        }
    }, [open]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleValidFromChange = (date) => {
        setValues({
            ...values,
            validFrom: date,
        });
    };

    const handleValidToChange = (date) => {
        setValues({
            ...values,
            validTo: date,
        });
    };

    const handleMultipleSelectChange = (event) => {
        setValues({
            ...values,
            wineryIds: event.target.value,
        });
    };

    const onSave = () => {
        if (isValid) {
            if (isEdit) {
                dispatch(
                    editOfferRequest({
                        ...values,
                        id: offer.id,
                        description: editorRef?.current?.getContent(),
                    })
                );
            } else {
                dispatch(
                    addNewOfferRequest({
                        ...values,
                        description: editorRef?.current?.getContent(),
                    })
                );
            }
            onModalClose();
        } else {
            dispatch(
                setNotification({
                    message: t('notification.validation.required-fields'),
                    type: NotificationType.Error,
                })
            );
        }
    };

    const onModalClose = () => {
        setValues(originalValues);
        onClose();
    };

    return (
        <FormModal
            onSave={onSave}
            title={isEdit ? t('offers.editOffer') : t('offers.newOffer')}
            open={open}
            onClose={onModalClose}
        >
            <form noValidate>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('offers.title')}
                            name="title"
                            id="title"
                            value={values?.title}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('offers.webShopLink')}
                            name="webShopLink"
                            id="webShopLink"
                            value={values?.webShopLink}
                            isRequired={false}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DatePicker
                            value={values.validFrom}
                            label={t('offers.validFrom')}
                            id="validFrom"
                            name="validFrom"
                            onChange={handleValidFromChange}
                            required
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DatePicker
                            value={values.validTo}
                            label={t('offers.validTo')}
                            id="validTo"
                            name="validTo"
                            onChange={handleValidToChange}
                            required
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Select
                            label={t('offers.event')}
                            name="eventId"
                            id="eventId"
                            value={values.eventId}
                            options={eventItems}
                            handleChange={handleChange}
                            placeholder
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <MultipleSelect
                            label={t('offers.wineries')}
                            name="wineryId"
                            value={values.wineryIds}
                            items={wineryItems}
                            onChange={handleMultipleSelectChange}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Label content={t('form.description')} />
                        <HtmlEditor
                            editorRef={editorRef}
                            content={values?.description}
                            height={380}
                        />
                    </Grid>
                </Grid>
            </form>
            <div className={classes.helperText}>
                <HelperText helperText={helperText} />
            </div>
        </FormModal>
    );
};

const useStyles = makeStyles({
    helperText: {
        marginTop: 15,
    },
});

OfferModal.propTypes = {
    open: bool.isRequired,
    onClose: func,
    isEdit: bool,
    offer: object,
};

export default OfferModal;
