import React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    makeStyles,
} from '@material-ui/core';
import { element, string, bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

const FormCardLayout = ({
    title,
    subheader,
    children,
    buttonTitle,
    buttonDisabled,
    onSave,
    onCancel,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <form className={classes.form}>
                <Card>
                    <CardHeader subheader={subheader} title={title} />
                    <Divider />
                    <CardContent> {children} </CardContent>
                    <Divider />
                    <Box className={classes.box}>
                        <Button
                            color="primary"
                            variant="outlined"
                            disabled={buttonDisabled}
                            onClick={onCancel}
                        >
                            {t('form.cancel')}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={buttonDisabled}
                            onClick={onSave}
                        >
                            {buttonTitle}
                        </Button>
                    </Box>
                </Card>
            </form>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: 25,
    },
    box: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 15,
    },
    form: {
        width: '80%',
    },
});

FormCardLayout.propTypes = {
    children: element,
    title: string.isRequired,
    subheader: string,
    buttonTitle: string.isRequired,
    buttonDisabled: bool,
    onSave: func.isRequired,
    onCancel: func,
};

export default FormCardLayout;
