import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMobileUsersRequest } from '../../actions/users';
import ListLayout from '../../components/Layout/ListLayout';
import { USER_PAGE_SIZE } from '../../constants/constants';
import {
    selectMobileUsers,
    selectMobileUserLoading,
} from '../../constants/selectors';
import { styles } from '../../constants/theme';
import useUrlParams from '../../hooks/useUrlParams';
import MobileUserTable from './MobileUserTable';

const MobileUsers = () => {
    const { t } = useTranslation();
    const isRequested = useSelector(selectMobileUserLoading);
    const dispatch = useDispatch();
    const {
        items = [],
        totalItems = 0,
        size = USER_PAGE_SIZE,
    } = useSelector(selectMobileUsers);
    const params = useUrlParams();
    const [page, setPage] = useState(params.page || 0);

    useEffect(() => {
        dispatch(
            getMobileUsersRequest({
                page,
                size: USER_PAGE_SIZE,
                sort: '',
            })
        );
    }, [page]);

    const handlePageChange = (_, newPage) => {
        setPage(newPage);
        history.push(`/mobile-users?page=${newPage}`);
    };

    return (
        <div style={styles.defaultContainer}>
            <ListLayout
                title={t('drawer.mobileUsers')}
                isRequested={isRequested}
                size={size}
                page={page}
                handlePageChange={handlePageChange}
                totalItems={totalItems}
            >
                <MobileUserTable items={items} />
            </ListLayout>
        </div>
    );
};

export default MobileUsers;
