import React, { useEffect } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import SummaryItems from './SummaryItems';
import LatestEventsTable from './LatestEventsTable';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsSuperAdmin,
    selectStatistics,
} from '../../constants/selectors';
import { getStatisticsDataRequest } from '../../actions/statistics';
import ExpiringOffersTable from './ExpiringOffersTable';

const Home = () => {
    const dispatch = useDispatch();
    const { isRequested, statistics = {} } = useSelector(selectStatistics);
    const isSuperAdmin = useSelector(selectIsSuperAdmin);
    const classes = useStyles();

    useEffect(() => {
        dispatch(getStatisticsDataRequest());
    }, []);

    if (isRequested && isEmpty(statistics)) {
        return (
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <SummaryItems statistics={statistics} />
            {isSuperAdmin && (
                <>
                    <LatestEventsTable eventList={statistics.eventList} />
                    <ExpiringOffersTable offerList={statistics.offerList} />
                </>
            )}
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        margin: 35,
    },
    loading: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    grid: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
});

export default Home;
