import {
    GET_EVENTS_FAILED,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_REQUEST,
    CLEAR_SELECTED_EVENTS,
    SET_SELECTED_EVENTS,
    ADD_NEW_EVENT_REQUEST,
    ADD_NEW_EVENT_SUCCESS,
    ADD_NEW_EVENT_FAILED,
    EDIT_EVENT_REQUEST,
    EDIT_EVENT_SUCCESS,
    EDIT_EVENT_FAILED,
    SET_IMAGE,
    DELETE_EVENTS_REQUEST,
    DELETE_EVENTS_SUCCESS,
    DELETE_EVENTS_FAILED,
    GET_ALL_EVENTS_REQUEST,
    GET_ALL_EVENTS_SUCCESS,
    GET_ALL_EVENTS_FAILED,
} from '../actions/constants';
import { INITIAL_EVENT_STATE } from '../constants/states';

export default (state = INITIAL_EVENT_STATE, action) => {
    switch (action.type) {
        case GET_EVENTS_REQUEST:
            return { ...state, isEventsRequested: true };
        case GET_EVENTS_SUCCESS:
            return {
                ...state,
                isEventsRequested: false,
                events: action.payload,
            };
        case GET_EVENTS_FAILED:
            return { ...state, isEventsRequested: false };
        case CLEAR_SELECTED_EVENTS:
            return { ...state, selectedEvents: [] };
        case SET_SELECTED_EVENTS:
            return { ...state, selectedEvents: action.payload };
        case ADD_NEW_EVENT_REQUEST:
            return { ...state, isCreateRequested: true };
        case ADD_NEW_EVENT_SUCCESS:
            return {
                ...state,
                isCreateRequested: false,
                events: {
                    ...state.events,
                    totalItems: state.events.totalItems + 1,
                    items: [...state.events.items, action.payload],
                },
            };
        case ADD_NEW_EVENT_FAILED:
            return { ...state, isCreateRequested: false };
        case EDIT_EVENT_REQUEST:
            return { ...state, isEditRequested: true };
        case EDIT_EVENT_SUCCESS: {
            return {
                ...state,
                isEditRequested: false,
                events: {
                    ...state.events,
                    items: state.events.items.map((event) => {
                        if (event.id === action.payload.id) {
                            return action.payload;
                        }
                        return event;
                    }),
                },
            };
        }
        case EDIT_EVENT_FAILED:
            return { ...state, isEditRequested: false };
        case SET_IMAGE:
            if (action.payload.eventId) {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        items: state.events.items.map((event) => {
                            if (event.id === action.payload.eventId) {
                                return {
                                    ...event,
                                    coverImageId: action.payload.photoId,
                                };
                            }
                            return event;
                        }),
                    },
                };
            }
            return state;
        case DELETE_EVENTS_REQUEST:
            return { ...state, isDeleteRequested: true };
        case DELETE_EVENTS_SUCCESS: {
            const deletedEvents = [...action.payload];
            const newEvents = state.events.items.filter(
                (event) =>
                    !deletedEvents.some(
                        (deletedEvent) => deletedEvent.id === event.id
                    )
            );

            return {
                ...state,
                isDeleteRequested: false,
                events: {
                    ...state.events,
                    items: newEvents,
                    totalItems: state.events.totalItems - deletedEvents.length,
                },
            };
        }
        case DELETE_EVENTS_FAILED:
            return { ...state, isDeleteRequested: false };
        case GET_ALL_EVENTS_REQUEST:
            return { ...state, isEventsRequested: true };
        case GET_ALL_EVENTS_SUCCESS:
            return {
                ...state,
                isEventsRequested: false,
                dropdownItems: action.payload,
            };
        case GET_ALL_EVENTS_FAILED:
            return { ...state, isEventsRequested: false };
        default:
            return state;
    }
};
