import React, { useEffect, useState } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getWineriesRequest } from '../../actions/wineries';
import {
    selectIsSuperAdmin,
    selectWineries,
    selectWineryLoading,
} from '../../constants/selectors';
import MultipleWineryList from './WineryLists/MultipleWineryList';
import SingleWineryList from './WineryLists/SingleWineryList';
import { WINERY_PAGE_SIZE } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import WineryModal from './WineryModal';
import useUrlParams from '../../hooks/useUrlParams';
import history from '../../constants/history';
import { FloatingAddButton } from '../../components/Button';

const WineryList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        items = [],
        totalPages = 0,
        totalItems = 0,
    } = useSelector(selectWineries);
    const isLoading = useSelector(selectWineryLoading);
    const isSuperAdmin = useSelector(selectIsSuperAdmin);
    const [addOpen, setAddOpen] = useState(false);
    const params = useUrlParams();
    const [page, setPage] = useState(params.page || 1);

    useEffect(() => {
        if (params.page) {
            setPage(params.page);
        }
    }, [params]);

    useEffect(() => {
        dispatch(
            getWineriesRequest({
                page: page - 1,
                size: WINERY_PAGE_SIZE,
                sort: '',
            })
        );
    }, [page]);

    const handlePageChange = (_, newPage) => {
        setPage(newPage);
        history.push(`/wineries?page=${newPage}`);
    };

    if (isLoading) {
        return (
            <div className={classes.root}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            {isSuperAdmin ? (
                <MultipleWineryList
                    items={items}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    page={Number(page)}
                    totalItems={totalItems}
                />
            ) : (
                <SingleWineryList items={items} />
            )}
            {isSuperAdmin && (
                <FloatingAddButton
                    title={t('wineries.winery')}
                    onClick={() => setAddOpen(true)}
                />
            )}
            <WineryModal open={addOpen} onClose={() => setAddOpen(false)} />
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        position: 'relative',
        height: 'auto',
    },
    root: {
        justifyContent: 'center',
        display: 'flex',
        marginTop: 15,
    },
});

export default WineryList;
