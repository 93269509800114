import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Menu/Header';
import AccommodationList from './AccommodationList';
import AccommodationModal from './AccommodationModal';
import { getAllWineriesRequest } from '../../actions/wineries';
import { selectSelectedAccommodations } from '../../constants/selectors';
import WarningModal from '../../components/Modal/WarningModal';
import { useTranslation } from 'react-i18next';
import { deleteAccommodationsRequest } from '../../actions/accommodations';
import { styles } from '../../constants/theme';

const Accommodations = () => {
    const selectedAccommodations = useSelector(selectSelectedAccommodations);
    const [addOpen, setAddOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAllWineriesRequest());
    }, []);

    const onDelete = () => {
        setDeleteOpen(false);
        dispatch(deleteAccommodationsRequest(selectedAccommodations));
    };

    return (
        <div style={styles.defaultContainer}>
            <Header
                deleteDisabled={selectedAccommodations?.length === 0}
                onAddClick={() => setAddOpen(true)}
                onDeleteClick={() => setDeleteOpen(true)}
            />
            <AccommodationList />
            <AccommodationModal
                open={addOpen}
                onClose={() => setAddOpen(false)}
            />
            <WarningModal
                open={deleteOpen}
                handleClose={() => setDeleteOpen(false)}
                content={t('accommodations.confirmDelete')}
                okButtonText={t('actions.delete')}
                onOkClick={onDelete}
                title={t('accommodations.toDelete')}
            />
        </div>
    );
};

export default Accommodations;
