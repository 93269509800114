import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectOfferLoading,
    selectOffers,
    selectSelectedOffers,
} from '../../constants/selectors';
import history from '../../constants/history';
import { OFFER_PAGE_SIZE } from '../../constants/constants';
import OfferTable from './OfferTable';
import ListLayout from '../../components/Layout/ListLayout';
import { getOffersRequest } from '../../actions/offers';
import useUrlParams from '../../hooks/useUrlParams';
import OfferModal from './OfferModal';
import { useTranslation } from 'react-i18next';

const OfferList = () => {
    const selectedOfferIds = useSelector(selectSelectedOffers);
    const isRequested = useSelector(selectOfferLoading);
    const {
        items = [],
        totalItems = 0,
        size = OFFER_PAGE_SIZE,
    } = useSelector(selectOffers);
    const params = useUrlParams();
    const [page, setPage] = useState(params.page || 0);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(
            getOffersRequest({
                page,
                size: OFFER_PAGE_SIZE,
                sort: '',
            })
        );
    }, [page]);

    const handlePageChange = (_, newPage) => {
        setPage(newPage);
        history.push(`/offers?page=${newPage}`);
    };

    const onEditMenuClick = () => {
        setEditOpen(true);
        setAnchorEl(null);
    };

    return (
        <>
            <ListLayout
                title={t('drawer.offers')}
                isRequested={isRequested}
                totalItems={totalItems}
                size={size}
                page={page}
                handlePageChange={handlePageChange}
            >
                <OfferTable
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                    selectedOfferIds={selectedOfferIds}
                    items={items}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    onEditMenuClick={onEditMenuClick}
                />
            </ListLayout>
            <OfferModal
                isEdit={true}
                offer={selectedOffer}
                open={editOpen}
                onClose={() => setEditOpen(false)}
            />
        </>
    );
};

export default OfferList;
