import React from 'react';
import SimpleTable from '../../components/Table/SimpleTable';
import { useTranslation } from 'react-i18next';
import { array } from 'prop-types';
import { dateFormatter } from '../../constants/tableHelper';

const MobileUserTable = ({ items }) => {
    const { t } = useTranslation();

    const columns = [
        { id: 'name', label: t('mobileUsers.name'), minWidth: 80 },
        { id: 'email', label: t('mobileUsers.email') },
        {
            id: 'lastLogin',
            label: t('mobileUsers.lastLogin'),
            minWidth: 80,
            format: (value) =>
                dateFormatter(value, t('mobileUsers.unknownDate')),
        },
        {
            id: 'createdAt',
            label: t('mobileUsers.createdAt'),
            minWidth: 80,
            align: 'right',
            format: (value) =>
                dateFormatter(value, t('mobileUsers.unknownDate')),
        },
    ];

    return <SimpleTable columns={columns} items={items}></SimpleTable>;
};

MobileUserTable.propTypes = {
    items: array,
};

export default MobileUserTable;
