import { useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../actions/notification';
import { NotificationType } from '../constants/enums';
import { selectSession } from './../constants/selectors';

export const useSession = () => {
    const { session, sessionRemoved } = useSelector(selectSession);
    const dispatch = useDispatch();
    const firstUpdate = useRef(true);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (session === null && sessionRemoved) {
            dispatch(
                setNotification({
                    message: 'notification.error.user.token-expired',
                    type: NotificationType.Warning,
                })
            );
        }
    }, [sessionRemoved]);
};
