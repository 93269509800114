import {
    GET_TRAVELS_REQUEST,
    GET_TRAVELS_SUCCESS,
    GET_TRAVELS_FAILED,
    EDIT_TRAVEL_REQUEST,
    EDIT_TRAVEL_SUCCESS,
    EDIT_TRAVEL_FAILED,
} from '../actions/constants';
import { INITIAL_TRAVEL_STATE } from '../constants/states';

export default (state = INITIAL_TRAVEL_STATE, action) => {
    switch (action.type) {
        case GET_TRAVELS_REQUEST:
            return { ...state, isTravelsRequested: true };
        case GET_TRAVELS_SUCCESS:
            return {
                ...state,
                isTravelsRequested: false,
                travels: action.payload,
            };
        case GET_TRAVELS_FAILED:
            return { ...state, isTravelsRequested: false };
        case EDIT_TRAVEL_REQUEST:
            return { ...state, isEditRequested: true };
        case EDIT_TRAVEL_SUCCESS:
            return {
                ...state,
                isEditRequested: false,
                travels: action.payload,
            };
        case EDIT_TRAVEL_FAILED:
            return { ...state, isEditRequested: false };
        default:
            return state;
    }
};
