import {
    DELETE_ACCOMMODATION_IMAGE_FAILED,
    DELETE_ACCOMMODATION_IMAGE_REQUEST,
    DELETE_ACCOMMODATION_IMAGE_SUCCESS,
    DELETE_OFFER_IMAGE_FAILED,
    DELETE_OFFER_IMAGE_REQUEST,
    DELETE_OFFER_IMAGE_SUCCESS,
    DELETE_WINERY_IMAGE_FAILED,
    DELETE_WINERY_IMAGE_REQUEST,
    DELETE_WINERY_IMAGE_SUCCESS,
    SELECT_ACCOMMODATION_COVER_PHOTO_FAILED,
    SELECT_ACCOMMODATION_COVER_PHOTO_REQUEST,
    SELECT_ACCOMMODATION_COVER_PHOTO_SUCCESS,
    SELECT_OFFER_COVER_PHOTO_FAILED,
    SELECT_OFFER_COVER_PHOTO_REQUEST,
    SELECT_OFFER_COVER_PHOTO_SUCCESS,
    SELECT_WINERY_COVER_PHOTO_FAILED,
    SELECT_WINERY_COVER_PHOTO_REQUEST,
    SELECT_WINERY_COVER_PHOTO_SUCCESS,
    SET_IMAGE,
    UPLOAD_ACCOMMODATION_IMAGE_FAILED,
    UPLOAD_ACCOMMODATION_IMAGE_REQUEST,
    UPLOAD_ACCOMMODATION_IMAGE_SUCCESS,
    UPLOAD_OFFER_IMAGE_FAILED,
    UPLOAD_OFFER_IMAGE_REQUEST,
    UPLOAD_OFFER_IMAGE_SUCCESS,
    UPLOAD_WINERY_IMAGE_FAILED,
    UPLOAD_WINERY_IMAGE_REQUEST,
    UPLOAD_WINERY_IMAGE_SUCCESS,
} from './constants';

export const uploadWineryImageRequest = (payload) => {
    return {
        type: UPLOAD_WINERY_IMAGE_REQUEST,
        payload,
    };
};

export const uploadWineryImageSuccess = (payload) => {
    return {
        type: UPLOAD_WINERY_IMAGE_SUCCESS,
        payload,
    };
};

export const uploadWineryImageFailed = (payload) => {
    return {
        type: UPLOAD_WINERY_IMAGE_FAILED,
        payload,
    };
};

export const deleteWineryImageRequest = (payload) => {
    return {
        type: DELETE_WINERY_IMAGE_REQUEST,
        payload,
    };
};

export const deleteWineryImageSuccess = (payload) => {
    return {
        type: DELETE_WINERY_IMAGE_SUCCESS,
        payload,
    };
};

export const deleteWineryImageFailed = (payload) => {
    return {
        type: DELETE_WINERY_IMAGE_FAILED,
        payload,
    };
};

export const selectAccommodationCoverPhotoRequest = (payload) => {
    return {
        type: SELECT_ACCOMMODATION_COVER_PHOTO_REQUEST,
        payload,
    };
};

export const selectAccommodationCoverPhotoSuccess = (payload) => {
    return {
        type: SELECT_ACCOMMODATION_COVER_PHOTO_SUCCESS,
        payload,
    };
};

export const selectAccommodationCoverPhotoFailed = (payload) => {
    return {
        type: SELECT_ACCOMMODATION_COVER_PHOTO_FAILED,
        payload,
    };
};

export const selectWineryCoverPhotoFailed = (payload) => {
    return {
        type: SELECT_WINERY_COVER_PHOTO_FAILED,
        payload,
    };
};

export const selectWineryCoverPhotoRequest = (payload) => {
    return {
        type: SELECT_WINERY_COVER_PHOTO_REQUEST,
        payload,
    };
};

export const selectWineryCoverPhotoSuccess = (payload) => {
    return {
        type: SELECT_WINERY_COVER_PHOTO_SUCCESS,
        payload,
    };
};

export const setImage = (payload) => {
    return {
        type: SET_IMAGE,
        payload,
    };
};

export const uploadAccommodationImageRequest = (payload) => {
    return {
        type: UPLOAD_ACCOMMODATION_IMAGE_REQUEST,
        payload,
    };
};

export const uploadAccommodationImageSuccess = (payload) => {
    return {
        type: UPLOAD_ACCOMMODATION_IMAGE_SUCCESS,
        payload,
    };
};

export const uploadAccommodationImageFailed = (payload) => {
    return {
        type: UPLOAD_ACCOMMODATION_IMAGE_FAILED,
        payload,
    };
};

export const deleteAccommodationImageRequest = (payload) => {
    return {
        type: DELETE_ACCOMMODATION_IMAGE_REQUEST,
        payload,
    };
};

export const deleteAccommodationImageSuccess = (payload) => {
    return {
        type: DELETE_ACCOMMODATION_IMAGE_SUCCESS,
        payload,
    };
};

export const deleteAccommodationImageFailed = (payload) => {
    return {
        type: DELETE_ACCOMMODATION_IMAGE_FAILED,
        payload,
    };
};

export const uploadOfferImageRequest = (payload) => {
    return {
        type: UPLOAD_OFFER_IMAGE_REQUEST,
        payload,
    };
};

export const uploadOfferImageSuccess = (payload) => {
    return {
        type: UPLOAD_OFFER_IMAGE_SUCCESS,
        payload,
    };
};

export const uploadOfferImageFailed = (payload) => {
    return {
        type: UPLOAD_OFFER_IMAGE_FAILED,
        payload,
    };
};

export const deleteOfferImageRequest = (payload) => {
    return {
        type: DELETE_OFFER_IMAGE_REQUEST,
        payload,
    };
};

export const deleteOfferImageSuccess = (payload) => {
    return {
        type: DELETE_OFFER_IMAGE_SUCCESS,
        payload,
    };
};

export const deleteOfferImageFailed = (payload) => {
    return {
        type: DELETE_OFFER_IMAGE_FAILED,
        payload,
    };
};

export const selectOfferCoverPhotoRequest = (payload) => {
    return {
        type: SELECT_OFFER_COVER_PHOTO_REQUEST,
        payload,
    };
};

export const selectOfferCoverPhotoSuccess = (payload) => {
    return {
        type: SELECT_OFFER_COVER_PHOTO_SUCCESS,
        payload,
    };
};

export const selectOfferCoverPhotoFailed = (payload) => {
    return {
        type: SELECT_OFFER_COVER_PHOTO_FAILED,
        payload,
    };
};
