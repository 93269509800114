import { setNotification } from '../actions/notification';
import API from '../constants/api';
import { NotificationType } from '../constants/enums';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
    ADD_NEW_NEWS_REQUEST,
    DELETE_NEWS_REQUEST,
    EDIT_NEWS_REQUEST,
    GET_ALL_NEWS_REQUEST,
    GET_NEWS_REQUEST,
} from '../actions/constants';
import {
    getNewsSuccess,
    getNewsFailed,
    editNewsSuccess,
    editNewsFailed,
    addNewNewsSuccess,
    addNewNewsFailed,
    clearSelectedNews,
    deleteNewsFailed,
    deleteNewsSuccess,
    getAllNewsSuccess,
    getAllNewsFailed,
} from '../actions/news';
import { setImage } from '../actions/images';
import { isEmpty } from 'lodash';

function newsRequest(payload) {
    return API.get('/news', {
        params: {
            ...payload,
        },
    });
}

function allNewsRequest() {
    return API.get('/news/all');
}

function editNewsRequest(payload) {
    return API.put(`/news/${payload.id}`, payload);
}

function createRequest(payload) {
    return API.post('/news', payload);
}

function deleteRequest(payload) {
    return API.patch('/news/delete', payload);
}

function uploadNewsImageRequest(payload) {
    return API.post(`/news/${payload.id}/images`, payload.file, {
        'Content-Type': `multipart/form-data`,
    });
}

function* getNewsRequest(payload) {
    try {
        const { data } = yield call(newsRequest, payload.payload);
        yield put(getNewsSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getNewsFailed(error));
    }
}

function* getAllNewsRequest() {
    try {
        const { data } = yield call(allNewsRequest);
        yield put(getAllNewsSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getAllNewsFailed(error));
    }
}

function* putNewsEditRequest(payload) {
    try {
        const { data } = yield call(editNewsRequest, payload.payload);
        yield put(editNewsSuccess(data));
        if (payload.payload.file && !isEmpty(payload.payload.file)) {
            let formData = new FormData();
            payload.payload.file.map((file) => formData.append('file', file));
            const imageUpload = yield call(uploadNewsImageRequest, {
                file: formData,
                id: data.id,
            });
            yield put(
                setImage({
                    photoId: imageUpload.data.id,
                    newsId: data.id,
                })
            );
        }
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(editNewsFailed(error));
    }
}

function* addNewNewsRequest(payload) {
    try {
        const { data } = yield call(createRequest, payload.payload);
        yield put(addNewNewsSuccess(data));
        if (payload.payload.file && !isEmpty(payload.payload.file)) {
            let formData = new FormData();
            payload.payload.file.map((file) => formData.append('file', file));
            const imageUpload = yield call(uploadNewsImageRequest, {
                file: formData,
                id: data.id,
            });
            yield put(
                setImage({
                    photoId: imageUpload.data.id,
                    newsId: data.id,
                })
            );
        }
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(addNewNewsFailed(error));
    }
}

function* deleteNewsRequest(payload) {
    try {
        const { data } = yield call(deleteRequest, payload.payload);
        yield put(deleteNewsSuccess(data));
        yield put(clearSelectedNews());
        yield put(
            setNotification({
                message: 'notification.success.success-deletion',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(deleteNewsFailed(error));
    }
}

export default function* watchNews() {
    yield takeLatest(GET_NEWS_REQUEST, getNewsRequest);
    yield takeLatest(GET_ALL_NEWS_REQUEST, getAllNewsRequest);
    yield takeLatest(EDIT_NEWS_REQUEST, putNewsEditRequest);
    yield takeLatest(ADD_NEW_NEWS_REQUEST, addNewNewsRequest);
    yield takeLatest(DELETE_NEWS_REQUEST, deleteNewsRequest);
}
