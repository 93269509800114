import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EMAIL_REGEX } from '../constants/constants';

export const useUserDataValidation = ({ email, firstName, lastName }) => {
    const [helperText, setHelperText] = useState('');
    const [isValid, setIsValid] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (!EMAIL_REGEX.test(email)) {
            setHelperText(`${t('notification.validation.invalid.email')}`);
            setIsValid(false);
            return;
        } else if (
            isEmpty(email) ||
            isEmpty(firstName.trim()) ||
            isEmpty(lastName.trim())
        ) {
            setHelperText(`${t('notification.validation.required-fields')}`);
            setIsValid(false);
            return;
        }
        setIsValid(true);
        setHelperText('');
        return;
    }, [email, firstName, lastName]);

    return [helperText, isValid, setHelperText];
};
