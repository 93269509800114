import {
    GET_OFFERS_REQUEST,
    GET_OFFERS_SUCCESS,
    GET_OFFERS_FAILED,
    EDIT_OFFER_REQUEST,
    EDIT_OFFER_SUCCESS,
    EDIT_OFFER_FAILED,
    CLEAR_SELECTED_OFFERS,
    SET_SELECTED_OFFERS,
    ADD_NEW_OFFER_FAILED,
    ADD_NEW_OFFER_REQUEST,
    ADD_NEW_OFFER_SUCCESS,
    DELETE_OFFERS_FAILED,
    DELETE_OFFERS_REQUEST,
    DELETE_OFFERS_SUCCESS,
    UPLOAD_OFFER_IMAGE_REQUEST,
    UPLOAD_OFFER_IMAGE_SUCCESS,
    UPLOAD_OFFER_IMAGE_FAILED,
    DELETE_OFFER_IMAGE_REQUEST,
    DELETE_OFFER_IMAGE_SUCCESS,
    DELETE_OFFER_IMAGE_FAILED,
    SELECT_OFFER_COVER_PHOTO_REQUEST,
    SELECT_OFFER_COVER_PHOTO_SUCCESS,
    SELECT_OFFER_COVER_PHOTO_FAILED,
} from '../actions/constants';
import { INITIAL_OFFER_STATE } from '../constants/states';

export default (state = INITIAL_OFFER_STATE, action) => {
    switch (action.type) {
        case GET_OFFERS_REQUEST:
            return { ...state, isOffersRequested: true };
        case GET_OFFERS_SUCCESS:
            return {
                ...state,
                isOffersRequested: false,
                offers: action.payload,
            };
        case GET_OFFERS_FAILED:
            return { ...state, isOffersRequested: false };
        case CLEAR_SELECTED_OFFERS:
            return { ...state, selectedOffers: [] };
        case SET_SELECTED_OFFERS:
            return { ...state, selectedOffers: action.payload };
        case EDIT_OFFER_REQUEST:
            return { ...state, isEditRequested: true };
        case EDIT_OFFER_SUCCESS: {
            return {
                ...state,
                isEditRequested: false,
                offers: {
                    ...state.offers,
                    items: state.offers.items.map((offer) => {
                        if (offer.id === action.payload.id) {
                            return action.payload;
                        }
                        return offer;
                    }),
                },
            };
        }
        case EDIT_OFFER_FAILED:
            return { ...state, isEditRequested: false };
        case ADD_NEW_OFFER_REQUEST:
            return { ...state, isCreateRequested: true };
        case ADD_NEW_OFFER_SUCCESS:
            return {
                ...state,
                isCreateRequested: false,
                offers: {
                    ...state.offers,
                    totalItems: state.offers.totalItems + 1,
                    items: [...state.offers.items, action.payload],
                },
            };
        case ADD_NEW_OFFER_FAILED:
            return { ...state, isCreateRequested: false };
        case DELETE_OFFERS_REQUEST:
            return { ...state, isDeleteRequested: true };
        case DELETE_OFFERS_SUCCESS: {
            const deletedOffers = [...action.payload];
            const newOffers = state.offers.items.filter(
                (offer) =>
                    !deletedOffers.some(
                        (deletedOffer) => deletedOffer.id === offer.id
                    )
            );
            return {
                ...state,
                isDeleteRequested: false,
                offers: {
                    ...state.offers,
                    items: newOffers,
                    totalItems: state.offers.totalItems - deletedOffers.length,
                },
            };
        }
        case DELETE_OFFERS_FAILED:
            return { ...state, isDeleteRequested: false };
        case UPLOAD_OFFER_IMAGE_REQUEST:
            return { ...state, isUploadRequested: true };
        case UPLOAD_OFFER_IMAGE_SUCCESS:
            return {
                ...state,
                isUploadRequested: false,
                offers: {
                    ...state.offers,
                    items: state.offers.items.map((offer) => {
                        if (offer.id === action.payload.offerId) {
                            return {
                                ...offer,
                                images: [
                                    ...offer.images,
                                    ...action.payload.urls,
                                ],
                            };
                        }
                        return offer;
                    }),
                },
            };
        case UPLOAD_OFFER_IMAGE_FAILED:
            return { ...state, isUploadRequested: false };
        case DELETE_OFFER_IMAGE_REQUEST:
            return { ...state, isDeleteRequested: true };
        case DELETE_OFFER_IMAGE_SUCCESS:
            return {
                ...state,
                isDeleteRequested: false,
                offers: {
                    ...state.offers,
                    items: state.offers.items.map((offer) => {
                        if (offer.id === action.payload.offerId) {
                            return {
                                ...offer,
                                images: offer.images.filter(
                                    (image) =>
                                        image.id !== action.payload.photoId
                                ),
                            };
                        }
                        return offer;
                    }),
                },
            };
        case DELETE_OFFER_IMAGE_FAILED:
            return { ...state, isDeleteRequested: false };
        case SELECT_OFFER_COVER_PHOTO_REQUEST:
            return { ...state, isCoverPhotoRequested: true };
        case SELECT_OFFER_COVER_PHOTO_SUCCESS:
            return {
                ...state,
                isCoverPhotoRequested: false,
                offers: {
                    ...state.offers,
                    items: state.offers.items.map((offer) => {
                        if (
                            action.payload.offerId &&
                            offer.id === action.payload.offerId
                        ) {
                            return {
                                ...offer,
                                images: offer.images.map((image) => {
                                    if (image.id === action.payload.id) {
                                        return action.payload;
                                    }
                                    if (action.payload.cover) {
                                        return { ...image, cover: false };
                                    }
                                    return image;
                                }),
                            };
                        }
                        return offer;
                    }),
                },
            };
        case SELECT_OFFER_COVER_PHOTO_FAILED:
            return { ...state, isCoverPhotoRequested: false };
        default:
            return state;
    }
};
