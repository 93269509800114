import React from 'react';
import ActionMenu from '../../components/Menu/ActionMenu';
import { func, object } from 'prop-types';
import { EditMenuItem, ImageMenuItem } from '../../components/MenuItem';

const OfferActionMenu = ({
    onEditClick,
    anchorEl,
    handleClose,
    onUploadClick,
}) => {
    return (
        <ActionMenu anchorEl={anchorEl} handleClose={handleClose}>
            <EditMenuItem onEditClick={onEditClick} />
            <ImageMenuItem onUploadClick={onUploadClick} />
        </ActionMenu>
    );
};

OfferActionMenu.propTypes = {
    anchorEl: object,
    handleClose: func,
    onEditClick: func,
    onUploadClick: func,
};

export default OfferActionMenu;
