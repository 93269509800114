import { Divider, Grid, makeStyles } from '@material-ui/core';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../actions/notification';
import { DatePicker, HelperText, Label } from '../../components/Form';
import {
    TextInput,
    MultipleSelect,
    Select,
    HtmlEditor,
} from '../../components/Input';
import FormModal from '../../components/Modal/FormModal';
import { NotificationType } from '../../constants/enums';
import {
    selectIsSuperAdmin,
    selectUserWinery,
    selectWineryDropdownItems,
    selectNewsDropdownItems,
} from '../../constants/selectors';
import { useEventValidation } from '../../hooks/useEventValidation';
import { addNewEventRequest, editEventRequest } from '../../actions/events';
import { ImageGridItem } from '../../components/Grid';
import { useWindowSize } from '../../hooks/useWindowSize';
import { styles } from '../../constants/theme';

const EventModal = ({ open, onClose, isEdit = false, event }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const wineryItems = useSelector(selectWineryDropdownItems);
    const newsItems = useSelector(selectNewsDropdownItems);
    const adminWinery = useSelector(selectUserWinery);
    const isSuperAdmin = useSelector(selectIsSuperAdmin);
    const classes = useStyles();
    const editorRef = useRef(null);
    const isDesktop = useWindowSize();
    const originalValues = {
        name: '',
        location: '',
        fromDate: new Date(),
        toDate: new Date(),
        description: '',
        wineryIds: isSuperAdmin ? [] : [adminWinery?.id],
        newsId: '',
    };
    const [values, setValues] = useState(originalValues);
    const [selectedFile, setSelectedFile] = useState(null);
    const [description, setDescription] = useState('');
    const [helperText, isValid] = useEventValidation({
        name: values.name,
        location: values.location,
        fromDate: values.fromDate,
        toDate: values.toDate,
        description: description,
        wineryIds: values.wineryIds,
    });

    useEffect(() => {
        if (isEdit && event) {
            setDescription(event.description);
            setValues({
                name: event.name,
                location: event.location,
                fromDate: event.fromDate,
                toDate: event.toDate,
                description: event.description,
                wineryIds: event.winery?.map((winery) => winery.id) || [],
                newsId: event?.news?.id,
            });
        }
    }, [open]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleFromDateChange = (date) => {
        setValues({
            ...values,
            fromDate: date,
        });
    };

    const handleToDateChange = (date) => {
        setValues({
            ...values,
            toDate: date,
        });
    };

    const handleMultipleSelectChange = (event) => {
        setValues({
            ...values,
            wineryIds: event.target.value,
        });
    };

    const onSave = () => {
        if (isValid) {
            if (isEdit) {
                dispatch(
                    editEventRequest({
                        ...values,
                        id: event.id,
                        file: selectedFile,
                        description: editorRef?.current?.getContent(),
                    })
                );
            } else {
                dispatch(
                    addNewEventRequest({
                        ...values,
                        file: selectedFile,
                        description: editorRef?.current?.getContent(),
                    })
                );
            }
            onModalClose();
        } else {
            dispatch(
                setNotification({
                    message: t('notification.validation.required-fields'),
                    type: NotificationType.Error,
                })
            );
        }
    };

    const onModalClose = () => {
        setValues(originalValues);
        onClose();
    };

    return (
        <FormModal
            onSave={onSave}
            title={isEdit ? t('events.editEvent') : t('events.newEvent')}
            open={open}
            onClose={onModalClose}
            isWide={isDesktop}
        >
            <form noValidate style={isDesktop ? styles.form : null}>
                <Grid
                    container
                    spacing={2}
                    style={isDesktop ? styles.side : null}
                >
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('events.name')}
                            name="name"
                            id="name"
                            value={values?.name}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput
                            onChange={handleChange}
                            label={t('events.location')}
                            name="location"
                            id="location"
                            value={values?.location}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <MultipleSelect
                            label={t('events.wineries')}
                            name="wineryId"
                            value={values.wineryIds}
                            items={wineryItems}
                            onChange={handleMultipleSelectChange}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DatePicker
                            value={values.fromDate}
                            label={t('events.fromDate')}
                            id="fromDate"
                            name="fromDate"
                            onChange={handleFromDateChange}
                            required
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DatePicker
                            value={values.toDate}
                            label={t('events.toDate')}
                            id="toDate"
                            name="toDate"
                            onChange={handleToDateChange}
                            required
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Select
                            label={t('events.news')}
                            name="newsId"
                            id="newsId"
                            value={values.newsId}
                            options={newsItems}
                            handleChange={handleChange}
                            placeholder
                        />
                    </Grid>
                    <ImageGridItem
                        isEdit={isEdit}
                        imageId={event?.coverImageId}
                        setSelectedFile={setSelectedFile}
                        title={t('upload.coverPhoto')}
                    />
                </Grid>
                {isDesktop && (
                    <Divider
                        orientation="vertical"
                        flexItem
                        style={styles.verticalDivider}
                    />
                )}
                <Grid container style={isDesktop ? styles.side : null}>
                    <Grid item md={12} xs={12}>
                        <Label content={t('form.description')} required />
                        <HtmlEditor
                            onChange={setDescription}
                            editorRef={editorRef}
                            content={values?.description}
                        />
                    </Grid>
                </Grid>
            </form>
            <div className={classes.helperText}>
                <HelperText helperText={helperText} />
            </div>
        </FormModal>
    );
};

const useStyles = makeStyles({
    helperText: {
        marginTop: 15,
    },
});

EventModal.propTypes = {
    open: bool.isRequired,
    onClose: func,
    isEdit: bool,
    event: object,
};

export default EventModal;
