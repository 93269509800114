import React from 'react';
import { useTranslation } from 'react-i18next';
import { array, func, object } from 'prop-types';
import { setSelectedEvents } from '../../actions/events';
import EventActionMenu from './EventActionMenu';
import Table from '../../components/Table/Table';
import { wineryListFormatter } from '../../constants/tableHelper';

const EventTable = ({
    selectedEventIds,
    setAnchorEl,
    anchorEl,
    items,
    setSelectedEvent,
    onEditMenuClick,
}) => {
    const { t } = useTranslation();

    const onMenuClick = (e, event) => {
        setAnchorEl(e.currentTarget);
        setSelectedEvent(event);
    };

    const columns = [
        { id: 'name', label: t('events.name') },
        { id: 'location', label: t('events.location') },
        { id: 'fromDate', label: t('events.fromDate'), minWidth: 80 },
        { id: 'toDate', label: t('events.toDate'), minWidth: 80 },
        {
            id: 'winery',
            label: t('events.wineries'),
            format: (value) => wineryListFormatter(value),
            minWidth: 270,
        },
    ];

    return (
        <Table
            onMenuClick={onMenuClick}
            columns={columns}
            items={items}
            selectedItemIds={selectedEventIds}
            setSelectedItems={setSelectedEvents}
        >
            <EventActionMenu
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                onEditClick={onEditMenuClick}
            />
        </Table>
    );
};

EventTable.propTypes = {
    selectedEventIds: array,
    anchorEl: object,
    setAnchorEl: func,
    items: array,
    setSelectedEvent: func,
    selectedEvent: object,
    onEditMenuClick: func,
};

export default EventTable;
