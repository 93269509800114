import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { func, instanceOf, string, oneOfType, bool } from 'prop-types';
import { CALENDAR_DATE_FORMAT } from '../../constants/constants';
import { useTranslation } from 'react-i18next';

const DatePicker = ({ onChange, value, label, id, name, required = false }) => {
    const { t } = useTranslation();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format={CALENDAR_DATE_FORMAT}
                    margin="normal"
                    name={name}
                    id={id}
                    autoOk={true}
                    label={label}
                    value={value}
                    onChange={onChange}
                    required={required}
                    invalidDateMessage={t('form.invalidDate')}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

DatePicker.propTypes = {
    value: oneOfType([string, instanceOf(Date)]),
    onChange: func,
    label: string,
    id: string,
    name: string,
    required: bool,
};

export default DatePicker;
