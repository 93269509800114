import React from 'react';
import { MenuItem } from '@material-ui/core';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { styles } from '../../constants/theme';

const ImageMenuItem = ({ onUploadClick }) => {
    const { t } = useTranslation();

    return (
        <MenuItem onClick={onUploadClick}>
            <PhotoLibraryIcon style={styles.icon} />{' '}
            {t('actions.pictureUpload')}
        </MenuItem>
    );
};

ImageMenuItem.propTypes = {
    onUploadClick: func,
};

export default ImageMenuItem;
