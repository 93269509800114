import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { setSelectedUsers } from '../../actions/users';
import { useTranslation } from 'react-i18next';
import UserActionMenu from './UserActionMenu';
import { array, func, object } from 'prop-types';
import Table from '../../components/Table/Table';
import { dateFormatter, roleFormatter } from '../../constants/tableHelper';
import { Roles } from '../../constants/enums';

const UserTable = ({
    selectedUserIds,
    anchorEl,
    setAnchorEl,
    items,
    setSelectedUser,
    onGeneratePasswordClick,
    onEditMenuClick,
    selectedUser,
}) => {
    const { t } = useTranslation();

    const onMenuClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(item);
    };

    const getItems = () => {
        return items.map((item) => ({
            ...item,
            disabled: item.roles.includes(Roles.SuperAdmin) || item.deleted,
        }));
    };

    const columns = [
        { id: 'name', label: t('users.name'), minWidth: 80 },
        { id: 'email', label: t('users.email') },
        { id: 'phoneNumber', label: t('users.phoneNumber') },
        {
            id: 'lastLogin',
            label: t('users.lastLogin'),
            minWidth: 80,
            format: (value) => dateFormatter(value, t('users.unknownDate')),
        },
        {
            id: 'roles',
            label: t('users.role'),
            minWidth: 80,
            format: (value) => roleFormatter(value, t),
        },
        {
            id: 'deleted',
            label: t('users.deleted'),
            format: (value) => (value ? <CheckIcon /> : ''),
            minWidth: 50,
        },
        {
            id: 'winery',
            label: t('users.winery'),
            minWidth: 50,
            format: (value) => value?.name || '',
        },
    ];

    return (
        <>
            <Table
                onMenuClick={onMenuClick}
                columns={columns}
                items={getItems()}
                selectedItemIds={selectedUserIds}
                setSelectedItems={setSelectedUsers}
            >
                <UserActionMenu
                    anchorEl={anchorEl}
                    handleClose={() => setAnchorEl(null)}
                    onGeneratePasswordClick={onGeneratePasswordClick}
                    onEditClick={onEditMenuClick}
                    passwordGenerationDisabled={selectedUser?.deleted}
                />
            </Table>
        </>
    );
};

UserTable.propTypes = {
    selectedUserIds: array,
    anchorEl: object,
    setAnchorEl: func,
    items: array,
    setSelectedUser: func,
    selectedUser: object,
    onGeneratePasswordClick: func,
    onEditMenuClick: func,
};

export default UserTable;
