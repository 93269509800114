import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
    ADD_NEW_WINERY_REQUEST,
    DELETE_WINERY_REQUEST,
    EDIT_WINERY_REQUEST,
    GET_ALL_WINERIES_REQUEST,
    GET_WINERIES_REQUEST,
    SELECT_WINERY_COVER_PHOTO_REQUEST,
    UPLOAD_WINERY_IMAGE_REQUEST,
} from '../actions/constants';
import {
    selectWineryCoverPhotoFailed,
    selectWineryCoverPhotoSuccess,
    uploadWineryImageFailed,
    uploadWineryImageSuccess,
} from '../actions/images';
import { setNotification } from '../actions/notification';
import {
    addNewWineryFailed,
    addNewWinerySuccess,
    deleteWineryFailed,
    deleteWinerySuccess,
    editWineryFailed,
    editWinerySuccess,
    getAllWineriesFailed,
    getAllWineriesSuccess,
    getWineriesFailed,
    getWineriesSuccess,
} from '../actions/wineries';
import API from '../constants/api';
import { NotificationType } from '../constants/enums';
import history from '../constants/history';

function wineriesRequest(payload) {
    return API.get('/wineries', {
        params: {
            ...payload,
        },
    });
}

function allWineriesRequest() {
    return API.get('/wineries/all');
}

function editWineryRequest(payload) {
    return API.put(`/wineries/${payload.id}`, payload);
}

function uploadWineryImageRequest(payload) {
    return API.post(`/wineries/${payload.id}/images`, payload.photos, {
        'Content-Type': `multipart/form-data`,
    });
}

function setWineryCoverImageRequest(payload) {
    return API.put(
        `wineries/${payload.wineryId}/images/${payload.imageId}/cover`,
        payload
    );
}

function deleteRequest(payload) {
    const searchParams = new URLSearchParams({
        size: payload.size.toString(),
        page: payload.page.toString(),
    });
    return API.delete(`/wineries/${payload.id}`, { params: searchParams });
}

function createRequest(payload) {
    return API.post('/wineries', payload);
}

function* getWineriesRequest(payload) {
    try {
        const { data } = yield call(wineriesRequest, payload.payload);
        yield put(getWineriesSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getWineriesFailed(error));
    }
}

function* getAllWineriesRequest() {
    try {
        const { data } = yield call(allWineriesRequest);
        yield put(getAllWineriesSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getAllWineriesFailed(error));
    }
}

function* putWineryEditRequest(payload) {
    try {
        const { data } = yield call(editWineryRequest, payload.payload);
        yield put(editWinerySuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(editWineryFailed(error));
    }
}

function* postWineryImageRequest(payload) {
    try {
        let formData = new FormData();
        payload.payload.photos.map((file) => formData.append('file', file));
        const { data } = yield call(uploadWineryImageRequest, {
            id: payload.payload.id,
            photos: formData,
        });
        yield put(
            uploadWineryImageSuccess({
                urls: data,
                wineryId: payload.payload.id,
            })
        );
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(uploadWineryImageFailed(error));
    }
}

function* putWineryCoverImageRequest(payload) {
    try {
        const { data } = yield call(
            setWineryCoverImageRequest,
            payload.payload
        );
        yield put(
            selectWineryCoverPhotoSuccess({
                ...data,
                wineryId: payload.payload.wineryId,
            })
        );
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(selectWineryCoverPhotoFailed(error));
    }
}

function* deleteWineryRequest(payload) {
    try {
        const { data } = yield call(deleteRequest, payload.payload);
        yield put(
            deleteWinerySuccess({
                id: payload.payload.id,
                pageableResponse: data,
            })
        );
        if (data && data.currentPage !== payload.payload.page) {
            history.push(`/wineries?page=${Number(data.currentPage) + 1}`);
        }
        yield put(
            setNotification({
                message: 'notification.success.success-deletion',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(deleteWineryFailed(error));
    }
}

function* addNewWineryRequest(payload) {
    try {
        const { data } = yield call(createRequest, payload.payload);
        yield put(addNewWinerySuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(addNewWineryFailed(error));
    }
}

export default function* watchWineries() {
    yield takeLatest(GET_WINERIES_REQUEST, getWineriesRequest);
    yield takeLatest(GET_ALL_WINERIES_REQUEST, getAllWineriesRequest);
    yield takeLatest(EDIT_WINERY_REQUEST, putWineryEditRequest);
    yield takeLatest(UPLOAD_WINERY_IMAGE_REQUEST, postWineryImageRequest);
    yield takeLatest(
        SELECT_WINERY_COVER_PHOTO_REQUEST,
        putWineryCoverImageRequest
    );
    yield takeLatest(DELETE_WINERY_REQUEST, deleteWineryRequest);
    yield takeLatest(ADD_NEW_WINERY_REQUEST, addNewWineryRequest);
}
