import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { func } from 'prop-types';
import { styles } from '../../constants/theme';

const DeleteMenuItem = ({ onDeleteClick }) => {
    const { t } = useTranslation();

    return (
        <MenuItem onClick={onDeleteClick}>
            <DeleteIcon style={styles.icon} /> {t('actions.delete')}
        </MenuItem>
    );
};

DeleteMenuItem.propTypes = {
    onDeleteClick: func,
};

export default DeleteMenuItem;
