import {
    ADD_NEW_EVENT_FAILED,
    ADD_NEW_EVENT_REQUEST,
    ADD_NEW_EVENT_SUCCESS,
    CLEAR_SELECTED_EVENTS,
    DELETE_EVENTS_FAILED,
    DELETE_EVENTS_REQUEST,
    DELETE_EVENTS_SUCCESS,
    EDIT_EVENT_FAILED,
    EDIT_EVENT_REQUEST,
    EDIT_EVENT_SUCCESS,
    GET_ALL_EVENTS_FAILED,
    GET_ALL_EVENTS_REQUEST,
    GET_ALL_EVENTS_SUCCESS,
    GET_EVENTS_FAILED,
    GET_EVENTS_REQUEST,
    GET_EVENTS_SUCCESS,
    SET_SELECTED_EVENTS,
} from './constants';

export const getEventsRequest = (payload) => {
    return {
        type: GET_EVENTS_REQUEST,
        payload,
    };
};

export const getEventsSuccess = (payload) => {
    return {
        type: GET_EVENTS_SUCCESS,
        payload,
    };
};

export const getEventsFailed = (payload) => {
    return {
        type: GET_EVENTS_FAILED,
        payload,
    };
};

export const setSelectedEvents = (payload) => {
    return {
        type: SET_SELECTED_EVENTS,
        payload,
    };
};

export const clearSelectedEvents = () => {
    return {
        type: CLEAR_SELECTED_EVENTS,
    };
};

export const addNewEventRequest = (payload) => {
    return {
        type: ADD_NEW_EVENT_REQUEST,
        payload,
    };
};

export const addNewEventSuccess = (payload) => {
    return {
        type: ADD_NEW_EVENT_SUCCESS,
        payload,
    };
};

export const addNewEventFailed = (payload) => {
    return {
        type: ADD_NEW_EVENT_FAILED,
        payload,
    };
};

export const editEventRequest = (payload) => {
    return {
        type: EDIT_EVENT_REQUEST,
        payload,
    };
};

export const editEventSuccess = (payload) => {
    return {
        type: EDIT_EVENT_SUCCESS,
        payload,
    };
};

export const editEventFailed = (payload) => {
    return {
        type: EDIT_EVENT_FAILED,
        payload,
    };
};

export const deleteEventsRequest = (payload) => {
    return {
        type: DELETE_EVENTS_REQUEST,
        payload,
    };
};

export const deleteEventsSuccess = (payload) => {
    return {
        type: DELETE_EVENTS_SUCCESS,
        payload,
    };
};

export const deleteEventsFailed = (payload) => {
    return {
        type: DELETE_EVENTS_FAILED,
        payload,
    };
};

export const getAllEventsRequest = () => {
    return {
        type: GET_ALL_EVENTS_REQUEST,
    };
};

export const getAllEventsSuccess = (payload) => {
    return {
        type: GET_ALL_EVENTS_SUCCESS,
        payload,
    };
};

export const getAllEventsFailed = (payload) => {
    return {
        type: GET_ALL_EVENTS_FAILED,
        payload,
    };
};
