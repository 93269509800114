import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Typography,
    Toolbar,
    AppBar,
    Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectSession } from '../../constants/selectors';
import { ExitToApp } from '@material-ui/icons';
import { logout } from '../../actions/session';

const MenuBar = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { isLoggedIn } = useSelector(selectSession);
    const dispatch = useDispatch();

    const signOut = () => {
        dispatch(logout());
    };

    return (
        <>
            <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logoContainer}>
                        <Link to="/" className={classes.logoButton}>
                            <img
                                src="/assets/images/szekszard-logo.png"
                                className={classes.logo}
                            />
                        </Link>
                    </div>
                    <div className={classes.titleContainer}>
                        <Typography variant="h6" className={classes.title}>
                            {t('menubar.title')}
                        </Typography>
                    </div>
                    {isLoggedIn && (
                        <Tooltip title={t('menubar.logout')}>
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="menu"
                                onClick={signOut}
                            >
                                <ExitToApp />
                            </IconButton>
                        </Tooltip>
                    )}
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logo: {
        height: 45,
    },
    logoButton: {
        width: 150,
    },
    title: {
        flexGrow: 1,
        marginLeft: 15,
        textAlign: 'center',
    },
    titleContainer: {
        position: 'absolute',
        width: '97%',
    },
    toolbar: {
        position: 'relative',
        justifyContent: 'space-between',
    },
    logoContainer: {
        zIndex: 10,
    },
}));

export default MenuBar;
