import {
    Card,
    CardContent,
    Box,
    Grid,
    Typography,
    makeStyles,
    Divider,
} from '@material-ui/core';
import { element, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { HUNGARIAN_DATE_FORMAT } from '../../constants/constants';
import moment from 'moment';

const ItemCard = ({ children, time }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getUpdatedTime = () => {
        return time
            ? moment(time).format(HUNGARIAN_DATE_FORMAT)
            : t('users.unknownDate');
    };

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>{children}</CardContent>
            <Divider />
            <Box className={classes.bottomContainer}>
                <Grid container spacing={2} className={classes.detailBox}>
                    <Grid item className={classes.grid}>
                        <AccessTimeIcon
                            className={classes.timeIcon}
                            color="action"
                        />
                        <Typography
                            color="textSecondary"
                            display="inline"
                            variant="body2"
                        >
                            {t('wineries.updated')}: {getUpdatedTime()}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 5,
    },
    detailBox: {
        justifyContent: 'space-between',
        margin: 0,
    },
    timeIcon: {
        paddingRight: 5,
    },
    grid: {
        alignItems: 'center',
        display: 'flex',
    },
    content: {
        position: 'relative',
        height: '85%',
    },
    bottomContainer: {
        padding: 5,
    },
});

ItemCard.propTypes = {
    children: element.isRequired,
    time: string,
};

export default ItemCard;
