import {
    GET_STATISTICS_DATA_FAILED,
    GET_STATISTICS_DATA_REQUEST,
    GET_STATISTICS_DATA_SUCCESS,
} from './constants';

export const getStatisticsDataRequest = () => {
    return {
        type: GET_STATISTICS_DATA_REQUEST,
    };
};

export const getStatisticsDataSuccess = (payload) => {
    return {
        type: GET_STATISTICS_DATA_SUCCESS,
        payload,
    };
};

export const getStatisticsDataFailed = (payload) => {
    return {
        type: GET_STATISTICS_DATA_FAILED,
        payload,
    };
};
