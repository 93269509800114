import { call, put, takeLatest } from '@redux-saga/core/effects';
import { EDIT_TRAVEL_REQUEST, GET_TRAVELS_REQUEST } from '../actions/constants';
import {
    editTravelSuccess,
    editTravelFailed,
    getTravelsFailed,
    getTravelsSuccess,
} from '../actions/travels';
import API from '../constants/api';
import { NotificationType } from '../constants/enums';
import { setNotification } from '../actions/notification';

function travelsRequest() {
    return API.get('/travels');
}

function editRequest(payload) {
    return API.patch('/travels', payload);
}

function* getTravelsRequest() {
    try {
        const { data } = yield call(travelsRequest);
        yield put(getTravelsSuccess(data));
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(getTravelsFailed(error));
    }
}

function* editTravelRequest(payload) {
    try {
        const { data } = yield call(editRequest, payload.payload);
        yield put(editTravelSuccess(data));
        yield put(
            setNotification({
                message: 'notification.success.success-save',
                type: NotificationType.Success,
            })
        );
    } catch (error) {
        console.warn(error);
        yield put(
            setNotification({
                message: error.response?.data.message,
                type: NotificationType.Error,
                status: error.response?.status,
            })
        );
        yield put(editTravelFailed(error));
    }
}

export default function* watchTravels() {
    yield takeLatest(GET_TRAVELS_REQUEST, getTravelsRequest);
    yield takeLatest(EDIT_TRAVEL_REQUEST, editTravelRequest);
}
