import React from 'react';
import ActionMenu from '../../components/Menu/ActionMenu';
import { func, object } from 'prop-types';
import { EditMenuItem } from '../../components/MenuItem';

const NewsActionMenu = ({ onEditClick, anchorEl, handleClose }) => {
    return (
        <ActionMenu anchorEl={anchorEl} handleClose={handleClose}>
            <EditMenuItem onEditClick={onEditClick} />
        </ActionMenu>
    );
};

NewsActionMenu.propTypes = {
    anchorEl: object,
    handleClose: func,
    onEditClick: func,
};

export default NewsActionMenu;
