import React from 'react';
import { useTranslation } from 'react-i18next';
import { array, func, object } from 'prop-types';
import Table from '../../components/Table/Table';
import {
    dateFormatter,
    coverImageFormatter,
    enumFormatter,
    wineryListFormatter,
    titleFormatter,
} from '../../constants/tableHelper';
import NewsActionMenu from './NewsActionMenu';
import { setSelectedNews as setSelectedNewsAction } from '../../actions/news';
import { StatusTypes } from '../../constants/enums';

const NewsTable = ({
    selectedNewsIds,
    setAnchorEl,
    anchorEl,
    items,
    setSelectedNews,
    onEditMenuClick,
}) => {
    const { t } = useTranslation();

    const onMenuClick = (e, offer) => {
        setAnchorEl(e.currentTarget);
        setSelectedNews(offer);
    };

    const columns = [
        {
            id: 'coverImageId',
            label: t('news.coverImage'),
            minWidth: 150,
            align: 'center',
            format: (value) => coverImageFormatter(value),
        },
        {
            id: 'title',
            label: t('news.title'),
            minWidth: 60,
            format: (value) => titleFormatter(value),
        },
        {
            id: 'wineries',
            label: t('news.wineries'),
            minWidth: 270,
            format: (value) => wineryListFormatter(value),
        },
        {
            id: 'updatedAt',
            label: t('news.updatedAt'),
            format: (value) => dateFormatter(value, '-'),
            align: 'center',
        },
        {
            id: 'publishedAt',
            label: t('news.publishedAt'),
            format: (value) => dateFormatter(value, t('news.notPublished')),
            align: 'center',
        },
        {
            id: 'status',
            label: t('news.status'),
            align: 'center',
            format: (value) => enumFormatter(StatusTypes, value, t),
        },
    ];

    return (
        <>
            <Table
                onMenuClick={onMenuClick}
                columns={columns}
                items={items}
                selectedItemIds={selectedNewsIds}
                setSelectedItems={setSelectedNewsAction}
            >
                <NewsActionMenu
                    anchorEl={anchorEl}
                    handleClose={() => setAnchorEl(null)}
                    onEditClick={onEditMenuClick}
                />
            </Table>
        </>
    );
};

NewsTable.propTypes = {
    selectedNewsIds: array,
    anchorEl: object,
    setAnchorEl: func,
    items: array,
    setSelectedNews: func,
    selectedNews: object,
    onEditMenuClick: func,
};

export default NewsTable;
