import React from 'react';
import {
    Button,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    makeStyles,
} from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';

const WarningModal = ({
    open,
    handleClose,
    content,
    okButtonText,
    onOkClick,
    title,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent className={classes.content}>
                <WarningIcon className={classes.icon} />
                <DialogContentText
                    id="alert-dialog-description"
                    className={classes.contentText}
                >
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.button}
                    onClick={handleClose}
                    color="primary"
                >
                    {t('form.cancel')}
                </Button>
                <Button
                    className={classes.button}
                    onClick={onOkClick}
                    color="primary"
                    autoFocus
                >
                    {okButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

WarningModal.propTypes = {
    open: bool.isRequired,
    handleClose: func.isRequired,
    content: string,
    okButtonText: string,
    onOkClick: func,
    title: string.isRequired,
};

const useStyles = makeStyles({
    content: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0',
    },
    contentText: {
        margin: 0,
    },
    icon: {
        paddingRight: 15,
    },
    button: {
        paddingRight: 15,
    },
});

export default WarningModal;
