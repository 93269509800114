import { Grid } from '@material-ui/core';
import { func, object } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Password } from '../Input';

const PasswordGrid = ({ handleChange, values }) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid item md={12} xs={12}>
                <Password
                    onChange={handleChange}
                    label={t('profile.password')}
                    name="password"
                    id="password"
                    type="password"
                    value={values?.password}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <Password
                    onChange={handleChange}
                    label={t('profile.confirm')}
                    name="confirm"
                    id="confirm"
                    type="password"
                    value={values?.confirm}
                />
            </Grid>
        </>
    );
};

PasswordGrid.propTypes = {
    handleChange: func,
    values: object,
};

export default PasswordGrid;
