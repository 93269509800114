import React from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';
import { colors } from '../../constants/theme';

const TextInput = ({
    label,
    id,
    name,
    autoComplete = 'off',
    onChange,
    value,
    isRequired = true,
    multiline = false,
    rows = 4,
    type = 'text',
}) => {
    const classes = useStyles();

    return (
        <TextField
            variant="outlined"
            margin="normal"
            required={isRequired}
            fullWidth
            name={name}
            label={label}
            type={type}
            id={id}
            autoComplete={autoComplete}
            className={classes.textfield}
            onChange={onChange}
            value={value}
            multiline={multiline}
            rows={rows}
        />
    );
};

const useStyles = makeStyles({
    textfield: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: colors.BLACK,
            },
        },
        '& label.Mui-focused': {
            color: colors.BLACK,
        },
    },
});

TextInput.propTypes = {
    label: string,
    type: string,
    id: string,
    name: string,
    autoComplete: string,
    onChange: func,
    value: oneOfType([number, string]),
    isRequired: bool,
    multiline: bool,
    rows: number,
};

export default TextInput;
