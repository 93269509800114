import { Fab, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { func, string } from 'prop-types';

const FloatingAddButton = ({ onClick, title }) => {
    const classes = useStyles();

    return (
        <Fab
            variant="extended"
            size="large"
            onClick={onClick}
            color="primary"
            aria-label="add"
            className={classes.fab}
        >
            <AddIcon />
            <Typography className={classes.text} variant="caption">
                {title}
            </Typography>
        </Fab>
    );
};

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: 15,
        right: 15,
    },
    text: {
        paddingLeft: 5,
    },
}));

FloatingAddButton.propTypes = {
    onClick: func.isRequired,
    title: string,
};

export default FloatingAddButton;
