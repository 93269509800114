import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { array, func, object } from 'prop-types';
import { setSelectedOffers } from '../../actions/offers';
import OfferActionMenu from './OfferActionMenu';
import Table from '../../components/Table/Table';
import {
    imageFormatter,
    urlFormatter,
    wineryListFormatter,
} from '../../constants/tableHelper';
import { selectOffers } from '../../constants/selectors';
import { uploadOfferImageRequest } from '../../actions/images';
import UploadModal from '../../components/Modal/UploadModal';
import OfferImageGrid from './OfferImageGrid';
import { useDispatch, useSelector } from 'react-redux';

const OfferTable = ({
    selectedOfferIds,
    setAnchorEl,
    anchorEl,
    items,
    setSelectedOffer,
    selectedOffer,
    onEditMenuClick,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [uploadOpen, setUploadOpen] = useState(false);
    const currentOffer = useSelector(selectOffers)?.items?.find(
        (offer) => offer.id === selectedOffer?.id
    );

    const onMenuClick = (e, offer) => {
        setAnchorEl(e.currentTarget);
        setSelectedOffer(offer);
    };

    const onImageUpload = (files) => {
        setUploadOpen(false);
        dispatch(
            uploadOfferImageRequest({
                id: selectedOffer?.id,
                photos: files,
            })
        );
    };

    const onUploadClick = () => {
        setUploadOpen(true);
        setAnchorEl(null);
    };

    const columns = [
        {
            id: 'images',
            label: t('offers.coverImage'),
            format: (value) => imageFormatter(value),
        },
        { id: 'title', label: t('offers.title'), minWidth: 50 },
        {
            id: 'webShopLink',
            label: t('offers.webShopLink'),
            format: (value) => urlFormatter(value),
        },
        {
            id: 'event',
            label: t('offers.event'),
            format: (value) => (value ? value.name : ''),
        },
        { id: 'validFrom', label: t('offers.validFrom'), minWidth: 50 },
        { id: 'validTo', label: t('offers.validTo'), minWidth: 50 },
        {
            id: 'wineries',
            label: t('offers.wineries'),
            format: (value) => wineryListFormatter(value),
        },
    ];

    return (
        <>
            <Table
                onMenuClick={onMenuClick}
                columns={columns}
                items={items}
                selectedItemIds={selectedOfferIds}
                setSelectedItems={setSelectedOffers}
            >
                <OfferActionMenu
                    anchorEl={anchorEl}
                    handleClose={() => setAnchorEl(null)}
                    onEditClick={onEditMenuClick}
                    onUploadClick={onUploadClick}
                />
            </Table>
            <UploadModal
                title={t('offers.offerUpload')}
                onClose={() => setUploadOpen(false)}
                open={uploadOpen}
                onImageUpload={onImageUpload}
                images={currentOffer?.images}
            >
                <OfferImageGrid
                    images={currentOffer?.images}
                    offerId={currentOffer?.id}
                />
            </UploadModal>
        </>
    );
};

OfferTable.propTypes = {
    selectedOfferIds: array,
    anchorEl: object,
    setAnchorEl: func,
    items: array,
    setSelectedOffer: func,
    selectedOffer: object,
    onEditMenuClick: func,
};

export default OfferTable;
