import { makeStyles, TextField } from '@material-ui/core';
import { array, bool, func, number, oneOfType, string } from 'prop-types';
import React from 'react';
import { colors } from '../../constants/theme';

const Select = ({
    label,
    name,
    handleChange,
    value,
    options,
    required = false,
    placeholder,
    disabled = false,
    shrink = false,
}) => {
    const classes = useStyles();

    return (
        <TextField
            fullWidth
            name={name}
            className={classes.textfield}
            onChange={handleChange}
            select
            SelectProps={{ native: true }}
            InputLabelProps={shrink && { shrink }}
            value={value}
            variant="outlined"
            label={label}
            required={required}
            disabled={disabled}
        >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map((option) => (
                <option key={option.id} value={option.id}>
                    {option.name}
                </option>
            ))}
        </TextField>
    );
};

const useStyles = makeStyles({
    textfield: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: colors.BLACK,
            },
        },
        '& label.Mui-focused': {
            color: colors.BLACK,
        },
    },
});

Select.propTypes = {
    label: string,
    name: string,
    handleChange: func.isRequired,
    value: oneOfType([string, number]),
    options: array,
    required: bool,
    placeholder: string,
    disabled: bool,
    shrink: bool,
};

export default Select;
