import { makeStyles } from '@material-ui/core';
import { array } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewAllCardLayout from '../../components/Layout/ViewAllCardLayout';
import SimpleTable from '../../components/Table/SimpleTable';

const LatestEventsTable = ({ eventList }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const columns = [
        { id: 'name', label: t('events.name') },
        { id: 'location', label: t('events.location') },
        { id: 'fromDate', label: t('events.fromDate') },
        { id: 'toDate', label: t('events.toDate') },
        {
            id: 'news',
            label: t('events.newsLink'),
            format: (value) => value?.title,
        },
    ];

    return (
        <div className={classes.root}>
            <ViewAllCardLayout href={'/events'} title={t('home.latestEvents')}>
                <SimpleTable items={eventList} columns={columns} />
            </ViewAllCardLayout>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        marginTop: 30,
        width: '100%',
    },
});

LatestEventsTable.propTypes = {
    eventList: array,
};

export default LatestEventsTable;
