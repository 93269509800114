import React, { useState } from 'react';
import { Password } from '../../components/Input';
import { useTranslation } from 'react-i18next';
import FormCardLayout from '../../components/Layout/FormCardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { updatePasswordRequest } from '../../actions/session';
import { selectSession } from '../../constants/selectors';
import { PasswordGrid } from '../../components/Grid';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import { HelperText } from '../../components/Form';
import { setNotification } from '../../actions/notification';
import { NotificationType } from '../../constants/enums';

const PasswordCard = () => {
    const [values, setValues] = useState({
        password: '',
        confirm: '',
        currentPassword: '',
    });
    const { t } = useTranslation();
    const [isDisabled, setIsDisabled] = useState(true);
    const { session } = useSelector(selectSession);
    const dispatch = useDispatch();
    const [helperText, isValid, setHelperText] = usePasswordValidation({
        password: values.password,
        confirm: values.confirm,
        currentPassword: values.currentPassword,
    });

    const handleChange = (event) => {
        setIsDisabled(false);
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const onSave = () => {
        if (isValid) {
            setHelperText('');
            dispatch(
                updatePasswordRequest({
                    ...values,
                    id: session.id,
                })
            );
            resetForm();
        } else {
            dispatch(
                setNotification({
                    message: t('notification.validation.password-change'),
                    type: NotificationType.Error,
                })
            );
        }
    };

    const resetForm = () => {
        setValues({
            password: '',
            confirm: '',
            currentPassword: '',
        });
        setIsDisabled(true);
    };

    return (
        <FormCardLayout
            onSave={onSave}
            buttonDisabled={isDisabled}
            title={t('profile.password')}
            subheader={t('profile.updatePassword')}
            buttonTitle={t('form.save')}
            onCancel={resetForm}
        >
            <>
                <Password
                    onChange={handleChange}
                    label={t('profile.currentPassword')}
                    name="currentPassword"
                    id="currentPassword"
                    type="password"
                    value={values.currentPassword}
                />
                <PasswordGrid values={values} handleChange={handleChange} />
                {!isDisabled && <HelperText helperText={helperText} />}
            </>
        </FormCardLayout>
    );
};

export default PasswordCard;
