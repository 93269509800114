import { all } from 'redux-saga/effects';
import watchImages from './imageSaga';
import watchSession from './sessionSaga';
import watchUsers from './userSaga';
import watchWineries from './winerySaga';
import watchEvents from './eventSaga';
import watchAccommodations from './accommodationSaga';
import watchStatistics from './statisticsSaga';
import watchOffers from './offerSaga';
import watchNews from './newsSaga';
import watchTravels from './travelSaga';

export default function* rootSaga() {
    yield all([
        watchSession(),
        watchUsers(),
        watchWineries(),
        watchEvents(),
        watchImages(),
        watchAccommodations(),
        watchStatistics(),
        watchOffers(),
        watchNews(),
        watchTravels(),
    ]);
}
