import {
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Switch as MaterialSwitch,
} from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import React from 'react';

const Switch = ({ label, checked, onChange, name, inputLabel }) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.root}>
            <FormLabel className={classes.label} component="legend">
                {inputLabel}:
            </FormLabel>
            <FormControlLabel
                control={
                    <MaterialSwitch
                        checked={checked}
                        onChange={onChange}
                        name={name}
                        color="primary"
                    />
                }
                label={label}
            />
        </FormControl>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    label: {
        alignSelf: 'center',
        paddingRight: 10,
    },
});

Switch.propTypes = {
    label: string.isRequired,
    checked: bool.isRequired,
    onChange: func,
    name: string,
    inputLabel: string.isRequired,
};

export default Switch;
