import {
    ADD_NEW_NEWS_FAILED,
    ADD_NEW_NEWS_REQUEST,
    ADD_NEW_NEWS_SUCCESS,
    CLEAR_SELECTED_NEWS,
    DELETE_NEWS_FAILED,
    DELETE_NEWS_REQUEST,
    DELETE_NEWS_SUCCESS,
    EDIT_NEWS_FAILED,
    EDIT_NEWS_REQUEST,
    EDIT_NEWS_SUCCESS,
    GET_NEWS_FAILED,
    GET_NEWS_REQUEST,
    GET_NEWS_SUCCESS,
    GET_ALL_NEWS_FAILED,
    GET_ALL_NEWS_REQUEST,
    GET_ALL_NEWS_SUCCESS,
    SET_SELECTED_NEWS,
} from './constants';

export const getNewsRequest = (payload) => {
    return {
        type: GET_NEWS_REQUEST,
        payload,
    };
};

export const getNewsSuccess = (payload) => {
    return {
        type: GET_NEWS_SUCCESS,
        payload,
    };
};

export const getNewsFailed = (payload) => {
    return {
        type: GET_NEWS_FAILED,
        payload,
    };
};

export const getAllNewsRequest = () => {
    return {
        type: GET_ALL_NEWS_REQUEST,
    };
};

export const getAllNewsSuccess = (payload) => {
    return {
        type: GET_ALL_NEWS_SUCCESS,
        payload,
    };
};

export const getAllNewsFailed = (payload) => {
    return {
        type: GET_ALL_NEWS_FAILED,
        payload,
    };
};

export const editNewsRequest = (payload) => {
    return {
        type: EDIT_NEWS_REQUEST,
        payload,
    };
};

export const editNewsSuccess = (payload) => {
    return {
        type: EDIT_NEWS_SUCCESS,
        payload,
    };
};

export const editNewsFailed = (payload) => {
    return {
        type: EDIT_NEWS_FAILED,
        payload,
    };
};

export const setSelectedNews = (payload) => {
    return {
        type: SET_SELECTED_NEWS,
        payload,
    };
};

export const clearSelectedNews = () => {
    return {
        type: CLEAR_SELECTED_NEWS,
    };
};

export const addNewNewsRequest = (payload) => {
    return {
        type: ADD_NEW_NEWS_REQUEST,
        payload,
    };
};

export const addNewNewsSuccess = (payload) => {
    return {
        type: ADD_NEW_NEWS_SUCCESS,
        payload,
    };
};

export const addNewNewsFailed = (payload) => {
    return {
        type: ADD_NEW_NEWS_FAILED,
        payload,
    };
};

export const deleteNewsRequest = (payload) => {
    return {
        type: DELETE_NEWS_REQUEST,
        payload,
    };
};

export const deleteNewsSuccess = (payload) => {
    return {
        type: DELETE_NEWS_SUCCESS,
        payload,
    };
};

export const deleteNewsFailed = (payload) => {
    return {
        type: DELETE_NEWS_FAILED,
        payload,
    };
};
