import {
    ADD_NEW_WINERY_FAILED,
    ADD_NEW_WINERY_REQUEST,
    ADD_NEW_WINERY_SUCCESS,
    DELETE_WINERY_FAILED,
    DELETE_WINERY_IMAGE_FAILED,
    DELETE_WINERY_IMAGE_REQUEST,
    DELETE_WINERY_IMAGE_SUCCESS,
    DELETE_WINERY_REQUEST,
    DELETE_WINERY_SUCCESS,
    EDIT_WINERY_FAILED,
    EDIT_WINERY_REQUEST,
    EDIT_WINERY_SUCCESS,
    GET_ALL_WINERIES_FAILED,
    GET_ALL_WINERIES_REQUEST,
    GET_ALL_WINERIES_SUCCESS,
    GET_WINERIES_FAILED,
    GET_WINERIES_REQUEST,
    GET_WINERIES_SUCCESS,
    SELECT_WINERY_COVER_PHOTO_FAILED,
    SELECT_WINERY_COVER_PHOTO_REQUEST,
    SELECT_WINERY_COVER_PHOTO_SUCCESS,
    UPLOAD_WINERY_IMAGE_FAILED,
    UPLOAD_WINERY_IMAGE_REQUEST,
    UPLOAD_WINERY_IMAGE_SUCCESS,
} from '../actions/constants';
import { INITIAL_WINERY_STATE } from '../constants/states';
import { WINERY_PAGE_SIZE } from '../constants/constants';

export default (state = INITIAL_WINERY_STATE, action) => {
    switch (action.type) {
        case GET_WINERIES_REQUEST:
            return { ...state, isWineriesRequested: true, isLoading: true };
        case GET_WINERIES_SUCCESS:
            return {
                ...state,
                isWineriesRequested: false,
                wineries: action.payload,
                isLoading: false,
            };
        case GET_WINERIES_FAILED:
            return { ...state, isWineriesRequested: false, isLoading: false };
        case GET_ALL_WINERIES_REQUEST:
            return { ...state, isWineriesRequested: true };
        case GET_ALL_WINERIES_SUCCESS:
            return {
                ...state,
                isWineriesRequested: false,
                dropdownItems: action.payload,
            };
        case GET_ALL_WINERIES_FAILED:
            return { ...state, isWineriesRequested: false };
        case EDIT_WINERY_REQUEST:
            return { ...state, isEditRequested: true };
        case EDIT_WINERY_SUCCESS: {
            return {
                ...state,
                isEditRequested: false,
                wineries: {
                    ...state.wineries,
                    items: state.wineries.items.map((winery) => {
                        if (winery.id === action.payload.id) {
                            return action.payload;
                        }
                        return winery;
                    }),
                },
            };
        }
        case EDIT_WINERY_FAILED:
            return { ...state, isEditRequested: false };
        case UPLOAD_WINERY_IMAGE_REQUEST:
            return { ...state, isUploadRequested: true };
        case UPLOAD_WINERY_IMAGE_SUCCESS:
            return {
                ...state,
                isUploadRequested: false,
                wineries: {
                    ...state.wineries,
                    items: state.wineries.items.map((winery) => {
                        if (winery.id === action.payload.wineryId) {
                            return {
                                ...winery,
                                images: [
                                    ...winery.images,
                                    ...action.payload.urls,
                                ],
                            };
                        }
                        return winery;
                    }),
                },
            };
        case UPLOAD_WINERY_IMAGE_FAILED:
            return { ...state, isUploadRequested: false };
        case DELETE_WINERY_IMAGE_REQUEST:
            return { ...state, isDeleteRequested: true };
        case DELETE_WINERY_IMAGE_SUCCESS:
            return {
                ...state,
                isDeleteRequested: false,
                wineries: {
                    ...state.wineries,
                    items: state.wineries.items.map((winery) => {
                        if (winery.id === action.payload.wineryId) {
                            return {
                                ...winery,
                                images: winery.images.filter(
                                    (image) =>
                                        image.id !== action.payload.photoId
                                ),
                            };
                        }
                        return winery;
                    }),
                },
            };
        case DELETE_WINERY_IMAGE_FAILED:
            return { ...state, isDeleteRequested: false };
        case SELECT_WINERY_COVER_PHOTO_REQUEST:
            return { ...state, isCoverPhotoRequested: true };
        case SELECT_WINERY_COVER_PHOTO_SUCCESS:
            return {
                ...state,
                isCoverPhotoRequested: false,
                wineries: {
                    ...state.wineries,
                    items: state.wineries.items.map((winery) => {
                        if (
                            action.payload.wineryId &&
                            winery.id === action.payload.wineryId
                        ) {
                            return {
                                ...winery,
                                images: winery.images.map((image) => {
                                    if (image.id === action.payload.id) {
                                        return action.payload;
                                    }
                                    if (action.payload.cover) {
                                        return { ...image, cover: false };
                                    }
                                    return image;
                                }),
                            };
                        }
                        return winery;
                    }),
                },
            };
        case SELECT_WINERY_COVER_PHOTO_FAILED:
            return { ...state, isCoverPhotoRequested: false };
        case DELETE_WINERY_REQUEST:
            return { ...state, isDeleteRequested: true };
        case DELETE_WINERY_SUCCESS: {
            const { pageableResponse } = action.payload;
            if (pageableResponse) {
                return {
                    ...state,
                    isDeleteRequested: false,
                    wineries: {
                        ...state.wineries,
                        totalPages: pageableResponse.totalPages,
                        totalItems: pageableResponse.totalItems,
                        items: pageableResponse.items,
                    },
                };
            }
            return {
                ...state,
                isDeleteRequested: false,
            };
        }
        case DELETE_WINERY_FAILED:
            return { ...state, isDeleteRequested: false };
        case ADD_NEW_WINERY_REQUEST:
            return { ...state, isCreateRequested: true };
        case ADD_NEW_WINERY_SUCCESS: {
            let totalPages = state.wineries.totalPages;
            let items = state.wineries.items;
            if (state.wineries.items.length !== WINERY_PAGE_SIZE) {
                items = [...items, action.payload];
            } else if (state.wineries.totalItems % WINERY_PAGE_SIZE === 0) {
                totalPages++;
            }
            return {
                ...state,
                isCreateRequested: false,
                wineries: {
                    ...state.wineries,
                    totalItems: state.wineries.totalItems + 1,
                    totalPages,
                    items,
                },
            };
        }
        case ADD_NEW_WINERY_FAILED:
            return { ...state, isCreateRequested: false };
        default:
            return state;
    }
};
