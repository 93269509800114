import React from 'react';
import { makeStyles } from '@material-ui/core';
import { string } from 'prop-types';
import { colors } from '../../constants/theme';

const Link = ({ target, href, label }) => {
    const classes = useStyles();

    return (
        <a
            className={classes.link}
            target={target}
            rel="noopener noreferrer"
            href={href}
        >
            {label}
        </a>
    );
};

const useStyles = makeStyles({
    link: {
        paddingLeft: 5,
        color: colors.BLACK,
        fontStyle: 'italic',
    },
});

Link.propTypes = {
    target: string,
    href: string,
    label: string,
};

export default Link;
