import { useState, useEffect } from 'react';
import { SCREEN_WIDTH } from '../constants/constants';

export function useWindowSize() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowWidth > SCREEN_WIDTH;
}
