import React from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Box className={classes.box}>
                <Container maxWidth="md">
                    <Box className={classes.imageBox}>
                        <img
                            alt="Under development"
                            src="/assets/images/404-not-found.png"
                            className={classes.image}
                        />
                    </Box>
                    <Typography align="center" color="textPrimary" variant="h5">
                        {t('notfound.subtitle')}
                    </Typography>
                </Container>
            </Box>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 20,
        fontWeight: 500,
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 350,
    },
    imageBox: {
        textAlign: 'center',
    },
});

export default NotFound;
