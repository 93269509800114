import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUsersRequest } from '../../actions/users';
import { getAllWineriesRequest } from '../../actions/wineries';
import Header from '../../components/Menu/Header';
import WarningModal from '../../components/Modal/WarningModal';
import { selectSelectedUsers } from '../../constants/selectors';
import { styles } from '../../constants/theme';
import UserList from './UserList';
import UserModal from './UserModal';

const Users = () => {
    const selectedUsers = useSelector(selectSelectedUsers);
    const [open, setOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllWineriesRequest());
    }, []);

    const onDelete = () => {
        setOpen(false);
        dispatch(deleteUsersRequest(selectedUsers));
    };

    return (
        <div style={styles.defaultContainer}>
            <Header
                deleteDisabled={selectedUsers?.length === 0}
                onDeleteClick={() => setOpen(true)}
                onAddClick={() => setFormOpen(true)}
            />
            <UserList />
            <WarningModal
                open={open}
                handleClose={() => setOpen(false)}
                content={t('users.confirmDelete')}
                okButtonText={t('users.delete')}
                onOkClick={onDelete}
                title={t('users.toDelete')}
            />
            <UserModal open={formOpen} onClose={() => setFormOpen(false)} />
        </div>
    );
};

export default Users;
