import React from 'react';
import { Container, Box, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import WineryCard from '../WineryCard';
import EmptyCard from '../../../components/Card/EmptyCard';
import { array } from 'prop-types';

const SingleWineryList = ({ items }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container className={classes.container}>
                <Box className={classes.box}>
                    {isEmpty(items) ? (
                        <EmptyCard />
                    ) : (
                        items.map((winery) => {
                            return (
                                <WineryCard
                                    isFullScreen={true}
                                    key={winery.id}
                                    winery={winery}
                                />
                            );
                        })
                    )}
                </Box>
            </Container>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 25,
    },
    container: {
        height: 550,
    },
    box: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
});

SingleWineryList.propTypes = {
    items: array,
};

export default SingleWineryList;
