import { Roles } from './enums';

export const selectSession = (state) => state.session;
export const selectUserWinery = (state) => state.session.session?.userWinery;
export const selectIsSuperAdmin = (state) =>
    state.session.session?.roles?.includes(Roles.SuperAdmin);
export const selectIsLoggedIn = (state) => state.session.isLoggedIn;
export const selectNotification = (state) => state.notification;
export const selectUsers = (state) => state.user.users;
export const selectMobileUsers = (state) => state.user.mobileUsers;
export const selectMobileUserLoading = (state) =>
    state.user.isMobileUsersRequested;
export const selectUserLoading = (state) => state.user.isUsersRequested;
export const selectWineries = (state) => state.winery.wineries;
export const selectWineryLoading = (state) => state.winery.isLoading;
export const selectSelectedUsers = (state) => state.user.selectedUsers;
export const selectWineryDropdownItems = (state) => state.winery.dropdownItems;
export const selectSelectedEvents = (state) => state.event.selectedEvents;
export const selectEventLoading = (state) => state.event.isEventsRequested;
export const selectEvents = (state) => state.event.events;
export const selectAccommodations = (state) =>
    state.accommodation.accommodations;
export const selectAccommodationLoading = (state) =>
    state.accommodation.isAccommodationRequested;
export const selectSelectedAccommodations = (state) =>
    state.accommodation.selectedAccommodations;
export const selectStatistics = (state) => state.statistics;
export const selectSelectedOffers = (state) => state.offer.selectedOffers;
export const selectOfferLoading = (state) => state.offer.isOffersRequested;
export const selectOffers = (state) => state.offer.offers;
export const selectTravels = (state) => state.travel.travels;
export const selectTravelLoading = (state) => state.travel.isTravelsRequested;
export const selectNews = (state) => state.news.news;
export const selectNewsLoading = (state) => state.news.isNewsRequested;
export const selectNewsDropdownItems = (state) => state.news.dropdownItems;
export const selectSelectedNews = (state) => state.news.selectedNews;
export const selectEventDropdownItems = (state) => state.event.dropdownItems;
